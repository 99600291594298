/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import { Component } from "react";
import Header from '../../components/header/HeaderWithBanner'
import Footer from '../../components/footer/Footer';
import { imsLoader } from '../../assets'
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator';
//import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import '../../../node_modules/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import '../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import ApiServices from "../../utils/apiServices";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import Services from "../../utils/services";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import Button from "../../components/Button";
import '../../components/App.css';
import UpdateUserDetailsPopUp from "../../components/PopUp/UpdateUserDetailsPopUp"


class AdminDashboard extends Component {
    userid;
    userExists;
    constructor(props) {
        super(props);
        const selectOptions = [
            { value: 'Accepted', label: 'Accepted' },
            { value: 'Rejected', label: 'Rejected' },
            { value: 'Pending', label: 'Pending' },
            // { value: '', label: '' }
        ];
        const functionOptions = [
            { value: 'Medical', label: 'Medical/Scientific' },
            { value: 'Commercial', label: 'Commercial' },
            // { value: '', label: '' }
        ];
        const accessgroupOptions = [
            { value: 'MISEHCP', label: 'Answers-MISE' },
            { value: 'AllContent', label: 'Answers-MSL' },
            { value: 'JanssenScience', label: 'Janssen Science' },
            { value: 'Authoring', label: 'Authoring' },
            { value: 'LimitedUser', label: 'Limited User' },
            { value: 'None', label: 'None' },
        ];

        this.state = {
            products: [],
            isLoaded: false,
            showPopupLoader: false,
            showAcceptPopup: false,
            showRejectPopup: false,
            showEditPopup: false,
            userWWID: 0,
            username: '',
            userExists: false,
            errorLabel: false,
            width: window.innerWidth,
            height: window.innerHeight,
            selected: [],
            selectedUsersDetails: [],
            emailSubOnReject: 'iMedical Search: update about your registration',
            emailSubOnAccept: 'iMedical Search: thank you for registering/updating your preferences',
            emailBodyForAccept: `Thank you for registering or updating your  iMedical Search (iMS) preferences. Your request/update has been approved and you now have access to content relevant to your role. If you have any questions regarding iMS please use the Contact Us link <a href="${window.origin}/Contact-Us">(${window.origin}/Contact-Us)</a> on the iMS homepage.`,
            emailBodyForReject: `Thank you for registering to use iMedical Search (iMS). Your request to access non-commercial content could not be verified. You will continue to have access to commercial content. Please contact the  <a href="mailto:ims@its.jnj.com">IMS team</a>​ if you require further information. `,
            columns: [
                {
                    dataField: 'wwid',
                    text: 'WWID',
                    filter: textFilter({
                        placeholder: 'WWID',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },

                {
                    dataField: 'username',
                    text: 'Name',
                    filter: textFilter({
                        placeholder: 'Name',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'currentdepartment',
                    text: 'Current Department',
                    filter: textFilter({
                        placeholder: 'Current Department',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'email',
                    text: 'E-mail',
                    filter: textFilter({
                        placeholder: 'E-mail',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'profession',
                    text: 'Function',
                    sort: true,
                    // filter: textFilter({
                    //     placeholder: 'Function',  // custom the input placeholder
                    //     className: 'my-custom-text-filter', // custom classname on input
                    // })
                    formatter: cell => functionOptions.find(opt => opt.value === cell).label,
                    filter: selectFilter({
                        options: functionOptions,
                        placeholder: 'Function',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'status',
                    text: 'Review Status',
                    formatter: cell => selectOptions.find(opt => opt.value === cell).label,
                    filter: selectFilter({
                        options: selectOptions,
                        placeholder: 'Review Status',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'regions',
                    text: 'Region(s)',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Region(s)',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'countries',
                    text: 'Countries',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Countries',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'datatracking',
                    text: 'Tracking',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Data Tracking',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'therapeutic_areas',
                    text: 'Therapeutic Area(s)',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Therapeutic Area(s)',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'drug_classes',
                    text: 'Drug Classes',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Drug Classes',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'products',
                    text: 'Product(s)',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Product(s)',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'contentprefernce',
                    text: 'All Product(s) Preference',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'All Product(s) Preference',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'documenttypes',
                    text: 'Document Type(s)',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Document Type(s)',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'accessgroup',
                    text: 'Access Group',
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                        return { width: '120px' };
                    },
                    formatter: (value, row, index) => {
                        return (<>
                            {(value.includes("Answers") && (value.includes("MISEHCP") || value.includes("AllContent")) || value.includes("Authoring")) ?
                                <ul className="ul-access-group">
                                    {value.includes("Answers") && (value.includes("MISEHCP") || value.includes("AllContent")) && <>
                                        <li>Answers</li>
                                        <li>
                                            <ul className="ul-access-group">
                                                {value.includes("MISEHCP") && <li>MISE</li>}
                                                {value.includes("AllContent") && <li>MSL</li>}
                                            </ul>
                                        </li>
                                    </>}
                                    {value.includes("JanssenScience") && <li>Janssen Science</li>}
                                    {value.includes("Authoring") && <li>Authoring</li>}
                                    {value.includes("LimitedUser") && <li>Limited User</li>}
                                </ul> : <>
                                    None
                                </>}
                        </>)
                    },
                    filter: selectFilter({
                        options: accessgroupOptions,
                        placeholder: 'Access Group',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                        onFilter: (filterValue, row, index) => { //filter listener
                            return row.filter(x => x.accessgroup.includes(filterValue))
                        }
                    }),
                    // filter: textFilter({
                    //     placeholder: 'Access Group',  // custom the input placeholder
                    //     className: 'my-custom-text-filter', // custom classname on input
                    //     onFilter: (filterValue, row, index) => { //filter listener
                    //         //...
                    //         console.log("filterValue", filterValue, row, index);
                    //         return [];
                    //     }
                    // })
                },
                {
                    dataField: 'department',
                    text: 'Original Department',
                    filter: textFilter({
                        placeholder: 'Original Department',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'created_date',
                    text: 'Created Date',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Created Date',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                },
                {
                    dataField: 'lastlogindate',
                    text: 'Last Login',
                    sort: true,
                    filter: textFilter({
                        placeholder: 'Last Login',  // custom the input placeholder
                        className: 'my-custom-text-filter', // custom classname on input
                    })
                }
            ]
        }
    }
    emailModule = async (emailBody, userDetails, subject) => {
        //console.log(userDetails);
        this.setState({ showAcceptPopup: false, showRejectPopup: false, showPopupLoader: true })
        const service = new ApiServices();
        let res;
        for (const itr of userDetails) {
            let reqBody = {
                'to': `${itr.email}`,
                'subject': `${subject}`,
                'username': `${itr.username}`,
                'mailbody': `${emailBody}`
            }
            res = await service.sendEmail(reqBody)
            //console.log(res)
        }
        if (res) {
            //window.location.reload(true);
            await this.getAllUsersDetails();
        }
    }
    componentDidMount() {
        document.title = "Admin Console";
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.userExists = localStorage.getItem('userExists');
        const service = new ApiServices();
        let login = new Services();
        login.logService(`Admin page successfully mounted for ${this.userid.toLowerCase()}`, 'info');
        var adminRes;
        if (this.userExists) {
            this.setState({
                username: this.userid.toLowerCase(),
                userExists: this.userExists
            }, async () => {
                //this.userValidation(this.state.username)
                /*Call to DB to check if logged in User is an admin*/
                adminRes = await service.getAdminDetails(this.state.username);
                //console.log(adminRes)
                if (adminRes[0].phm_ims_isadminfunc) {
                    this.setState({
                        userWWID: adminRes[0].phm_ims_isadminfunc
                    }, async () => {
                        /* Axios call to get the data*/
                        await this.getAllUsersDetails();
                    })
                }
                else {
                    this.setState({
                        isLoaded: true
                    })
                }
            })
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }
    }
    getAllUsersDetails = async () => {
        const service = new ApiServices();
        let res = await service.getAllUsersDetails();
        if (res) {
            this.setState({
                products: res,
                isLoaded: true,
                selected: [],
                showPopupLoader: false
            })
        }
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    handleButtonAccept = () => {
        // console.log('PopUp needs to come');
        if (this.state.selected.length > 0) {
            this.setState({
                showAcceptPopup: true
            })
        }
        else {
            this.setState({
                errorLabel: true
            })
        }

    }
    handleAcceptClose = () => {
        this.setState({
            showAcceptPopup: false
        })
    }
    handleButtonReject = () => {
        //console.log('PopUp needs to come');
        if (this.state.selected.length > 0) {
            this.setState({
                showRejectPopup: true
            })
        }
        else {
            this.setState({
                errorLabel: true
            })
        }

    }
    handleRejectClose = () => {
        this.setState({
            showRejectPopup: false
        })
    }
    handleButtonEdit = () => {
        //console.log('PopUp needs to come');
        if (this.state.selected.length === 1) {
            this.setState({
                showEditPopup: true
            })
        }

    }
    handleButtonEditCancel = () => {
        this.setState({
            showEditPopup: false
        })
    }
    handleButtonUpdate = async () => {
        this.setState({
            showEditPopup: false,
            //isLoaded: false,
            showPopupLoader: true,
            selected: [],
            selectedUsersDetails: [],
        })
        await this.getAllUsersDetails();
    }
    handleClickOnStatusAccept = async () => {
        //console.log('Update Call to API');
        const service = new ApiServices();
        var query = `call "iMS".phm_ims_updateRegistrationDetails(array [${this.state.selected}])`;
        var res = await service.updateDbService(query)
        if (res) {
            this.emailModule(this.state.emailBodyForAccept, this.state.selectedUsersDetails, this.state.emailSubOnAccept)
        }
        //console.log(query);
    }
    handleClickOnStatusReject = async () => {
        //console.log('Update Call to API');
        const service = new ApiServices();
        var clause = "wwid in (";
        if (this.state.selected) {
            this.state.selected.map((item, index) => {
                if (index === 0) {
                    clause += `${item}`
                }
                else {
                    clause += `,${item}`
                }
                return null;
            })
            clause += ")"
        }
        var query = "UPDATE \"iMS\".phm_ims_registrationdetails SET status = 'Rejected' where " + clause;
        var res = service.updateDbService(query);
        if (res) {
            this.emailModule(this.state.emailBodyForReject, this.state.selectedUsersDetails, this.state.emailBodyForReject)
        }
    }
    setErrorStatus = () => {
        if (this.state.selected.length > 0) {
            //console.log(this.state.selected);
            //console.log(this.state.selectedUsersDetails);
            this.setState({
                errorLabel: false
            })
        }
    }
    handleSelectRow = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.wwid],
                selectedUsersDetails: [...this.state.selectedUsersDetails, { 'username': `${row.username}`, 'email': `${row.email}` }]
            }), this.setErrorStatus);
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.wwid),
                selectedUsersDetails: this.state.selectedUsersDetails.filter(x => x.email !== row.email)
            }), this.setErrorStatus);
        }
    }
    handleSelectAllRow = (isSelect, rows) => {
        const ids = rows.map(r => r.wwid);
        const usersDetails = rows.map(r => {
            return { 'username': `${r.username}`, 'email': `${r.email}` }
        });
        if (isSelect) {
            this.setState(() => ({
                selected: ids,
                selectedUsersDetails: usersDetails
            }), this.setErrorStatus);
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((el) => !ids.includes(el)), //[],
                selectedUsersDetails: this.state.selectedUsersDetails.filter(ar => !usersDetails.find(rm => (rm.username === ar.username && ar.email === rm.email))) //[]
            }), this.setErrorStatus);
        }
    }
    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );

    componentWillUnmount() {
        let login = new Services();
        login.logService(`Admin page successfully unmounted for ${this.userid.toLowerCase()}`, 'info');
        window.removeEventListener('resize', this.updateDimensions);
    }
    render() {
        const { ExportCSVButton } = CSVExport;
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            onSelect: this.handleSelectRow,
            onSelectAll: this.handleSelectAllRow,
            selected: this.state.selected
        };
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            disablePageTitle: true,
            totalSize: this.state.selectedUsersDetails.length,
            // totalSize: this.state.products.length,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: this.state.products.length
            }] // A numeric array is also available. the purpose of above example is custom the text
        };
        return (
            <div>
                <ErrorBoundary>
                    {this.state.userExists ? <>
                        <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                        {/* <div style={{ margin: "0 1.5%" }}> */}
                        <Header />
                        {/* </div> */}
                        {this.state.isLoaded ?
                            <div>
                                {this.state.userWWID !== 0 && this.state.isLoaded ?
                                    <div className="admin-dashboard-content-wrapper">
                                        <div className="admin-data-table-wrapper">
                                            <ToolkitProvider
                                                keyField='wwid'
                                                data={this.state.products}
                                                columns={this.state.columns}
                                                exportCSV={{ fileName: 'RegistrationDetails.csv', onlyExportFiltered: true, exportAll: false }}
                                /* search = {{}} */>
                                                {
                                                    props => (
                                                        <div>
                                                            <div className="admin-data-function-button-wrapper">
                                                                <div className="admin-heading-leftPart">
                                                                    <span className="admin-console-heading">Admin Console</span>
                                                                </div>
                                                                <div className="admin-console-button-container">
                                                                    <Button text="Reject" customColor='#FF8000' action={this.handleButtonReject} />
                                                                    <Button text="Accept" customColor='#2EB000' action={this.handleButtonAccept} />
                                                                    {/* <Button text="Export Csv" customColor='#0074B0' action={() => { this.props.history.push('/') }} /> */}
                                                                    <div className="react-bs-table-csv-btn-wrapper">
                                                                        <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton></div>
                                                                    <Button text="Edit" isDisabled={!(this.state.selected && this.state.selected.length === 1)} customColor={(this.state.selected && this.state.selected.length === 1) ? '#f44336' : '#B2B2B2'} action={this.handleButtonEdit} />
                                                                </div>
                                                                {/* <div style={{ float: "right" }}>
                                                                    <div className="react-bs-table-csv-btn-wrapper">
                                                                        <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton></div>
                                                                </div> */}
                                                                {/* <div style={{ textAlign: "left" }}>
                                                                    <Button variant="success" onClick={this.handleButtonAccept}>Accept</Button>{'   '}
                                                                    <Button variant="danger" onClick={this.handleButtonReject}>Reject</Button>
                                                                </div> */}
                                                            </div>
                                                            {this.state.errorLabel &&
                                                                <div>
                                                                    <p className="errorLabel" style={{ textAlign: "left" }}>None of the items are selected</p>
                                                                </div>
                                                            }
                                                            {/* <SearchBar { ...props.searchProps } /> */}
                                                            <BootstrapTable
                                                                {...props.baseProps}
                                                                bordered={false}
                                                                filter={filterFactory()} pagination={paginationFactory(options)} selectRow={selectRow} wrapperClasses="table-responsive adminconsole-table"
                                                                classes="adminDashboard-table"
                                                                rowClasses="text-nowrap">
                                                            </BootstrapTable>

                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </div>
                                        {this.state.showAcceptPopup &&
                                            <div className="modal-Popup-wrapper">
                                                <Modal
                                                    show={this.state.showAcceptPopup}
                                                    backdrop="static"
                                                    keyboard={false}
                                                    dialogClassName="modal-PopUp-inner-wrapper"
                                                    centered
                                                >
                                                    <Modal.Header>
                                                        <Modal.Title>Confirmation</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        Are you sure you want to accept the selected record(s)?
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button text="Cancel" customColor='#FF8000' action={
                                                            this.handleAcceptClose
                                                        } />
                                                        <Button text="Ok" customColor='#2EB000' action={
                                                            this.handleClickOnStatusAccept
                                                        } />
                                                        {/* <Button variant="secondary admin-button-custom-class" onClick={this.handleAcceptClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button className="btn-custom-class admin-button-custom-class" onClick={this.handleClickOnStatusAccept}>Ok</Button> */}
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        }
                                        {this.state.showRejectPopup &&
                                            <div className="modal-Popup-wrapper">
                                                <Modal

                                                    show={this.state.showRejectPopup}
                                                    backdrop="static"
                                                    keyboard={false}
                                                    dialogClassName="modal-PopUp-inner-wrapper"
                                                    centered
                                                >
                                                    <Modal.Header>
                                                        <Modal.Title>Confirmation</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        Are you sure you want to reject the selected record(s)?
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button text="Cancel" customColor='#FF8000' action={
                                                            this.handleRejectClose
                                                        } />
                                                        <Button text="Ok" customColor='#2EB000' action={
                                                            this.handleClickOnStatusReject
                                                        } />
                                                        {/* <Button variant="secondary admin-button-custom-class" onClick={this.handleRejectClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="primary admin-button-custom-class" onClick={this.handleClickOnStatusReject}>Ok</Button> */}
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        }
                                        {this.state.showEditPopup &&
                                            <UpdateUserDetailsPopUp showUserDetails={this.state.showEditPopup} customWidth={this.state.width} customHeight={this.state.height} handleButtonEditCancel={this.handleButtonEditCancel} handleButtonUpdate={this.handleButtonUpdate} selectedWwid={this.state.selected[0]} />
                                        }
                                        {this.state.showPopupLoader && <Modal
                                            show={this.state.showPopupLoader}
                                            size="lg md sm xs"
                                            centered
                                            aria-labelledby="contained-modal-title-vcenter"
                                            dialogClassName="savePopUp"
                                            scrollable={true}
                                        >

                                            <div style={{ padding: "0 5%" }}>
                                                <div style={{ fontSize: "14px", textAlign: "center" }}>
                                                    <Modal.Body>
                                                        <img src={imsLoader} alt="" width={100} height={100} />
                                                        <div className="saveModal-heading" style={{ color: "#0074B0" }}>Please wait while updating user details.</div>
                                                    </Modal.Body>
                                                </div>
                                            </div>

                                        </Modal>}
                                    </div> :
                                    <div className="admin-page-authorization-wrapper">
                                        <p style={{ margin: "5%" }}>You are not authorized to view this page</p>
                                    </div>
                                }
                            </div>
                            :
                            <div style={{ alignItems: "center", height: "70vh", paddingTop: "10%" }}>
                                <img src={imsLoader} alt="Loading Data" style={{ width: "5%" }} />
                                {/* <div className="gradient-loader"></div> */}
                            </div>
                        }
                        <div style={{ margin: "0 1.5%" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div> </> :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                        </div>
                    }
                </ErrorBoundary>
            </div>

        )
    }
}
export default AdminDashboard;