
import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component, useEffect, useState } from "react";
import Button from "../Button";
import './CreateFolderPopUp.css';
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import ApiServices from "../../utils/apiServices";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import axios from "axios";

const DeleteLexiPopUp = (props) => {
    //const selectedId = props.id;
    const [selectedId, setSelectedId] = useState();
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageShowModal, setAlertMessageShowModal] = useState(false);
    useEffect(() => {
        let selected_id = [];
        if (props.phonemeDelete) {
            props.id.forEach(ele => {
                selected_id = selected_id.concat(ele.phonemeid)
            });
        }
        else {
            props.id.forEach(ele => {
                selected_id = selected_id.concat(ele.id)
            })
        }
        setSelectedId(`(${selected_id.join(', ')})`);
    }, [props.id])
    console.log(selectedId)
    const handleDelete = async () => {
        const service = new ApiServices();
        //api
        let dbQuery
        if (props.phonemeDelete) {
            dbQuery = `delete from "iMS".phm_ims_ttsphoneme where id in ${selectedId}`;
        }
        else {
            dbQuery = `delete from "iMS".phm_ims_ttslexicon where id in ${selectedId}`;
        }
        let res = await axios.get(`https://stage-ims.jnj.com/api/db/runquery?dbquery=${dbQuery}`);
        if (res) {
            setAlertMessageShowModal(true)
            setSelectedId()
            if (props.phonemeDelete) {
                setAlertMessage(" Selected Phoneme  Deleted Successfully");
            }
            else {
                setAlertMessage("Lexicon File Deleted Successfully");
            }
        }
    }
    const closeAlertMessage = () => {
        setAlertMessage("");
        setAlertMessageShowModal(false);
    }
    console.log()
    return (<>
        <Modal
            show={props.showModal}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            scrollable={false}
        >
            <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                <div className="popup-cancel-btn-wrapper">
                    <img
                        src={popCancelBttn}
                        alt=""
                        onClick={e => {
                            props.handleClose()
                        }}
                    />
                </div>
                <div>
                    <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                        <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                            <Modal.Title>
                                <div>
                                    Delete File
                                </div>
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
                        {(props.phonemeDelete) ? <div className="text" style={{ marginLeft: '50px' }}>Are you sure you want to delete the selected item ? This action is irreversible.
                        </div> : <div className="text" style={{ marginLeft: '50px' }}>Are you sure you want to delete the selected item .And all phonemes associated with this files will also get deleted.This action is irreversible
                        </div>}
                        <div className="reading-list-button-wrapper1">
                            <div style={{ width: "20%", display:"flex",justifyContent:"space-between"}}>
                            <Button text="Delete" customColor='#0074B0' action={() => {
                                handleDelete()
                            }} />
                            <Button text="Cancel" customColor="#0074B0" action={() => {
                                props.handleClose()
                            }} />
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </div>
            {alertMessageShowModal && <AlertMessagePopUpModal color='#00c12c' content={alertMessage}
                showModal={alertMessageShowModal}
                handleClose={() => {
                    closeAlertMessage();
                    props.handleClose();
                    //window.location.reload();
                }} />}
        </Modal>
    </>)
}
export default DeleteLexiPopUp;