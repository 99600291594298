import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';


import {
    collapseFilterWhite,
    expandFilterWhite,
    fieldedSrchIcon,
    DownloadButton,
    saveBlueIcon,
    arrowIconBlue,
    PlusIconBlue,
    deleteIcon,
    RenameIcon,
    ExportIcon,
    UploadIcon,
    TaskIcon,
    FileIcon,
} from "../../assets";

class TTSSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            collapsed: false,
        }
    }

    render() {
        let { collapsed } = this.state;

        let pathname = window.location.pathname
        return (<>
            <Sidebar className="TSS-sidebar" collapsed={collapsed} >
                <Menu>
                    <div className={collapsed ? "TSS-menuitem-header TSS-menuitem-header-collapsed" : "TSS-menuitem-header TSS-menuitem-header-expand"} >
                        {collapsed ?
                            <>
                                <div className="TSS-menuitem-arrow-left-to-line" onClick={() => { this.setState({ collapsed: false }); }}>
                                    <img src={expandFilterWhite} alt="expandFilterWhite" />
                                </div>
                            </> :
                            <>
                                <div className="TSS-menuitem-arrow-left-to-line" onClick={() => { this.setState({ collapsed: true }); }}>
                                    <img src={collapseFilterWhite} alt="expandFilterWhite" />
                                </div>
                            </>
                        }
                    </div>
                    <MenuItem component={<Link to="/TTSHome" />} className={pathname === "/TTSHome" ? "TSS-menuitem-selected" : ""}>
                        {collapsed ?
                            <div>
                                <img src={FileIcon}
                                    width="24"
                                    height="24"
                                    alt="" />
                            </div> : <div className="gen-ai-answering-menuitem-home">
                                <img src={FileIcon}
                                    width="24"
                                    height="24"
                                    alt="" />
                                <div className="gen-ai-answering-text-align-left relative">My Files</div>
                            </div>}
                    </MenuItem>
                    <MenuItem component={<Link to="/TTSMyTask" />} className={pathname === "/TTSMyTask" ? "TSS-menuitem-selected" : ""}>
                        {collapsed ? <div>
                            <img src={TaskIcon}
                                width="24"
                                height="24"
                                alt="" />
                        </div> : <div className="gen-ai-answering-menuitem-library">
                            <img src={TaskIcon}
                                width="24"
                                height="24"
                                alt="" />
                            <div className="gen-ai-answering-text-align-left relative">All Phoneme File</div>
                        </div>}
                    </MenuItem>
                    <MenuItem component={<Link to="/AllLexiconFile" />} className={pathname === "/AllLexiconFile" ? "TSS-menuitem-selected" : ""}>
                        {collapsed ? <div>
                            <img src={TaskIcon}
                                width="24"
                                height="24"
                                alt="" />
                        </div> : <div className="gen-ai-answering-menuitem-library">
                            <img src={TaskIcon}
                                width="24"
                                height="24"
                                alt="" />
                            <div className="gen-ai-answering-text-align-left relative">All Lexicon File</div>
                        </div>}
                    </MenuItem>
                </Menu>
            </Sidebar>
        </>
        )

    }

}
export default withRouter(TTSSidebar);