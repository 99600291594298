/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import trackingService from '../../services/trackingService';
import DocumentDescriptor from '../../components/DocumentDescriptor';
import KeyTakeaways from '../../components/KeyTakeaways';
import { infoIcn } from '../../assets'
import GenerateArrayStrings, { getVarType } from '../../components/GenerateArrayStrings'
import OrderFromRightFind from '../../components/OrderFromRightFind';
import OpenWindow from '../../components/OpenWindow';
import moment from 'moment';
// import ActionBar from "../../components/ActionBar";
import { uniq } from 'underscore'

class HtmlDocumentResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // item: [],
            // documentIndex: [],
            item: this.props.item,
            documentIndex: this.props.count,
            collapsed: false,
            appliedFilters: [],
            searchKeyword: '',
            pageSize: "",
            pageNumber: "",
            actionBarCollapsed: false,
            isCollapseAll: false,
            isFromDownloadHtml: false
        }
        this.width = "";
    }
    componentWillMount() {
        this.setState({
            item: this.props.item,
            documentIndex: this.props.count,
            collapsed: this.props.collapsed,
            appliedFilters: this.props.appliedFilters,
            searchKeyword: this.props.searchKeyword,
            pageSize: this.props.pageSize,
            pageNumber: this.props.pageNumber,
            isCollapseAll: this.props.isCollapseAll,
            isFromDownloadHtml: this.props.isFromDownloadHtml ? true : false,
        })
        if (this.props.customWidth < 1000) {
            this.width = "4%"
        }
    }
    componentDidUpdate = (prop) => {
        // console.log(this.props.isCollapseAll)
        if (this.props.collapsed !== this.state.collapsed) {
            this.setState({ collapsed: this.props.collapsed })
        }
        // console.log(prop.isCollapseAll)
        // this.setState({collapsed:prop.isCollapseAll})
        // if(this.props.isCollapseAll !== this.state.collapsed){
        //     console.log(this.props.isCollapseAll)
        //     this.setState({collapsed:this.props.isCollapseAll})
        // }
        // if(this.props.customWidth < 1000){
        //     this.width = "4%"
        // }
    }
    indexData = event => {
        var title = [event.target.getAttribute('data-title')];
        var products = [event.target.getAttribute('data-products')];
        var docnumber = [event.target.getAttribute('data-docnumber')];
        var docversion = [event.target.getAttribute('data-version')];
        var contentsource = [event.target.getAttribute('data-contentsource')];
        var docID = [event.target.getAttribute('data-docid')];
        var docType = [event.target.getAttribute('data-doctype')];
        var docUrl = [event.target.getAttribute('data-docurl')];
        var doclastModified = [event.target.getAttribute('data-doclastmodified')];
        var orderedBy = [event.target.getAttribute('data-orderedby')];
        var orderedFor = [event.target.getAttribute('data-orderedfor')];
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View OR Download Document', 'Direct', window.location.href, this.state.searchKeyword, this.state.appliedFilters, title, docversion, docnumber, docID, docUrl, products, docType, 'View Document', '', '', contentsource, this.props.pageNumber, this.props.pageSize, doclastModified, orderedBy, orderedFor);
    }
    indexPubMedData = event => {
        var title = [event.target.getAttribute('data-title')];
        var docUrl = [event.target.getAttribute('data-docurl')]
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View OR Download PubMed Document', 'Direct', window.location.href, this.state.searchKeyword, this.state.appliedFilters, title, '', '', '', docUrl, '', '', 'View PubMed Document', '', '', 'PubMed', this.props.pageNumber, this.props.pageSize, '');
    }
    handleProtocolID = (protocolIDs) => {
        let ids = GenerateArrayStrings(protocolIDs)
        let url = window.location.origin + `/results?query=&q=All%20Fields&filters=&f1=protocolIDs&fval1=${ids}&fcount=1`;
        OpenWindow(url)
    }
    handlePubMedID = (pmID) => {
        let url = `https://pubmed.ncbi.nlm.nih.gov/${pmID}/?otool=pajanslib`;
        OpenWindow(url)
    }
    handleNCTID = (nctID) => {
        let url = `https://clinicaltrials.gov/ct2/show/${nctID}`;
        OpenWindow(url)
    }
    extractNCTId = (protocolIdsArr) => {
        var pidNCTArray = [];
        var pidStream = "";
        var nctStream = "";
        let nctId = protocolIdsArr.filter(elm => elm.startsWith('NCT'))
        let pIds = protocolIdsArr.filter(elm => !elm.startsWith('NCT'))
        if (pIds.length > 0) {
            pidStream = pIds.toString().split(',').join(';');
        }
        if (nctId.length > 0) {
            nctStream = nctId.toString().split(',').join(';');
        }
        pidNCTArray.push(pidStream)
        pidNCTArray.push(nctStream)
        return pidNCTArray;
    }
    renderNCThyperLinks = (nctIDs) => {
        if (nctIDs.length > 0) {
            return (
                nctIDs.map((item, index) => {
                    if (index === nctIDs.length - 1) {
                        return (
                            <a href="javascript:void(0)" title="Click this field to view the full record on ClinicalTrials.gov web site" onClick={e => this.handleNCTID(item)} key={index}>{item}</a>
                        )
                    }
                    else {
                        return (
                            <a href="javascript:void(0)" title="Click this field to view the full record on ClinicalTrials.gov web site" onClick={e => this.handleNCTID(item)} key={index}>{item}; </a>
                        )
                    }
                    //return null;
                })
            )
        }
    }
    generateDocumentTeaser = (teaserData) => {
        let str = ''
        if (teaserData.length > 0) {
            teaserData.map((item, index) => {
                str += item + "..."
            })
        }
        //console.log(str)
        //console.log(this.convertToHtml(str))
        return str;
    }
    convertToHtml = (item) => {
        return (<div dangerouslySetInnerHTML={{ __html: item }}></div>)
    }
    handleToggleSelect = (collapseStatus) => {
        //console.log(collapseStatus)
        this.setState({
            // collapsed: !this.state.collapsed
            collapsed: collapseStatus
        })
    }
    formattedStringArr = (array, fn) => {
        var result = [];
        for (var i = 0; i < array.length; i++) {
            var mapping = fn(array[i]);
            result = result.concat(mapping);
        }
        return result;
    }
    divider = () => {
        return (
            <p></p>
        )
    }
    textFormatter = (data) => {
        const replacedString = data.replaceAll("%%##%%##", '%%##').replaceAll("%%##", '\n');
        let _this = this;
        const updatedText = this.formattedStringArr(replacedString.split('\n'), function (part) {
            return [part, <div>{_this.divider()}</div>];
        });
        updatedText.pop()
        return (
            <div>
                {updatedText}
            </div>
        )
    }
    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }
    titleCase(str) {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    }
    render() {
        var approvedDate;
        if (this.state.item.approvedDate) {
            approvedDate = moment(this.state.item.approvedDate).format("D-MMM-YYYY");
        }
        var modifiedDate;
        if (this.state.item.modifiedDate) {
            modifiedDate = moment(this.state.item.modifiedDate).format("D-MMM-YYYY");
        }
        else {
            modifiedDate = moment(this.state.item.lastUpdated).format("D-MMM-YYYY");
        }
        let ctNames = [];
        if (this.state.item.ctNames || this.state.item.clinicalTrialName) {
            if (this.state.item.ctNames && this.state.item.ctNames.length > 0) {
                ctNames = this.state.item.ctNames;
            } else {
                if (
                    this.state.item.clinicalTrialName &&
                    this.state.item.clinicalTrialName.length > 0
                ) {
                    if (
                        this.state.item.clinicalTrialName.length > 0 &&
                        Array.isArray(this.state.item.clinicalTrialName)
                    ) {
                        ctNames = this.state.item.clinicalTrialName;
                    } else {
                        ctNames = [this.state.item.clinicalTrialName];
                    }
                }
            }
        }
        // console.log(this.props.collapsed,"xx",this.state.item);
        if (window.location.pathname !== '/pubMedResults' && !this.state.isPubMedHtml) {
            let { orderedBy, orderedFor } = this.state.item;
            if (!orderedBy) orderedBy = '';
            if (!orderedFor) orderedFor = '';
            //#region for iMS render
            if (this.state.item.domain === 'SKM') {
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                let url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
                let downloadurl = `/api/dcs-spo/download?connID=${this.state.item.connID}%26csID=${this.state.item.csID}%26docID=${this.state.item.docID}%26fileType=${this.state.item.fileExtension}%26fileName=${this.state.item.docTitle}`
                let mailUrl = window.location.origin + downloadurl
                let mailbody = `Title: ${this.state.item.docTitle}%0D%0DUrl: ${mailUrl}%0D%0DFor access to iMedical Search, please contact iMedical Search Team `
                let mailref = `mailto:${this.state.item.contactEmail}?Subject=iMedical Search Document: ${this.state.item.docTitle}&body=${mailbody}`
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" /* to={url} */ href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.domain} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                        <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed} />
                    </span> */}
                        </div>
                        {/* {this.state.collapsed === false? */}
                        {!this.state.collapsed ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {/* this.state.item.majorTopicsSynonyms &&
                                  <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                               */}
                                {/* {this.props.itemTeaser !== undefined &&
                    this.props.itemTeaser.length > 0 && (
                      <div className="PHM-MetaDataProperty">
                        <div style={{ display: "block" }}>
                          <div>
                            {this.convertToHtml(
                              this.generateDocumentTeaser(this.props.itemTeaser)
                            )}
                            <br />
                          </div>
                        </div>
                      </div>
                    )} */}
                                {this.state.item.authors !== undefined &&
                                    this.state.item.authors.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Authors:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {getVarType(this.state.item.authors) !== "Array"
                                                            ? this.state.item.authors
                                                            : GenerateArrayStrings(this.state.item.authors)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.owner && (
                                    <div className="PHM-MetaDataProperty">
                                        <img
                                            src={infoIcn}
                                            title="Click the name in this field to e-mail a question or comment about this document to this person"
                                            className="citation-info-icon-wrapper"
                                            alt="Click the name in this field to e-mail a question or comment about this document to this person"
                                        />
                                        <div style={{ display: "block" }}>
                                            <label>Owner:&nbsp;</label>
                                            <b>
                                                <label>
                                                    <a href={mailref}>{this.state.item.owner}</a>
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.product !== undefined &&
                                    this.state.item.product.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Product:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(this.state.item.product)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.usageGuidelines && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Usage Guidelines:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.usageGuidelines}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.audience && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Audience:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.audience}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.congressName !== undefined &&
                                    this.state.item.congressName.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Congress Name:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(
                                                            this.state.item.congressName
                                                        )}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {/* {modifiedDate &&
                    this.state.item.modifiedDate !== undefined &&
                    this.state.item.modifiedDate.length > 0 && (
                      <div className="PHM-MetaDataProperty">
                        <div style={{ display: "block" }}>
                          <label>Last Updated:&nbsp;</label>
                          <b>
                            <label>{modifiedDate}</label>
                          </b>
                        </div>
                      </div>
                    )} */}
                                {this.state.item.maintValidUntil && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Valid Until:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {moment(this.state.item.maintValidUntil).format("D-MMM-YYYY")}{" "}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.assetType && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Asset Type:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.assetType}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.description && (
                                    <div className="PHM-MetaDataProperty">
                                        <div
                                            style={{ display: "block" }}
                                            className="wrapDescription"
                                        >
                                            <label>Description:&nbsp;</label>
                                            <b>
                                                <span style={{ wordWrap: "break-word" }}>
                                                    {this.state.item.description}
                                                </span>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.languages !== undefined &&
                                    this.state.item.languages.length > 0 ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.csName && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Content Source:&nbsp;</label>
                                            <b>
                                                <label>NAVIGATOR</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.status && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.status}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>

                )
            }
            else if (this.state.item.domain === 'RIMdocs') {
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                // let url = `/DocURL?ID=${this.state.item.docTitle}&cs=${this.state.item.domain}`
                let url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}`
                //console.log(url)
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" /* to={url} */ href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.jj_number} data-version={this.state.item.r_version_label} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed} />
                        </span> */}
                        </div>
                        {!this.state.collapsed ?
                            <div id={`docID-${this.state.documentIndex}`} className="citation-results-meta-property-wrapper">
                                {/* this.state.item.majorTopicsSynonyms &&
                        <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                     */}
                                {this.props.itemTeaser !== undefined && this.props.itemTeaser.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <div>{this.convertToHtml(this.generateDocumentTeaser(this.props.itemTeaser))}<br /></div>
                                        </div>
                                    </div>
                                }
                                {this.state.item.object_name &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>File Name: </label>
                                            <b>
                                                <label>{this.state.item.object_name}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.product_code !== undefined && this.state.item.product_code.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Primary Compound ID: </label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.product_code)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {(this.state.item.product_generic_name !== undefined &&
                                    this.state.item.product_generic_name.length > 0) &&
                                    Array.isArray(this.state.item.product_generic_name) && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Generic Name:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(uniq(this.state.item.product_generic_name.filter(x => x)))}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {(this.state.item.product_generic_name !== undefined &&
                                    this.state.item.product_generic_name.length > 0) &&
                                    !Array.isArray(this.state.item.product_generic_name) && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Generic Name:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {this.state.item.product_generic_name}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {(this.state.item.product_generic_name_alias !== undefined &&
                                    this.state.item.product_generic_name_alias.length > 0) && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Generic Name (Aliases):&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {this.state.item.product_generic_name_alias}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.jj_component_name &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Component Name: </label>
                                            <b>
                                                <label>{this.state.item.jj_component_name}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.protocolIDs !== undefined && this.state.item.protocolIDs.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <img src={infoIcn} title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab." alt="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab." className="citation-info-icon-wrapper" />
                                        <div style={{ display: "block" }}>
                                            <label>Protocol No: </label>
                                            <b>
                                                <label><a href="javascript:void(0)" title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab" onClick={evt => this.handleProtocolID(this.state.item.protocolIDs)}>{GenerateArrayStrings(this.state.item.protocolIDs)}</a></label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.protocolIDAliases !== undefined && this.state.item.protocolIDAliases.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Protocol No (Aliases): </label>
                                            <b>
                                                <label><a href="javascript:void(0)">{this.state.item.protocolIDAliases}</a></label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.jj_number &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document No: </label>
                                            <b>
                                                <label>{this.state.item.jj_number}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.phaseOfStudy !== undefined && this.state.item.phaseOfStudy > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Phase of Study: </label>
                                            <b>
                                                <label>{this.state.item.phaseOfStudy}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.pageCount &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Page Count: </label>
                                            <b>
                                                <label>{this.state.item.pageCount}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {approvedDate &&
                                    <div className="PHM-MetaDataProperty">
                                        <img src={infoIcn} title="The Approved Date is the date that this document was internally approved." alt="The Approved Date is the date that this document was internally approved." className="citation-info-icon-wrapper" />
                                        <div style={{ display: "block" }}>
                                            <label>Approved Date: </label>
                                            <b>
                                                <label>{approvedDate}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {modifiedDate &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Modified Date: </label>
                                            <b>
                                                <label>{modifiedDate}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.r_version_label &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>System Version Number: </label>
                                            <b>
                                                <label>{this.state.item.r_version_label}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.docType !== undefined && this.state.item.docType.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Type: </label>
                                            <b>
                                                <label>{this.state.item.docType}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.jj_artifact_short_name &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Artifact Short Name: </label>
                                            <b>
                                                <label>{this.state.item.jj_artifact_short_name}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.jj_reason_for_revision &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Reason For Revision: </label>
                                            <b>
                                                <label>{this.state.item.jj_reason_for_revision}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.languages ?
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language: </label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div> :
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language: </label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.domain &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Content Source: </label>
                                            <b>
                                                <label>{this.state.item.domain}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.a_status &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status: </label>
                                            <b>
                                                <label>{this.state.item.a_status}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                            </div> : null}
                    </div>
                )
            }
            else if (this.state.item.domain === 'SRD') {
                //console.log(this.state.item)
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                let url
                if (this.state.item.fileExtension !== 'pdf') {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}&fileName=${this.state.item.docTitle}`
                }
                else {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
                }
                if (this.state.item.a_status === "Approved Needs Review") {
                    url = url + `&docState=anr`
                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" /* to={url} */ href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-version={this.state.item.majorVersionNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed}/>
                        </span> */}
                        </div>
                        {!this.state.collapsed ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {/* this.state.item.majorTopicsSynonyms &&
                              <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                           */}
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.fileName && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>File Name:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.fileName}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.primaryCompoundID !== undefined &&
                                    this.state.item.primaryCompoundID.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Primary Compound ID:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.primaryCompoundID}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.genericName !== undefined &&
                                    this.state.item.genericName.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Generic Name:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(
                                                            this.state.item.genericName
                                                        )}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.componentName && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Component Name:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.componentName}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.protocolIDs !== undefined &&
                                    this.state.item.protocolIDs.length > 0 ? (
                                    <div className="PHM-MetaDataProperty">
                                        <img
                                            src={infoIcn}
                                            title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab."
                                            className="citation-info-icon-wrapper"
                                            alt="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab."
                                        />
                                        <div style={{ display: "block" }}>
                                            <label>Protocol No:&nbsp;</label>
                                            <b>
                                                <label>
                                                    <a
                                                        href="javascript:void(0)"
                                                        title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab"
                                                        onClick={(evt) =>
                                                            this.handleProtocolID(
                                                                this.state.item.protocolIDs
                                                            )
                                                        }
                                                    >
                                                        {GenerateArrayStrings(
                                                            ctNames.concat(this.state.item.protocolIDs)
                                                        )}
                                                        {/* {GenerateArrayStrings(this.state.item.protocolIDs)} */}
                                                    </a>
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    ctNames &&
                                    ctNames.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Protocol No:&nbsp;</label>
                                                <b>
                                                    <label>{GenerateArrayStrings(ctNames)}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )
                                )}
                                {this.state.item.protocolIDAliases !== undefined &&
                                    this.state.item.protocolIDAliases.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Protocol No (Aliases):&nbsp;</label>
                                                <b>
                                                    <label>
                                                        <a href="javascript:void(0)">
                                                            {this.state.item.protocolIDAliases}
                                                        </a>
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.documentNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document No:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.documentNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.phaseOfStudy !== undefined &&
                                    this.state.item.phaseOfStudy.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Phase of Study:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(
                                                            this.state.item.phaseOfStudy
                                                        )}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.pageCount && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Page Count:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.pageCount}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {approvedDate && (
                                    <div className="PHM-MetaDataProperty">
                                        <img
                                            src={infoIcn}
                                            title="The Approved Date is the date that this document was internally approved."
                                            className="citation-info-icon-wrapper"
                                            alt="The Approved Date is the date that this document was internally approved."
                                        />
                                        <div style={{ display: "block" }}>
                                            <label>Approved Date:&nbsp;</label>
                                            <b>
                                                <label>{approvedDate}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {modifiedDate &&
                                    this.state.item.modifiedDate !== undefined &&
                                    this.state.item.modifiedDate.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Modified Date:&nbsp;</label>
                                                <b>
                                                    <label>{modifiedDate}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.majorVersionNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>System Version Number:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.majorVersionNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.docType !== undefined &&
                                    this.state.item.docType.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Document Type:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.docType}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.jj_artifact_short_name && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Artifact Short Name:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {this.state.item.jj_artifact_short_name}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.jj_reason_for_revision && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Reason For Revision:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {this.state.item.jj_reason_for_revision}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.languages ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source:&nbsp;</label>
                                        <b>
                                            <label>RIMdocs</label>
                                        </b>
                                    </div>
                                </div>

                                {this.state.item.status && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.status}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                )
            }
            else if (this.state.item.domain === 'RIMdocs_LL') {
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                // let url = `/DocURL?ID=${this.state.item.docTitle}&cs=${this.state.item.domain}`
                let url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}`
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" /* to={url} */ href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.jj_number} data-version={this.state.item.r_version_label} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed}/>
                        </span> */}
                        </div>
                        {!this.state.collapsed ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {/* this.state.item.majorTopicsSynonyms &&
                              <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                           */}
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.object_name && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>File Name:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.object_name}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.product_code !== undefined &&
                                    this.state.item.product_code.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Primary Compound ID:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(
                                                            this.state.item.product_code
                                                        )}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {(this.state.item.product_generic_name !== undefined &&
                                    this.state.item.product_generic_name.length > 0) &&
                                    Array.isArray(this.state.item.product_generic_name) && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Generic Name:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(uniq(this.state.item.product_generic_name.filter(x => x)))}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {(this.state.item.product_generic_name !== undefined &&
                                    this.state.item.product_generic_name.length > 0) &&
                                    !Array.isArray(this.state.item.product_generic_name) && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Generic Name:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {this.state.item.product_generic_name}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {(this.state.item.product_generic_name_alias !== undefined &&
                                    this.state.item.product_generic_name_alias.length > 0) && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Generic Name (Aliases):&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {this.state.item.product_generic_name_alias}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {/* {this.state.item.jj_component_name && (
                    <div className="PHM-MetaDataProperty">
                      <div style={{ display: "block" }}>
                        <label>Component Name:&nbsp;</label>
                        <b>
                          <label>{this.state.item.jj_component_name}</label>
                        </b>
                      </div>
                    </div>
                  )} */}
                                {this.state.item.protocolIDs !== undefined &&
                                    this.state.item.protocolIDs.length > 0 ? (
                                    <div className="PHM-MetaDataProperty">
                                        <img
                                            src={infoIcn}
                                            title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab."
                                            className="citation-info-icon-wrapper"
                                            alt="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab."
                                        />
                                        <div style={{ display: "block" }}>
                                            <label>Protocol No:&nbsp;</label>
                                            <b>
                                                <label>
                                                    <a
                                                        href="javascript:void(0)"
                                                        title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab"
                                                        onClick={(evt) =>
                                                            this.handleProtocolID(
                                                                this.state.item.protocolIDs
                                                            )
                                                        }
                                                    >
                                                        {GenerateArrayStrings(
                                                            ctNames.concat(this.state.item.protocolIDs)
                                                        )}
                                                        {/* {GenerateArrayStrings(this.state.item.protocolIDs)} */}
                                                    </a>
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    ctNames &&
                                    ctNames.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Protocol No:&nbsp;</label>
                                                <b>
                                                    <label>{GenerateArrayStrings(ctNames)}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )
                                )}
                                {this.state.item.protocolIDAliases !== undefined &&
                                    this.state.item.protocolIDAliases.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Protocol No (Aliases):&nbsp;</label>
                                                <b>
                                                    <label>
                                                        <a href="javascript:void(0)">
                                                            {this.state.item.protocolIDAliases}
                                                        </a>
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.jj_number && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document No:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.jj_number}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.phaseOfStudy !== undefined &&
                                    this.state.item.phaseOfStudy > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Phase of Study:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.phaseOfStudy}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.r_version_label && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>System Version Number:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.r_version_label}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.docType !== undefined &&
                                    this.state.item.docType.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Document Type:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.docType}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.jj_region_country !== undefined && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Country:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.jj_region_country}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.languages ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.jj_reason_for_revision && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Reason For Revision:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {this.state.item.jj_reason_for_revision}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                )}

                                {this.state.item.domain && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Content Source:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.domain}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.a_status && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.a_status}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                )
            }
            else if (this.state.item.domain === 'MLR') {
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                let extractedIds = this.extractNCTId(this.state.item.protocolIDs);
                //console.log(extractedIds)
                let url;
                if (this.state.item.fileExtension !== 'pdf') {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}&fileName=${this.state.item.docTitle}`
                }
                else {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
                }
                if (this.state.item.a_status === "Approved Needs Review") {
                    url = url + `&docState=anr`
                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" /* to={url} */ href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed}/>
                        </span> */}
                        </div>
                        {!this.state.collapsed ?
                            <div id={`docID-${this.state.documentIndex}`} className="citation-results-meta-property-wrapper">
                                {/* this.state.item.majorTopicsSynonyms &&
                        <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                     */}
                                {this.props.itemTeaser !== undefined && this.props.itemTeaser.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <div>{this.convertToHtml(this.generateDocumentTeaser(this.props.itemTeaser))}<br /></div>
                                        </div>
                                    </div>
                                }
                                {this.state.item.authors !== undefined && this.state.item.authors.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Authors: </label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.authors)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.product !== undefined && this.state.item.product.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Products: </label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.product)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Date: </label>
                                        <b>
                                            <label>{this.state.item.year}</label>
                                        </b>
                                    </div>
                                </div>
                                {this.state.item.congressName !== undefined && this.state.item.congressName.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Congress: </label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.congressName)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.literatureSource &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Source: </label>
                                            <b>
                                                <label>{this.state.item.literatureSource}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.docType !== undefined && this.state.item.docType.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Type: </label>
                                            <b>
                                                <label>{this.state.item.docType}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.publicationStatus &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Publication Status: </label>
                                            <b>
                                                <label>{this.state.item.publicationStatus}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.protocolIDs !== undefined && this.state.item.protocolIDs.length > 0 &&
                                    <>
                                        {extractedIds[0] ?
                                            <div className="PHM-MetaDataProperty">
                                                <img src={infoIcn} title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab." alt="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab." className="citation-info-icon-wrapper" />
                                                <div style={{ display: "block" }}>
                                                    <label>Protocol ID: </label>
                                                    <b>
                                                        <label><a href="javascript:void(0)" title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab" onClick={evt => this.handleProtocolID(extractedIds[0].split(';'))}>{GenerateArrayStrings(extractedIds[0].split(';'))}</a></label>
                                                    </b>
                                                </div>
                                            </div> :
                                            <div className="PHM-MetaDataProperty">
                                                <img src={infoIcn} title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab." alt="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab." className="citation-info-icon-wrapper" />
                                                <div style={{ display: "block" }}>
                                                    <label>Protocol ID: </label>
                                                    <b>
                                                        <label><a href="javascript:void(0)" title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab" onClick={evt => this.handleProtocolID(extractedIds[1].split(';'))}>{GenerateArrayStrings(extractedIds[1].split(';'))}</a></label>
                                                    </b>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                {this.state.item.phaseOfStudy !== undefined && this.state.item.phaseOfStudy.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Phase of Study: </label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.phaseOfStudy)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.pmID !== undefined && this.state.item.pmID.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>PubMed ID: </label>
                                            <b>
                                                <label><a href="javascript:void(0)" title="Click this field to view the abstract in PubMed. This will appear in a new browser tab" onClick={e => this.handlePubMedID(this.state.item.pmID)}>{this.state.item.pmID}</a></label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {extractedIds[1] &&
                                    <div className="PHM-MetaDataProperty">
                                        <img src={infoIcn} title="Click this field to view the full record on ClinicalTrials.gov web site" alt="Click this field to view the full record on ClinicalTrials.gov web site" className="citation-info-icon-wrapper" />
                                        <div style={{ display: "block" }}>
                                            <label>NCTID: </label>
                                            <b>
                                                <label>{this.renderNCThyperLinks(extractedIds[1].split(';'))}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.languages ?
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language: </label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div> :
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language: </label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                }

                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source: </label>
                                        <b>
                                            <label>iMedical Knowledge Vault</label>
                                        </b>
                                    </div>
                                </div>
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Status: </label>
                                        <b>
                                            <label>{this.state.item.status}</label>
                                        </b>
                                    </div>
                                </div>
                                {!this.state.isFromDownloadHtml && this.state.item.pmID !== undefined && this.state.item.pmID.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <OrderFromRightFind pubMedId={this.state.item.pmID} />
                                        </div>
                                    </div>
                                }
                                {this.state.isFromDownloadHtml && this.state.item.pmID !== undefined && this.state.item.pmID.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <a href={`https://www.rightfind.com/vlib/order/orderform.aspx?ClientId=60&qlu=${this.state.item.pmID}`}>
                                                <div className="citation-right-find-descriptor-wrapper">
                                                    <div className="citation-right-find-descriptor-header-wrapper">
                                                        <span style={{ verticalAlign: "middle", paddingRight: "15px" }}>Order from RightFind for additional usages </span>
                                                        <img src={infoIcn} className="order-info-icn" title="You have the digital rights to use the full document associated with this citation for any internal purpose. For other usages, including External Reactive Use, Proactive Use, Transmission to a Regulatory Agency, or Special Orders of charts, tables, etc., click on this option to order via RightFind and select the appropriate usage option." alt="You have the digital rights to use the full document associated with this citation for any internal purpose. For other usages, including External Reactive Use, Proactive Use, Transmission to a Regulatory Agency, or Special Orders of charts, tables, etc., click on this option to order via RightFind and select the appropriate usage option." />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                }
                                {!this.state.isFromDownloadHtml && this.state.item.keyTakeaways &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <KeyTakeaways keyTakeaways={this.state.item.keyTakeaways} />
                                        </div>
                                    </div>
                                }
                                {this.state.isFromDownloadHtml && this.state.item.keyTakeaways &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <b><label>Key Takeaways: </label></b>
                                            <div style={{ paddingLeft: "20px" }}>
                                                {this.textFormatter(this.state.item.keyTakeaways)}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div> : null}
                    </div>
                )
            }
            else if (this.state.item.domain === "SFM") {
                let url;
                if (this.state.item.fileExtension !== "pdf") {
                    url = `/api/dcs-vv/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}}&fileName=${encodeURIComponent(this.state.item.docTitle.toString().replace(/\n/g, " "))}`;
                } else {
                    url = `/api/dcs-vv/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}}&fileName=${encodeURIComponent(this.state.item.docTitle.toString().replace(/\n/g, " "))}`;
                }
                if (this.state.item.status === "Approved Needs Review") {
                    url = url + `&docState=anr`;
                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span
                                    style={{
                                        color: "#0074B0",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {this.state.documentIndex}.{" "}
                                </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" rel="noopener noreferrer" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-version={this.state.item.majorVersionNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                        </div>
                        {!this.state.collapsed /*  && !this.props.isCollapseAll */ ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.authors !== undefined &&
                                    this.state.item.authors.length > 0 ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Authors:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {getVarType(this.state.item.authors) !== "Array"
                                                        ? this.state.item.authors
                                                        : GenerateArrayStrings(this.state.item.authors)}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    this.state.item.owner && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Authors:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.owner}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )
                                )}
                                {modifiedDate &&
                                    this.state.item.modifiedDate !== undefined &&
                                    this.state.item.modifiedDate.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Modified Date:&nbsp;</label>
                                                <b>
                                                    <label>{modifiedDate}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.docType !== undefined &&
                                    this.state.item.docType.length > 0 &&
                                    this.state.item.domain !== "SFM" && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Document Type:&nbsp;</label>
                                                {this.state.item.gcsrcontent ? (
                                                    <b>
                                                        <label>{this.state.item.docType} - GCSR</label>
                                                    </b>
                                                ) : (
                                                    <b>
                                                        <label>{this.state.item.docType}</label>
                                                    </b>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.presentationType && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Presentation Type:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.presentationType}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.countries !== undefined &&
                                    this.state.item.countries.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Country:&nbsp;</label>
                                                {this.state.item.countries.includes(
                                                    "unitedStates"
                                                ) ? (
                                                    <b>
                                                        <label>United States</label>
                                                    </b>
                                                ) : (
                                                    <b>
                                                        <label>
                                                            {GenerateArrayStrings(
                                                                this.state.item.countries
                                                            )}
                                                        </label>
                                                    </b>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.languages ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.product !== undefined &&
                                    this.state.item.product.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Products:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(this.state.item.product)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.mslTherapeuticArea && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>MSL Therapeutic Area:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.mslTherapeuticArea}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.documentNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Number:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.documentNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.majorVersionNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Major Version:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.majorVersionNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source:&nbsp;</label>
                                        <b>
                                            <label>iMedical Knowledge Vault</label>
                                        </b>
                                    </div>
                                </div>
                                {this.state.item.status && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.status}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                );
            }
            else if (this.state.item.domain === "GCSR") {
                let url;
                if (this.state.item.fileExtension !== "pdf") {
                    url = `/api/dcs-vv/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}&fileName=${encodeURIComponent(this.state.item.docTitle.toString().replace(/\n/g, " "))}`;
                } else {
                    url = `/api/dcs-vv/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}&fileName=${encodeURIComponent(this.state.item.docTitle.toString().replace(/\n/g, " "))}`;
                }
                if (this.state.item.status === "Approved Needs Review") {
                    url = url + `&docState=anr`;
                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span
                                    style={{
                                        color: "#0074B0",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {this.state.documentIndex}.{" "}
                                </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" rel="noopener noreferrer" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-version={this.state.item.majorVersionNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed} />
                                    </span> */}
                        </div>
                        {!this.state.collapsed /*  && !this.props.isCollapseAll */ ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {/* this.state.item.majorTopicsSynonyms &&
                                    <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                                 */}
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.authors !== undefined &&
                                    this.state.item.authors.length > 0 ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Authors:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {getVarType(this.state.item.authors) !== "Array"
                                                        ? this.state.item.authors
                                                        : GenerateArrayStrings(this.state.item.authors)}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    this.state.item.owner && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Authors:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.owner}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )
                                )}
                                {modifiedDate &&
                                    this.state.item.modifiedDate !== undefined &&
                                    this.state.item.modifiedDate.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Modified Date:&nbsp;</label>
                                                <b>
                                                    <label>{modifiedDate}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.docType !== undefined &&
                                    this.state.item.docType.length > 0 &&
                                    this.state.item.domain !== "SFM" && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Document Type:&nbsp;</label>
                                                {this.state.item.gcsrcontent ? (
                                                    <b>
                                                        <label>{this.state.item.docType} - GCSR</label>
                                                    </b>
                                                ) : (
                                                    <b>
                                                        <label>{this.state.item.docType} - GCSR</label>
                                                    </b>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.presentationType && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Presentation Type:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.presentationType}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.countries !== undefined &&
                                    this.state.item.countries.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Country:&nbsp;</label>
                                                {this.state.item.countries.includes(
                                                    "unitedStates"
                                                ) ? (
                                                    <b>
                                                        <label>United States</label>
                                                    </b>
                                                ) : (
                                                    <b>
                                                        <label>
                                                            {GenerateArrayStrings(
                                                                this.state.item.countries
                                                            )}
                                                        </label>
                                                    </b>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.languages ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.product !== undefined &&
                                    this.state.item.product.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Products:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(this.state.item.product)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.documentNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Number:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.documentNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.majorVersionNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Major Version:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.majorVersionNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source:&nbsp;</label>
                                        <b>
                                            <label>iMedical Knowledge Vault</label>
                                        </b>
                                    </div>
                                </div>
                                {this.state.item.status && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.status}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                );
            }
            else if (this.state.item.domain === "SRAP") {
                let url;
                if (this.state.item.fileExtension !== "pdf") {
                    url = `/api/dcs-vv/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}&fileName=${encodeURIComponent(this.state.item.docTitle.toString().replace(/\n/g, " "))}`;
                } else {
                    url = `/api/dcs-vv/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}&fileName=${encodeURIComponent(this.state.item.docTitle.toString().replace(/\n/g, " "))}`;
                }
                if (this.state.item.status === "Approved Needs Review") {
                    url = url + `&docState=anr`;
                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span
                                    style={{
                                        color: "#0074B0",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {this.state.documentIndex}.{" "}
                                </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" rel="noopener noreferrer" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-version={this.state.item.majorVersionNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed} />
                                    </span> */}
                        </div>
                        {!this.state.collapsed /*  && !this.props.isCollapseAll */ ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {/* this.state.item.majorTopicsSynonyms &&
                                    <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                                 */}
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.authors !== undefined &&
                                    this.state.item.authors.length > 0 ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Authors:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {getVarType(this.state.item.authors) !== "Array"
                                                        ? this.state.item.authors
                                                        : GenerateArrayStrings(this.state.item.authors)}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    this.state.item.owner && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Authors:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.owner}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )
                                )}
                                {modifiedDate &&
                                    this.state.item.modifiedDate !== undefined &&
                                    this.state.item.modifiedDate.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Modified Date:&nbsp;</label>
                                                <b>
                                                    <label>{modifiedDate}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {/* {this.state.item.docType !== undefined &&
                          this.state.item.docType.length > 0 &&
                          this.state.item.domain !== "SFM" && (
                            <div className="PHM-MetaDataProperty">
                              <div style={{ display: "block" }}>
                                <label>Document Type:&nbsp;</label>
                                {this.state.item.gcsrcontent ? (
                                  <b>
                                    <label>{this.state.item.docType} - GCSR</label>
                                  </b>
                                ) : (
                                  <b>
                                    <label>{this.state.item.docType}</label>
                                  </b>
                                )}
                              </div>
                            </div>
                          )} */}
                                {this.state.item.presentationType && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Presentation Type:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.presentationType}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.countries !== undefined &&
                                    this.state.item.countries.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Country:&nbsp;</label>
                                                {this.state.item.countries.includes(
                                                    "unitedStates"
                                                ) ? (
                                                    <b>
                                                        <label>United States</label>
                                                    </b>
                                                ) : (
                                                    <b>
                                                        <label>
                                                            {GenerateArrayStrings(
                                                                this.state.item.countries
                                                            )}
                                                        </label>
                                                    </b>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.languages ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.product !== undefined &&
                                    this.state.item.product.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Products:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(this.state.item.product)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.documentNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Number:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.documentNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.majorVersionNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Major Version:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.majorVersionNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source:&nbsp;</label>
                                        <b>
                                            <label>iMedical Knowledge Vault</label>
                                        </b>
                                    </div>
                                </div>
                                {this.state.item.status && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.status}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                );
            }
            else if (this.state.item.domain === "SR" || this.state.item.domain === "CMIR_LATAM") {
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                let url;
                if (this.state.item.fileExtension !== 'pdf') {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}&fileName=${this.state.item.docTitle}`
                }
                else {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
                }
                if (this.state.item.a_status === "Approved Needs Review") {
                    url = url + `&docState=anr`
                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" rel="noopener noreferrer" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-version={this.state.item.majorVersionNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                                <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed} />
                        </span> */}
                        </div>
                        {(!this.state.collapsed/*  && !this.props.isCollapseAll */) ?
                            <div id={`docID-${this.state.documentIndex}`} className="citation-results-meta-property-wrapper">
                                {/* this.state.item.majorTopicsSynonyms &&
                        <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                     */}
                                {this.props.itemTeaser !== undefined && this.props.itemTeaser.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <div>{this.convertToHtml(this.generateDocumentTeaser(this.props.itemTeaser))}<br /></div>
                                        </div>
                                    </div>
                                }
                                {this.state.item.authors !== undefined && this.state.item.authors.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Authors:&nbsp;</label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.authors)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {modifiedDate &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Modified Date:&nbsp;</label>
                                            <b>
                                                <label>{modifiedDate}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.docType !== undefined && this.state.item.docType.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Type:&nbsp;</label>
                                            {this.state.item.gcsrcontent ?
                                                <b>
                                                    <label>{this.state.item.docType} - GCSR</label>
                                                </b>
                                                :
                                                <b>
                                                    <label>{this.state.item.docType}</label>
                                                </b>}
                                        </div>
                                    </div>
                                }
                                {this.state.item.presentationType &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Presentation Type:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.presentationType}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.countries !== undefined && this.state.item.countries.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Country:&nbsp;</label>
                                            {this.state.item.countries.includes('unitedStates') ?
                                                <b>
                                                    <label>United States</label>
                                                </b>
                                                :
                                                <b>
                                                    <label>{this.state.item.countries}</label>
                                                </b>
                                            }
                                        </div>
                                    </div>
                                }
                                {this.state.item.languages ?
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div> :
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.product !== undefined && this.state.item.product.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Products:&nbsp;</label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.product)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.documentNumber &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Number:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.documentNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.majorVersionNumber &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Major Version:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.majorVersionNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source:&nbsp;</label>
                                        <b>
                                            <label>iMedical Knowledge Vault</label>
                                        </b>
                                    </div>
                                </div>
                                {this.state.item.status &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.status}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.targetAudience &&
                                    this.state.item.targetAudience.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Target Audience:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.targetAudience.join('; ')}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                            </div> : null}
                    </div>
                )
            }
            else if (this.state.item.domain === "iMR" ||
                this.state.item.domain === "iMR_LATAM" ||
                this.state.item.domain === "iMR_EMEA" ||
                this.state.item.domain === "iMR_ASPAC") {
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                let url;
                if (this.state.item.fileExtension !== 'pdf') {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}&fileName=${this.state.item.docTitle}`
                }
                else {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
                }
                if (this.state.item.a_status === "Approved Needs Review") {
                    url = url + `&docState=anr`
                }
                let products = "";
                if (this.state.item.product) {
                    try {
                        products = this.state.item.product.map(x => this.titleCase(x.split('_')[0])).filter(this.onlyUnique).join('; ')
                    } catch (error) {
                        console.log("error", error);
                    }

                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" rel="noopener noreferrer" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed}/>
                        </span> */}
                        </div>
                        {!this.state.collapsed ?
                            <div id={`docID-${this.state.documentIndex}`} className="citation-results-meta-property-wrapper">
                                {/* this.state.item.majorTopicsSynonyms &&
                        <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                     */}
                                {this.props.itemTeaser !== undefined && this.props.itemTeaser.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <div>{this.convertToHtml(this.generateDocumentTeaser(this.props.itemTeaser))}<br /></div>
                                        </div>
                                    </div>
                                }
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Usage Guidelines:&nbsp;</label>
                                        <b>
                                            <label>Before external use, you MUST ensure the content is compliant with your local review processes (i.e. medical, legal, compliance, privacy, and regulatory)</label>
                                        </b>
                                    </div>
                                </div>

                                {this.state.item.docTitle &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Title:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.docTitle}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.owner &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Owner:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.owner}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.product !== undefined && this.state.item.product.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Products:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {products}
                                                    {/* {GenerateArrayStrings(this.state.item.product)} */}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.subProduct &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Sub Product:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.subProduct}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.documentNumber &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Number:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.documentNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.majorVersionNumber &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Version:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.majorVersionNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.businessUnit &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Business Unit:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.businessUnit}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.lineOfBusiness &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Line of Business:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.lineOfBusiness}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.approvedDateString &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Approved for Distribution Date:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.approvedDateString}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.expirationDateString &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Expiration Date:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.expirationDateString}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.lastModifiedBy &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Modified by:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.lastModifiedBy}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.type &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Type:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.type}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.subType &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Subtype:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.subType}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.classification &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Classification:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.classification}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.lifecycle &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Lifecycle:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.lifecycle}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.pTargetAudience &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Primary Target Audience:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.pTargetAudience}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.audience &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Audience:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.audience}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.distributedBy &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Distributed by:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.distributedBy}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Language:&nbsp;</label>
                                        <b>
                                            <label>English</label>
                                        </b>
                                    </div>
                                </div>
                                {this.state.item.status &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.status}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source:&nbsp;</label>
                                        <b>
                                            <label>iMedical Review</label>
                                        </b>
                                    </div>
                                </div>
                                {(this.state.item.domain !== "iMR_LATAM" && this.state.item.domain !== "iMR_EMEA" && this.state.item.domain !== "iMR_ASPAC") && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Related Pieces:&nbsp;</label>
                                            <b>
                                                <label>
                                                    There may be pieces related to the Copy Review
                                                    Piece. To access, you must go to iMR directly.
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {(this.state.item.domain === "iMR_LATAM" || this.state.item.domain === "iMR_EMEA" || this.state.item.domain === "iMR_ASPAC") && (
                                    this.state.item.audience && this.state.item.domain === "iMR_LATAM" ? (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Presentation Type:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.audience.split(';').join(', ')}</label>
                                                </b>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Presentation Type:&nbsp;</label>
                                                <b>
                                                    {this.state.item.domain === "iMR_EMEA" && <label>Reactive</label>}
                                                    {this.state.item.domain === "iMR_ASPAC" && <label>Proactive</label>}
                                                </b>
                                            </div>
                                        </div>
                                    )
                                )}
                                {(this.state.item.domain === "iMR_LATAM" || this.state.item.domain === "iMR_EMEA" || this.state.item.domain === "iMR_ASPAC") ? (
                                    this.state.item.countries !== undefined &&
                                    this.state.item.countries.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Country:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(this.state.item.countries)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Country:&nbsp;</label>
                                            <b>
                                                <label>United States</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.isFromDownloadHtml && this.state.item.docItemDescription &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <DocumentDescriptor docItemDescription={this.state.item.docItemDescription} />
                                        </div>
                                    </div>
                                }
                                {!this.state.isFromDownloadHtml && this.state.item.docItemDescription &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <b><label>Document Item Description: </label></b>
                                            <div style={{ paddingLeft: "20px" }}>
                                                {this.textFormatter(this.state.item.docItemDescription)}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div> : null}
                    </div>
                )
            }
            else if (this.state.item.domain === "CTGov") {
                //let nctId = this.state.item.protocolIDs.find(elm => elm.startsWith('NCT'))
                // let url = `https://clinicaltrials.gov/ct2/show/${this.state.item.docTitle}`;
                let url = `https://clinicaltrials.gov/ct2/show/${this.state.item.docID}`;
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" href={url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed}/>
                        </span> */}
                        </div>
                        {!this.state.collapsed ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {/* this.state.item.majorTopicsSynonyms &&
                              <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                           */}
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {/* this.state.item.detailDescription && 
                          <div className="result-Description">
                              
                                  <div style={{display:"-webkit-box",WebkitLineClamp:"2",WebkitBoxOrient:"vertical",overflow:"hidden",width:"120%"}}>{this.state.item.detailDescription}</div>
                              
                          </div> */}
                                {this.state.item.docType !== undefined &&
                                    this.state.item.docType.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Document Type:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.docType}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.lastUpdated && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Last Updated:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {moment(this.state.item.lastUpdated).format("D-MMM-YYYY")}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.lastVerified && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Last Verified:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.lastVerified}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.leadSponsor && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Sponsor:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.leadSponsor}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.protocolIDs !== undefined &&
                                    this.state.item.protocolIDs.length > 0 ? (
                                    <div className="PHM-MetaDataProperty">
                                        <img
                                            src={infoIcn}
                                            title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab."
                                            className="citation-info-icon-wrapper"
                                            alt="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab."
                                        />
                                        <div style={{ display: "block" }}>
                                            <label>Protocol ID:&nbsp;</label>
                                            <b>
                                                <label>
                                                    <a
                                                        href="javascript:void(0)"
                                                        title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab"
                                                        onClick={(evt) =>
                                                            this.handleProtocolID(
                                                                this.state.item.protocolIDs
                                                            )
                                                        }
                                                    >
                                                        {GenerateArrayStrings(
                                                            ctNames.concat(this.state.item.protocolIDs)
                                                        )}
                                                        {/* {GenerateArrayStrings(this.state.item.protocolIDs)} */}
                                                    </a>
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    ctNames &&
                                    ctNames.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Protocol No:&nbsp;</label>
                                                <b>
                                                    <label>{GenerateArrayStrings(ctNames)}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )
                                )}
                                {this.state.item.intervention && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Intervention:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.intervention}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.condition && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Condition:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.condition}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.phaseOfStudy !== undefined &&
                                    this.state.item.phaseOfStudy.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Phase:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(
                                                            this.state.item.phaseOfStudy
                                                        )}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.languages ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.overallStatus && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Overall Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.overallStatus}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.startDate && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Start Date:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.startDate}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.endDate && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>End Date:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.endDate}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                )
            }
            else if (this.state.item.domain === "IROs") {
                let url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
                let downloadurl = `/api/dcs-spo/download?connID=${this.state.item.connID}%26csID=${this.state.item.csID}%26docID=${this.state.item.docID}%26fileType=${this.state.item.fileExtension}%26fileName=${this.state.item.docTitle}`
                let mailUrl = window.location.origin + downloadurl
                let mailbody = `Title: ${this.state.item.docTitle}%0D%0DUrl: ${mailUrl}%0D%0DFor access to iMedical Search, please contact iMedical Search Team `
                let mailref = `mailto:${this.state.item.contactInformation}?Subject=iMedical Search Document: ${this.state.item.docTitle}&body=${mailbody}`
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" /* to={url} */ href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.domain} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                        </div>
                        {!this.state.collapsed ?
                            <div id={`docID-${this.state.documentIndex}`} className="citation-results-meta-property-wrapper">
                                {this.props.itemTeaser !== undefined && this.props.itemTeaser.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <div>{this.convertToHtml(this.generateDocumentTeaser(this.props.itemTeaser))}<br /></div>
                                        </div>
                                    </div>
                                }
                                {this.state.item.subCategoryTA &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Category: </label>
                                            <b>
                                                <label>{this.state.item.subCategoryTA}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.product !== undefined && this.state.item.product.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Product: </label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.product)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.genericProduct &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Generic Product: </label>
                                            <b>
                                                <label>{this.state.item.genericProduct}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.publishedDate &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Published Date: </label>
                                            <b>
                                                <label>{moment(this.state.item.publishedDate).format("D-MMM-YYYY")} </label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.docType &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Type: </label>
                                            <b>
                                                <label>{this.state.item.docType}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.contactInformation &&
                                    <div className="PHM-MetaDataProperty">
                                        <img src={infoIcn} title="Click the name in this field to e-mail a question or comment about this document to iMedical Insight team." alt="Click the name in this field to e-mail a question or comment about this document to iMedical Insight team." className="citation-info-icon-wrapper" />
                                        <div style={{ display: "block" }}>
                                            <label>Contact: </label>
                                            <b>
                                                <label><a href={mailref}>iMedical Insights Team</a></label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Status: </label>
                                        <b>
                                            <label>Published</label>
                                        </b>
                                    </div>
                                </div>
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source: </label>
                                        <b>
                                            <label>Integrated Read-Outs (iMedical Insights)</label>
                                        </b>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                )
            }
            else if (this.state.item.domain === "MIAs") {
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                // let url = `${this.state.item.downloadUrl}&fileName=${this.state.item.docTitle}`
                // let downloadurl = `/api/dcs-spo/download?connID=${this.state.item.connID}%26csID=${this.state.item.csID}%26docID=${this.state.item.docID}%26fileType=${this.state.item.fileExtension}%26fileName=${this.state.item.docTitle}`
                // let downloadurl = `${this.state.downloadUrl.replaceAll('&','%26')}%26fileName=${this.state.item.docTitle}`
                // let mailUrl = window.location.origin + downloadurl
                // let mailbody = `Title: ${this.state.item.docTitle}%0D%0DUrl: ${mailUrl}%0D%0DFor access to iMedical Search, please contact iMedical Search Team `
                // let mailref = `mailto:${this.state.item.contactEmail}?Subject=iMedical Search Document: ${this.state.item.docTitle}&body=${mailbody}`
                let url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.domain} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed} />
                        </span> */}
                        </div>
                        {/* {this.state.collapsed === false? */}
                        {!this.state.collapsed ?
                            <div id={`docID-${this.state.documentIndex}`} className="citation-results-meta-property-wrapper">
                                {/* this.state.item.majorTopicsSynonyms &&
                                <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                             */}
                                {this.props.itemTeaser !== undefined && this.props.itemTeaser.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <div>{this.convertToHtml(this.generateDocumentTeaser(this.props.itemTeaser))}<br /></div>
                                        </div>
                                    </div>
                                }
                                {this.state.item.authors !== undefined && this.state.item.authors.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Authors:&nbsp;</label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.authors)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.product !== undefined && this.state.item.product.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Product:&nbsp;</label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.product)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.congressName !== undefined && this.state.item.congressName.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Congress Name:&nbsp;</label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.congressName)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {(this.state.item.miaDate !== undefined && this.state.item.miaDate.length > 0) &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Date:&nbsp;</label>
                                            <b>
                                                <label>{moment(this.state.item.miaDate).format("D-MMM-YYYY")}</label>
                                                {/* <label>{modifiedDate}</label> */}
                                            </b>
                                        </div>
                                    </div>
                                }
                                {/* {this.state.item.maintValidUntil && */}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Source:&nbsp;</label>
                                        <b>
                                            <label>Global Medical Affairs</label>
                                        </b>
                                    </div>
                                </div>
                                {/* } */}
                                {this.state.item.docType && this.state.item.docType !== undefined && this.state.item.docType.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Type:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.docType}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {/* {this.state.item.description && */}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }} className="wrapDescription">
                                        <label>Publication Status:&nbsp;</label>
                                        <b>
                                            <label>Published</label>
                                        </b>
                                    </div>
                                </div>
                                {/* } */}
                                {this.state.item.languages !== undefined && this.state.item.languages.length > 0 ?
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div> :
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.csName &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Content Source:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.csName}</label>
                                                {/* <label>Medical Impact Assessments</label> */}
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.status &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.status}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                            </div> : null}
                    </div>

                )
            } else if (this.state.item.domain === "PERC") {
                let url = `${this.state.item.downloadUrl}`;
                let mailref = `mailto:${"lshea4@its.jnj.com"}`;
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span
                                    style={{
                                        color: "#0074B0",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {this.state.documentIndex}.{" "}
                                </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.domain} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                        </div>
                        {!this.state.collapsed ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.owner !== undefined &&
                                    this.state.item.owner.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Authors:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        <a href={mailref}>
                                                            {GenerateArrayStrings([
                                                                "Lisa Shea",
                                                                "on behalf of JSA Patient Engagement",
                                                            ])}
                                                        </a>
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.ta !== undefined &&
                                    this.state.item.ta.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Therapeutic Area:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.ta}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.createdDate !== undefined &&
                                    this.state.item.createdDate.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Date:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {moment(this.state.item.createdDate).format("D-MMM-YYYY")}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.csName !== undefined &&
                                    this.state.item.csName.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Content Source:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.csName}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Document Type:&nbsp;</label>
                                        <b>
                                            <label>Patient Engagement Final Reports</label>
                                        </b>
                                    </div>
                                </div>
                                {this.state.item.type !== undefined &&
                                    this.state.item.type.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Document SubType:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.docType}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                <div className="PHM-MetaDataProperty">
                                    <div
                                        style={{ display: "block" }}
                                        className="wrapDescription"
                                    >
                                        <label>Status:&nbsp;</label>
                                        <b>
                                            <label>Approved Effective</label>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            } else if (this.state.item.domain === "2WIRE") {
                let url = `${this.state.item.downloadUrl}`;
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span
                                    style={{
                                        color: "#0074B0",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {this.state.documentIndex}.{" "}
                                </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.domain} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                        </div>
                        {!this.state.collapsed ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.description && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Brief Summary:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.description}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.languages ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.franchise && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Franchise:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.franchise}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.product !== undefined &&
                                    this.state.item.product.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Product:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(this.state.item.product)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.year && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Date:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.year}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.docType !== undefined &&
                                    this.state.item.docType.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Document Type:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.docType}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.owner && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Owner:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {this.state.item.owner} – Global MA Immunology
                                                    Support
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source:&nbsp;</label>
                                        <b>
                                            <label>2WIRE</label>
                                        </b>
                                    </div>
                                </div>
                                <div className="PHM-MetaDataProperty">
                                    <div
                                        style={{ display: "block" }}
                                        className="wrapDescription"
                                    >
                                        <label>Status:&nbsp;</label>
                                        <b>
                                            <label>Approved Effective</label>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            } else if (this.state.item.domain === "CMIR") {
                // For Slide Decks
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                let url;
                if (this.state.item.fileExtension !== "pdf") {
                    url = `/api/dcs-vv/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}&fileName=${this.state.item.docTitle}`;
                } else {
                    url = `/api/dcs-vv/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`;
                }
                if (this.state.item.status === "Approved Needs Review") {
                    url = url + `&docState=anr`;
                }
                if (
                    this.state.item.connID !== undefined &&
                    this.state.item.csID !== undefined &&
                    this.state.item.docID !== undefined &&
                    this.state.item.fileExtension !== undefined &&
                    this.state.item.majorVersionNumber !== undefined
                ) {
                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span
                                    style={{
                                        color: "#0074B0",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {this.state.documentIndex}.{" "}
                                </span>
                                <span>
                                    <a id="docdata" href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.domain} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                                          <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed}/>
                                      </span> */}
                        </div>
                        {!this.state.collapsed ? (
                            <div
                                id={`docID-${this.state.documentIndex}`}
                                className="citation-results-meta-property-wrapper"
                            >
                                {/* this.state.item.majorTopicsSynonyms &&
                                      <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                                   */}
                                {this.props.itemTeaser !== undefined &&
                                    this.props.itemTeaser.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <div>
                                                    {this.convertToHtml(
                                                        this.generateDocumentTeaser(this.props.itemTeaser)
                                                    )}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.authors !== undefined &&
                                    this.state.item.authors.length > 0 ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Authors:&nbsp;</label>
                                            <b>
                                                <label>
                                                    {getVarType(this.state.item.authors) !== "Array"
                                                        ? this.state.item.authors
                                                        : GenerateArrayStrings(this.state.item.authors)}
                                                </label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    this.state.item.owner && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Authors:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.owner}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )
                                )}
                                {this.state.item.product !== undefined &&
                                    this.state.item.product.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Products:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(this.state.item.product)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {/* {this.state.item.congressName !== undefined &&
                          this.state.item.congressName.length > 0 && (
                            <div className="PHM-MetaDataProperty">
                              <div style={{ display: "block" }}>
                                <label>Congress Name:&nbsp;</label>
                                <b>
                                  <label>
                                    {GenerateArrayStrings(this.state.item.congressName)}
                                  </label>
                                </b>
                              </div>
                            </div>
                          )} */}
                                {this.state.item.languages ? (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language:&nbsp;</label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {this.state.item.countries !== undefined &&
                                    this.state.item.countries.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Country:&nbsp;</label>
                                                <b>
                                                    <label>
                                                        {GenerateArrayStrings(this.state.item.countries)}
                                                    </label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {/* {this.state.item.franchise && (
                          <div className="PHM-MetaDataProperty">
                            <div style={{ display: "block" }}>
                              <label>Franchise :&nbsp;</label>
                              <b>
                                <label>{this.state.item.franchise}</label>
                              </b>
                            </div>
                          </div>
                        )} */}

                                {/* {this.state.item.year && (
                          <div className="PHM-MetaDataProperty">
                            <div style={{ display: "block" }}>
                              <label>Date:&nbsp;</label>
                              <b>
                                <label>{this.state.item.year}</label>
                              </b>
                            </div>
                          </div>
                        )} */}
                                {this.state.item.documentNumber && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Number:&nbsp;</label>
                                            <b>
                                                <label>{this.state.item.documentNumber}</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                                {modifiedDate &&
                                    this.state.item.modifiedDate !== undefined &&
                                    this.state.item.modifiedDate.length > 0 && (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Last Updated:&nbsp;</label>
                                                <b>
                                                    <label>{modifiedDate}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                {this.state.item.docType !== undefined &&
                                    this.state.item.docType.length > 0 &&
                                    (
                                        <div className="PHM-MetaDataProperty">
                                            <div style={{ display: "block" }}>
                                                <label>Document Type:&nbsp;</label>
                                                <b>
                                                    <label>{this.state.item.docType}</label>
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source:&nbsp;</label>
                                        <b>
                                            <label>iMedical Knowledge Vault</label>
                                        </b>
                                    </div>
                                </div>
                                {/* {this.state.item.owner ? (
                          <div className="PHM-MetaDataProperty">
                            <div style={{ display: "block" }}>
                              <label>Owner:&nbsp;</label>
                              <b>
                                <label>
                                  {this.state.item.owner} – Global MA Immunology Support
                                </label>
                              </b>
                            </div>
                          </div>
                        ) : (
                          <div className="PHM-MetaDataProperty">
                            <div style={{ display: "block" }}>
                              <label>Owner:&nbsp;</label>
                              <b>
                                <label>Global MA Immunology Support</label>
                              </b>
                            </div>
                          </div>
                        )} */}

                                {this.state.item.status && (
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status:&nbsp;</label>
                                            <b>
                                                <label>Approved Effective</label>
                                            </b>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                );
            }
            else {
                //console.log(this.state.item)
                //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
                let url;
                if (this.state.item.fileExtension !== 'pdf') {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&docVer=${this.state.item.majorVersionNumber}&fileName=${this.state.item.docTitle}`
                }
                else {
                    url = `/DocURL?ID=${this.state.item.docID}&cs=${this.state.item.domain}&connID=${this.state.item.connID}&csID=${this.state.item.csID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
                }
                if (this.state.item.a_status === "Approved Needs Review") {
                    url = url + `&docState=anr`
                }
                return (
                    <div>
                        <div className="results-title-action-outer-wrapper">
                            <span className="results-div-docTitle-wrapper">
                                <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                                <span>
                                    <a id="docdata" /* to={url} */ href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.documentNumber} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate} data-orderedby={orderedBy} data-orderedfor={orderedFor}>
                                        {this.state.item.docTitle}
                                    </a>
                                </span>
                            </span>
                            {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed}/>
                        </span> */}
                        </div>
                        {!this.state.collapsed ?
                            <div id={`docID-${this.state.documentIndex}`} className="citation-results-meta-property-wrapper">
                                {/* this.state.item.majorTopicsSynonyms &&
                        <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                     */}
                                {this.props.itemTeaser !== undefined && this.props.itemTeaser.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <div>{this.convertToHtml(this.generateDocumentTeaser(this.props.itemTeaser))}<br /></div>
                                        </div>
                                    </div>
                                }
                                {this.state.item.congressName !== undefined && this.state.item.congressName.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Congress Name: </label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.congressName)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.languages ?
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language: </label>
                                            <b>
                                                <label>{this.state.item.languages}</label>
                                            </b>
                                        </div>
                                    </div> :
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Language: </label>
                                            <b>
                                                <label>English</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.franchise &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Franchise : </label>
                                            <b>
                                                <label>{this.state.item.franchise}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.product !== undefined && this.state.item.product.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Product: </label>
                                            <b>
                                                <label>{GenerateArrayStrings(this.state.item.product)}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.year &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Date: </label>
                                            <b>
                                                <label>{this.state.item.year}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.docType !== undefined && this.state.item.docType.length > 0 &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Document Type: </label>
                                            <b>
                                                <label>{this.state.item.docType}</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                                {this.state.item.owner ?
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Owner: </label>
                                            <b>
                                                <label>{this.state.item.owner}  – Global MA Immunology Support</label>
                                            </b>
                                        </div>
                                    </div> :
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Owner: </label>
                                            <b>
                                                <label>Global MA Immunology Support</label>
                                            </b>
                                        </div>
                                    </div>
                                }

                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Content Source: </label>
                                        <b>
                                            <label>2WIRE</label>
                                        </b>
                                    </div>
                                </div>

                                {this.state.item.status &&
                                    <div className="PHM-MetaDataProperty">
                                        <div style={{ display: "block" }}>
                                            <label>Status: </label>
                                            <b>
                                                <label>Approved Effective</label>
                                            </b>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                )
            }
        }
        else {
            let url = `/DocURLPubMed?sid=PMS&pmid=${this.state.item.PMID}`
            return (
                <div>
                    <div className="results-title-action-outer-wrapper">
                        <span className="results-div-docTitle-wrapper">
                            <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                            <span>
                                <a id="docdata" href={window.location.origin + url} target="_blank" rel="noopener noreferrer" onClick={this.indexPubMedData} data-title={this.state.item.title} data-docurl={url}>
                                    {this.state.item.title}
                                </a>
                            </span>
                        </span>
                        {/* <span className="results-div-action-bar-wrapper" style={{width:this.props.customWidth > 600 &&  this.props.customWidth < 1000 ? "2%" : (this.props.customWidth <=600 && "4%") }}>
                            <ActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.state.collapsed}/>
                        </span> */}
                    </div>
                    {!this.state.collapsed ?
                        <div id={`docID-${this.state.documentIndex}`} className="citation-results-meta-property-wrapper">
                            {/* this.state.item.majorTopicsSynonyms &&
                        <RelevanceHighlight item={this.state.item} searchKeyword={this.props.searchKeyword}/>
                     */}

                            {(this.state.item.authors && this.state.item.authors.length > 0) &&

                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Authors: </label>
                                        <b>
                                            <label>{getVarType(this.state.item.authors) !== 'Array' ? this.state.item.authors : GenerateArrayStrings(this.state.item.authors)}</label>
                                        </b>
                                    </div>
                                </div>
                            }
                            {this.state.item.publicationDate &&
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Date: </label>
                                        <b>
                                            <label>{this.state.item.publicationDate}</label>
                                        </b>
                                    </div>
                                </div>
                            }
                            {this.state.item.source &&
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Source: </label>
                                        <b>
                                            <label>{this.state.item.source.name}.{this.state.item.source.year};{this.state.item.source.location}</label>
                                        </b>
                                    </div>
                                </div>
                            }
                            {this.state.item.PMID &&
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>PubMed ID: </label>
                                        <b>
                                            <label><a href={`https://pubmed.ncbi.nlm.nih.gov/${this.state.item.PMID}/?otool=pajanslib`} title="Click this field to view the abstract in PubMed. This will appear in a new browser tab" rel="noopener noreferrer">{this.state.item.PMID}</a></label>
                                        </b>
                                    </div>
                                </div>
                            }
                            {this.state.item.doi &&
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>DOI: </label>
                                        <b>
                                            <label><a href={`https://doi.org/${this.state.item.doi}`} title="Click this field to view the abstract in doi.org. This will appear in a new browser tab" rel="noopener noreferrer">{this.state.item.doi}</a></label>
                                        </b>
                                    </div>
                                </div>
                            }
                            {this.state.item.language && this.state.item.language !== 'eng' ?
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Language: </label>
                                        <b>
                                            <label>{this.state.item.languages}</label>
                                        </b>
                                    </div>
                                </div> :
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Language: </label>
                                        <b>
                                            <label>English</label>
                                        </b>
                                    </div>
                                </div>
                            }
                            {this.state.item.franchise &&
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <label>Franchise : </label>
                                        <b>
                                            <label>{this.state.item.franchise}</label>
                                        </b>
                                    </div>
                                </div>
                            }
                            <div className="PHM-MetaDataProperty">
                                <div style={{ display: "block" }}>
                                    <label>Content Source: </label>
                                    <b>
                                        <label>PubMed</label>
                                    </b>
                                </div>
                            </div>
                            {!this.state.isFromDownloadHtml && ((this.state.item.meshHeading)
                                || (this.state.item.publicationType)
                                || (this.state.item.chemicals)
                            ) &&
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <DocumentDescriptor meshHeading={this.state.item.meshHeading} publicationType={this.state.item.publicationType} substances={this.state.item.chemicals} isPubMedHtml={this.state.isPubMedHtml} />
                                    </div>
                                </div>
                            }


                            {this.state.isFromDownloadHtml && ((this.state.item.meshHeading)
                                || (this.state.item.publicationType)
                                || (this.state.item.chemicals)
                            ) &&
                                <div className="PHM-MetaDataProperty">
                                    <div style={{ display: "block" }}>
                                        <b><label>PubMed Descriptors: </label></b>
                                        {(this.state.item.publicationType &&
                                            (getVarType(this.state.item.publicationType) !== 'Array' ||
                                                (getVarType(this.state.item.publicationType) === 'Array' &&
                                                    this.state.item.publicationType.length > 0))) &&
                                            <div className="PHM-MetaDataProperty-SubType">
                                                <div style={{ display: "block" }}>
                                                    <label>Publication type: </label>
                                                    <b>
                                                        <label>{getVarType(this.state.item.publicationType) !== 'Array' ? this.state.item.publicationType : GenerateArrayStrings(this.state.item.publicationType)}</label>
                                                    </b>
                                                </div>
                                            </div>
                                        }
                                        {(this.state.item.meshHeading &&
                                            (getVarType(this.state.item.meshHeading) !== 'Array' ||
                                                (getVarType(this.state.item.meshHeading) === 'Array' &&
                                                    this.state.item.meshHeading.length > 0))) &&
                                            <div className="PHM-MetaDataProperty-SubType">
                                                <div style={{ display: "block" }}>
                                                    <label>MeSH terms: </label>
                                                    <b>
                                                        <label>{getVarType(this.state.item.meshHeading) !== 'Array' ? this.state.item.meshHeading : GenerateArrayStrings(this.state.item.meshHeading)}</label>
                                                    </b>
                                                </div>
                                            </div>
                                        }
                                        {(this.state.item.chemicals &&
                                            (getVarType(this.state.item.chemicals) !== 'Array' ||
                                                (getVarType(this.state.item.chemicals) === 'Array' &&
                                                    this.state.item.chemicals.length > 0))) &&
                                            <div className="PHM-MetaDataProperty-SubType">
                                                <div style={{ display: "block" }}>
                                                    <label>Substances: </label>
                                                    <b>
                                                        <label>{getVarType(this.state.item.chemicals) !== 'Array' ? this.state.item.chemicals : GenerateArrayStrings(this.state.item.chemicals)}</label>
                                                    </b>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            }
                        </div> : null}
                </div>
            )
        }
    }
}
export default HtmlDocumentResults;