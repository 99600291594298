import { Modal } from "react-bootstrap";
import { useState } from "react";
import { popCancelBttn } from "../../assets";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import ZooButton from "../GenAIAnswering2.0/ZooButton"
import '../GenAIAnswering2.0/GenAIAuthoringSidebar.css';

export default function ExpandContentPopUpModal(props) {
    const {
        content,
        showModal,
        handleSaveContent,
        placeholder
    } = props
    const [showPop, setShow] = useState(showModal)
    const [txtValue, setTxtValue] = useState(content)
    const handleTextarea = (e) => {
        setTxtValue(e.target.value)
    }
    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <div className="tools">
                <div className ="tools-container">
                 <ZooButton
                  text="Zoom_In"
                  customColor="#0074b0"
                  sReset = {false}
                  action={() => zoomIn()}
                />
                <ZooButton
                  text="Zoom_Out"
                  customColor="#0074b0"
                  sReset = {false}
                  action={() => zoomOut()}
                />
                <ZooButton
                  text="Reset"
                  customColor="#0074b0"
                  isReset = {true}
                  action={() => resetTransform()}
                />
                </div>
            </div>
          );
     }
    return (
        <Modal
            size="lg"
            show={showPop}
            keyboard={false}
            dialogClassName="popTimeOut expandContentPopUp"
            className="z-index-alertMessage"
            backdropClassName="z-index-alertMessage-backdrop"
        >
            {(txtValue.startsWith('blob:http://') || txtValue.startsWith('blob:https://') )? <>
                <div style={{ padding: "25px" }}>
                <div className="expandContentCancel">
                    <img src={popCancelBttn} alt="" onClick={(e) => { setShow(false); handleSaveContent(txtValue) }} />
                </div>
            <div className="gen-ai-image-container-1">
                <div className="gen-ai-image-container-2">
                    <TransformWrapper
                        defaultPositionX={1}
                        defaultPositionY={1}
                        defaultScale={1}
                    ><TransformComponent>
                            <img className="gen-ai-authoring-img" src={`${txtValue}`} style={{ width: '753px', height: '520px' }} />
                        </TransformComponent>
                        <><Controls /></>
                    </TransformWrapper>
                </div></div></div>
            </> : <div style={{ padding: "25px" }}>
                <div className="expandContentCancel">
                    <img src={popCancelBttn} alt="" onClick={(e) => { setShow(false); handleSaveContent(txtValue) }} />
                </div>
                <textarea value={txtValue} placeholder={placeholder ? placeholder : ""} className="contentTextarea" onChange={(e) => handleTextarea(e)} />
            </div>}
        </Modal>
    )
}