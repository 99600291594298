import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component, useEffect, useState } from "react";
import Button from "../Button";
import './CreateFolderPopUp.css';
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import ApiServices from "../../utils/apiServices";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import axios from "axios";

const RenameLexi = (props) => {
    const selectedId = props.selectedID;
    
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageShowModal,setAlertMessageShowModal] = useState(false);
    const [docName, setDocName] = useState("");

    const handleSave = async () => {
        if(props.selectedId) {
            if (handleValidation()){
                let res = await axios.get(`https://stage-ims.jnj.com/api/db/runquery?dbquery=update "iMS".phm_ims_ttslexicon set name = '${docName}' where id = ${props.selectedId}`)
            if (res) {
                setAlertMessage("Lexicon File Name saved successfully");
                setAlertMessageShowModal(true)
            }
        }
        }
    }
    const handleValidation = () =>{
        return true
    }
    const closeAlertMessage = () => {
        setAlertMessage("");
        setAlertMessageShowModal(false);

    }
    return (<>
        <Modal
            show={props.showModal}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            scrollable={false}
        >
            <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                <div className="popup-cancel-btn-wrapper">
                    <img
                        src={popCancelBttn}
                        alt=""
                        onClick={e => {
                            props.handleClose()
                        }}
                    />
                </div>
                <div>
                    <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                        <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                            <Modal.Title>
                                <div>
                                    Rename File
                                </div>
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
                    <input type= 'text' placeholder="Enter the name" value={docName} onChange={(e)=>setDocName(e.target.value)}/>
                        <div className="reading-list-button-wrapper1">
                        <div style={{ width:"20%", display:"flex",justifyContent:"space-between"}}>
                            <Button text="Save" customColor='#0074B0' action={() => {
                                handleSave()
                            }} />
                            <Button text="Cancel" customColor="#0074B0" action={() => {
                                props.handleClose()
                            }} />
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </div>
            {alertMessageShowModal && <AlertMessagePopUpModal color='#00c12c' content={alertMessage} 
            showModal={alertMessageShowModal} 
            handleClose={() => 
                {closeAlertMessage();
                props.handleClose();
                //props.setRefresh(true);
                //window.location.reload();
            }} />}
        </Modal>
    </>)
}

export default RenameLexi;