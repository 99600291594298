import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import "./index.css"
import { imsLoader, crossIcon } from "../../assets";
import { clone, uniq } from 'underscore'
import { LocationOrigin, getCurrentDate } from '../../utils/utilityServices';

let limitString = 250;
const GraphInProcessing = 'GraphInProcessing';
const GraphSucceeded = 'GraphSucceeded';
const GraphUnsucceeded = 'GraphUnsucceeded';

class GenAIGraphViewMilestoneItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            milestoneName: props.milestoneName,
            graph: props.graph,
            question: props.data,
            currentIndex: props.currentIndex,
            totalItems: props.totalItems,
            isShowMore: false,
            graphThreadId: props.threadId,
            requirements: props.requirements,
            graphTypes: props.graphTypes,
        }
    }
    handleAddMilestoneClose = () => {
        this.props.handleClose(false)
    }

    handleClickOnRevertToMilestone = async (e) => {
        let { graphTypes, requirements, graphThreadId, graph, question } = this.state;
        let _userGraphInfo = {
            userGraphHTML: graph,
            graphAPIResponseThreadId: clone(graphThreadId),
            graphAPIStatus: GraphSucceeded,
            lastCreatedDatehour: getCurrentDate()
        }
        await this.props.handleClickOnRevertToMilestone(_userGraphInfo, clone(graphTypes), clone(requirements), clone(question))
        _userGraphInfo.graphType = clone(graphTypes);
        _userGraphInfo.requirement = clone(requirements);
        _userGraphInfo.question = clone(question);
        this.props.indexReverttoMilestoneData(_userGraphInfo, question);
    }
    snippetLongString = (snippet) => {
        if (snippet.length > limitString) {
            let text = "";
            let spanIndex = snippet.indexOf('<span class=');
            if (spanIndex !== -1) {
                text = snippet.substring(spanIndex, (spanIndex + limitString))//cuts to spanIndex+150
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            } else {
                text = snippet.substring(0, limitString)//cuts to 70
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            }
            //text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }


    render() {
        let { milestoneName, currentIndex, question, isShowMore, graph, totalItems } = this.state;
        let text = "";
        if (!isShowMore) {
            text = this.snippetLongString(question);
        }
        else {
            text = question;
        }
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-graph-view-milestone-container-bottom">
                <div className="gen-ai-graph-view-milestone-container-bottom-header">
                    <div className="gen-ai-graph-view-milestone-container-bottom-right">
                        <div className="gen-ai-graph-view-milestone-container-bottom-right-top" style={currentIndex === 0 ? { border: "none" } : {}} ></div>
                        <div className="gen-ai-graph-view-milestone-container-bottom-right-controle">
                            <div className="gen-ai-graph-view-milestone-container-bottom-right-controle-label">
                                <svg viewBox="0 0 24 24" height="24px" width="24px" data-prefix="far" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "10px" }} >
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path d="M16.577 8.52566L6.65811 5.21937C6.3578 5.11927 6.20764 5.06921 6.10382 5.14405C6 5.21888 6 5.37716 6 5.69371V13L16.577 9.47434C17.1653 9.27824 17.4594 9.18019 17.4594 9C17.4594 8.81981 17.1653 8.72176 16.577 8.52566Z" fill="#222222"></path>
                                        <path d="M6 13V5.69371C6 5.37716 6 5.21888 6.10382 5.14405C6.20764 5.06921 6.3578 5.11927 6.65811 5.21937L16.577 8.52566C17.1653 8.72176 17.4594 8.81981 17.4594 9C17.4594 9.18019 17.1653 9.27824 16.577 9.47434L6 13ZM6 13V18V19" stroke="#222222" stroke-width="2" stroke-linecap="round"></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="gen-ai-graph-view-milestone-container-bottom-right-controle-text">
                                {milestoneName}
                            </div>
                        </div>
                        <div className="gen-ai-graph-view-milestone-container-bottom-right-bottom" style={currentIndex + 1 === totalItems ? { border: "none" } : {}}></div>
                    </div>
                    <div className="gen-ai-graph-view-milestone-container-bottom-center" style={{ overflow: "auto" }}>
                        {(graph) &&
                            <iframe srcDoc={graph}
                                ref={ref => { this.userGraphiframe = ref }}
                                // width="100%"
                                // height="100%"
                                // onLoad={this.resizeIframe}
                                frameborder="0"
                                scrolling="auto"
                            />}
                        <div className="gen-ai-graph-view-milestone-container-bottom-question">
                            {text}
                            {question.length > limitString &&
                                <div className="gen-ai-graph-view-milestone-container-bottom-question-message-show-more-less">{isShowMore ?
                                    <span onClick={() => { this.setState({ isShowMore: false }) }}>Show Less</span> :
                                    <span onClick={() => { this.setState({ isShowMore: true }) }}>Show More</span>}</div>}
                        </div>
                    </div>
                    <div className="gen-ai-graph-view-milestone-container-bottom-left">
                        <div className="gen-ai-graph-view-milestone-container-bottom-right-controle">
                            <div className="gen-ai-graph-view-milestone-container-bottom-right-controle-revert-to-milestone" onClick={() => { this.handleClickOnRevertToMilestone() }} >
                                <svg fill="#2a498a" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path d="m4.431 12.822 13 9A1 1 0 0 0 19 21V3a1 1 0 0 0-1.569-.823l-13 9a1.003 1.003 0 0 0 0 1.645z"></path>
                                    </g>
                                </svg>
                                Revert to Milestone
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        }
    }

}
export default withRouter(GenAIGraphViewMilestoneItem);