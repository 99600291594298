import { Modal } from "react-bootstrap";
import { Component } from "react";
import { IMSLogo, imsLoader, ActionsArrowIcon } from '../../assets';
import ApiServices from "../../utils/apiServices";
// import { AccessGroups } from "../../utils/utilityServices";
import "./UpdateUserDetailsPopUp.css";
import Button from "../../components/Button";


class UpdateUserDetailsPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isProcessing: true,
            dropdownProperty: {
                isRegionsShow: false,
                isReviewStatusShow: false,
                isTrackingShow: false,
                isFunctionShow: false,
                isProductPreference: false,
                isCountriesShow: false,
                isDocumentTypeShow: false,
                isTherapeuticAreaShow: false,
                isDrugClassShow: false,
                isProductsShow: false,
                isRussianuserShow: false,
                isAccessGroupShow: false
            },
            allRegions: [],
            allCountries: [],
            allDocumentTypes: [],
            allTherapeuticAreas: [],
            allDrugClasses: [],
            allProducts: [],
            Answers: false,
            Authoring: false,
            MISEHCP: false,
            AllContent: false,
            JanssenScience: false,
            LimitedUser: false
        };
    }
    async componentDidMount() {
        const service = new ApiServices();
        let res = await service.getUserRegistrationDetailsByWwid(this.props.selectedWwid);
        if (res) {
            let firstResult = res[0];
            this.setState({
                data: firstResult,
            }, async () => {
                await this.getAllRegions();
                await this.getAllCountries();
                await this.getAllDocumentTypes();
                await this.getAllTherapeuticAreas();
                await this.getAllDrugClasses();
                await this.getAllProducts();
                this.setState({
                    isProcessing: false,
                })
            })
        } else {
            this.setState({
                isProcessing: false,
            })
        }
    }

    async getAllRegions() {
        const service = new ApiServices();
        let res = await service.getAllRegions();
        if (res) {
            this.setState({
                allRegions: res,
            })
        }
    }

    async getAllCountries() {
        const { data, allRegions } = this.state;
        let joinValue = data.regions;
        if (joinValue) {
            let selectedItems = joinValue.split(";");
            // if (selectedItems.findIndex(x => x === 'Global') === -1 || true) {
            let selectedRegionsID = allRegions.filter(x => selectedItems.find(z => z === x.region)).map(a => a.regionid);
            const service = new ApiServices();
            joinValue = selectedRegionsID.join(',')
            let res = await service.getAllCountriesByRegions(joinValue);
            if (res) {
                let joinValueCountries = data.countries;
                if (!joinValueCountries) joinValueCountries = "";
                let selectedCountries = joinValueCountries.split(";");
                selectedCountries = selectedCountries.filter(x => res.find(z => z.country === x));
                data.countries = selectedCountries.join(';');
                this.setState({
                    allCountries: res,
                    data: data,
                })
            }
            // } else {
            //     data.countries = "";
            //     this.setState({
            //         allCountries: [],
            //         data: data
            //     })
            // }
        } else {
            data.countries = "";
            this.setState({
                allCountries: [],
                data: data
            })
        }



    }

    async getAllDocumentTypes() {
        const service = new ApiServices();
        let res = await service.getAllDocumentTypes();
        if (res) {
            this.setState({
                allDocumentTypes: res,
            })
        }
    }

    async getAllTherapeuticAreas() {
        const service = new ApiServices();
        let res = await service.getAllTherapeuticAreas();
        if (res) {
            this.setState({
                allTherapeuticAreas: res,
            })
        }
    }

    async getAllDrugClasses() {
        const { data, } = this.state;
        let joinValue = data.taids;
        if (joinValue) {
            const service = new ApiServices();
            let res = await service.getAllDrugClasses(joinValue);
            if (res) {
                let joinValueDrugClasses = data.drug_classes;
                if (!joinValueDrugClasses) joinValueDrugClasses = "";
                let selectedDrugClasses = joinValueDrugClasses.split(";");

                let joinValueClassids = data.classids;
                if (!joinValueClassids) joinValueClassids = "";
                let selectedClassids = joinValueClassids.split(",");

                selectedDrugClasses = selectedDrugClasses.filter(x => res.find(z => z.classname === x));
                selectedClassids = selectedClassids.filter(x => res.find(z => z.classid === x));
                data.drug_classes = selectedDrugClasses.join(';');
                data.classids = selectedClassids.join(',');
                this.setState({
                    allDrugClasses: res,
                    data: data
                })
            }
        } else {
            data.drug_classes = "";
            data.classids = "";
            this.setState({
                allDrugClasses: [],
                data: data
            })
        }
    }

    async getAllProducts() {
        const { data, } = this.state;
        let joinValue = data.classids;
        if (!joinValue) joinValue = "";
        let selectedItems = joinValue.split(",");
        joinValue = selectedItems.join("','")
        const service = new ApiServices();
        let res = await service.getAllProductsByClassids(joinValue);
        if (res) {
            let Answers = false, Authoring = false, MISEHCP = false, AllContent = false, JanssenScience = false, LimitedUser = false;
            let joinValueProducts = data.products;
            if (!joinValueProducts) joinValueProducts = "";
            let selectedProducts = joinValueProducts.split(";");

            let joinValueProductids = data.productids;
            if (!joinValueProductids) joinValueProductids = "";
            let selectedProductids = joinValueProductids.split(",");

            selectedProducts = selectedProducts.filter(x => res.find(z => z.productname === x));
            selectedProductids = selectedProductids.filter(x => res.find(z => z.productid === x));
            data.products = selectedProducts.join(';');
            data.productids = selectedProductids.join(',');
            if (data.accessgroup) {
                if (data.accessgroup.includes("Answers")) {
                    Answers = true;
                }
                if (data.accessgroup.includes("MISEHCP")) {
                    MISEHCP = true;
                }
                if (data.accessgroup.includes("AllContent")) {
                    AllContent = true;
                }
                if (data.accessgroup.includes("Authoring")) {
                    Authoring = true;
                }
                if (data.accessgroup.includes("JanssenScience")) {
                    JanssenScience = true;
                }
                if (data.accessgroup.includes("LimitedUser")) {
                    LimitedUser = true;
                }
            }
            this.setState({
                allProducts: res,
                data: data,
                Answers: Answers,
                MISEHCP: MISEHCP,
                AllContent: AllContent,
                Authoring: Authoring,
                JanssenScience: JanssenScience,
                LimitedUser: LimitedUser
            })
        }
    }

    handelDropdown(key, value) {
        const { dropdownProperty } = this.state;
        let defaultValue = {
            isRegionsShow: false,
            isReviewStatusShow: false,
            isTrackingShow: false,
            isFunctionShow: false,
            isProductPreference: false,
            isCountriesShow: false,
            isDocumentTypeShow: false,
            isTherapeuticAreaShow: false,
            isDrugClassShow: false,
            isProductsShow: false,
            isRussianuserShow: false,
            isAccessGroupShow: false
        }
        defaultValue[key] = value;

        this.setState({ dropdownProperty: defaultValue }
            , async () => {
                if ((key === 'isRegionsShow' || (dropdownProperty && dropdownProperty.isRegionsShow)) && !value) {
                    await this.getAllCountries();
                }
                if ((key === 'isTherapeuticAreaShow' || (dropdownProperty && dropdownProperty.isTherapeuticAreaShow)) && !value) {
                    await this.getAllDrugClasses();
                    await this.getAllProducts();
                }
                if ((key === 'isDrugClassShow' || (dropdownProperty && dropdownProperty.isDrugClassShow)) && !value) {
                    await this.getAllProducts();
                }
            });
    }

    handleIsChecked(key, itemValue) {
        const { data } = this.state;
        let joinValue = data[key];
        if (!joinValue) joinValue = "";
        let selectedItems = joinValue.split(";");
        return selectedItems.findIndex(x => x === itemValue) !== -1;
    }

    handleMultiSelected(checked, selectedValue, key) {
        let { data } = this.state;
        let joinValue = data[key];
        if (!joinValue) joinValue = "";
        let selectedItems = joinValue.split(";");
        if (key === 'regions') {
            if (selectedValue === 'Global' && checked) {
                let { allRegions } = this.state;
                selectedItems = allRegions.map(x => x.region);
            } else if (selectedValue === 'Global' && !checked) {
                selectedItems = [];
            } else if (checked) {
                selectedItems.push(selectedValue);
            } else {
                selectedItems = selectedItems.filter(x => x !== selectedValue && x !== 'Global');
            }
        }
        else {
            if (checked) {
                selectedItems.push(selectedValue);
            } else {
                selectedItems = selectedItems.filter(x => x !== selectedValue);
            }
        }

        data[key] = selectedItems.filter(x => x).join(";");
        this.setState({ data: data });
    }

    handleMultiSelectedWithId(checked, selectedValue, key, selectedId, keyId) {
        let { data } = this.state;
        let joinValue = data[key];
        if (!joinValue) joinValue = "";
        let selectedItems = joinValue.split(";");

        let joinIdValue = data[keyId];
        if (!joinIdValue) joinIdValue = "";
        let selectedIds = joinIdValue.split(",");

        if (checked) {
            selectedItems.push(selectedValue);
            selectedIds.push(selectedId);
        } else {
            selectedItems = selectedItems.filter(x => x !== selectedValue);
            selectedIds = selectedIds.filter(x => x !== `${selectedId}`);
        }

        data[key] = selectedItems.filter(x => x).join(";");
        data[keyId] = selectedIds.filter(x => x).join(",");
        this.setState({ data: data });
    }

    handleSingleSelected(selectedValue, key, openKey) {
        let { data } = this.state;
        data[key] = selectedValue;
        let defaultValue = {
            isRegionsShow: false,
            isReviewStatusShow: false,
            isTrackingShow: false,
            isFunctionShow: false,
            isProductPreference: false,
            isCountriesShow: false,
            isDocumentTypeShow: false,
            isTherapeuticAreaShow: false,
            isDrugClassShow: false,
            isProductsShow: false,
            isRussianuserShow: false,
            isAccessGroupShow: false
        }
        this.setState({ data: data, dropdownProperty: defaultValue });
    }

    async handleButtonUpdate() {
        const service = new ApiServices();
        let email = JSON.parse(localStorage.getItem('username')).toLowerCase();
        const { data, Answers, Authoring, MISEHCP, AllContent, JanssenScience, LimitedUser } = this.state;
        let _jnjDepartment = data.department;
        if (_jnjDepartment) {
            _jnjDepartment = _jnjDepartment.replace(/'/g, "''");
        }
        let _groupLevelAccess = "None";
        if (Answers && (MISEHCP || AllContent)) {
            _groupLevelAccess = "Answers";
            if (MISEHCP) {
                _groupLevelAccess += "MISEHCP";
            }
            if (AllContent) {
                _groupLevelAccess += "AllContent";
            }
        }
        if (JanssenScience) {
            _groupLevelAccess = _groupLevelAccess !== "None" ? `${_groupLevelAccess}AndJanssenScience` : "JanssenScience";
        }
        if (Authoring) {
            _groupLevelAccess = _groupLevelAccess !== "None" ? `${_groupLevelAccess}AndAuthoring` : "Authoring";
        }
        if (LimitedUser) {
            _groupLevelAccess = _groupLevelAccess !== "None" ? `${_groupLevelAccess}AndLimitedUser` : "LimitedUser";
        }

        var query = `call "iMS".phm_ims_updateuserpreference(${data.wwid},'${data.email.toLowerCase()}','${data.regions}','${data.countries}','${data.datatracking}','${data.profession}','${data.therapeutic_areas}','${data.drug_classes.replace(/'/g, "''")}','${data.products}','${data.contentprefernce}','${data.documenttypes}','${data.email.toLowerCase()}','${email.toLowerCase()}','${_jnjDepartment}','${data.username.replace(/'/g, "''")}','${data.status}','${data.taids}','${data.classids}','${data.productids}',${data.russianuser},'${_groupLevelAccess}')`;
        this.setState({
            isProcessing: true,
        })
        var res = await service.updateDbService(query);
        this.setState({
            isProcessing: false,
        })
        if (res) {
            this.props.handleButtonUpdate();
        }
    }
    handelAccessGroup(e) {
        let { name, checked } = e.target;
        this.setState({ [name]: checked }, () => {
            let { MISEHCP, AllContent } = this.state;
            if (name === "Answers" && checked) {
                this.setState({
                    MISEHCP: true,
                    AllContent: true,
                });
            } else if (MISEHCP === false && AllContent === false) {
                this.setState({
                    Answers: false
                });
            }
        })
    }

    renderRegions() {
        const { allRegions } = this.state;
        return (<ul className="dropdown-item-wrapper" id="dropdownRegions">
            {
                (allRegions.map((x, i) => {
                    let isCheck = this.handleIsChecked("regions", x.region)
                    return (<li key={`Regions-${i}`}>
                        <input type="checkbox" class="k-checkbox" id={`Regions-${i}`} style={{ margin: 0 }} onChange={e => this.handleMultiSelected(e.target.checked, x.region, "regions")} checked={isCheck} />
                        <label for={`Regions-${i}`} className="dropdown-item-label"  >{x.region}</label>
                    </li>)
                }))
            }
        </ul>)
    }
    renderCountries() {
        const { allCountries } = this.state;
        return (<ul className="dropdown-item-wrapper" id="dropdownCountries">
            {
                (allCountries.map((x, i) => {
                    let isCheck = this.handleIsChecked("countries", x.country)
                    return (<li key={`Countries-${i}`}>
                        <input type="checkbox" class="k-checkbox" id={`Countries-${i}`} style={{ margin: 0 }} onChange={e => this.handleMultiSelected(e.target.checked, x.country, "countries")} checked={isCheck} />
                        <label for={`Countries-${i}`} className="dropdown-item-label"  >{x.country}</label>
                    </li>)
                }))
            }
        </ul>)
    }
    renderDocumentTypes() {
        const { allDocumentTypes } = this.state;
        return (<ul className="dropdown-item-wrapper dropdown-item-wrapper-open-top">
            {
                (allDocumentTypes.map((x, i) => {
                    let isCheck = this.handleIsChecked("documenttypes", x.doctype)
                    return (<li key={`DocumentTypes-${i}`}>
                        <input type="checkbox" class="k-checkbox" id={`DocumentTypes-${i}`} style={{ margin: 0 }} onChange={e => this.handleMultiSelected(e.target.checked, x.doctype, "documenttypes")} checked={isCheck} />
                        <label for={`DocumentTypes-${i}`} className="dropdown-item-label"  >{x.doctype}</label>
                    </li>)
                }))
            }
        </ul>)
    }

    renderTherapeuticAreas() {
        const { allTherapeuticAreas } = this.state;
        return (<ul className="dropdown-item-wrapper">
            {
                (allTherapeuticAreas.map((x, i) => {
                    let isCheck = this.handleIsChecked("therapeutic_areas", x.taname)
                    return (<li key={`TherapeuticAreas-${i}`}>
                        <input type="checkbox" class="k-checkbox" id={`TherapeuticAreas-${i}`} style={{ margin: 0 }} onChange={e => this.handleMultiSelectedWithId(e.target.checked, x.taname, "therapeutic_areas", x.taid, "taids")} checked={isCheck} />
                        <label for={`TherapeuticAreas-${i}`} className="dropdown-item-label"  >{x.taname}</label>
                    </li>)
                }))
            }
        </ul>)
    }

    renderDrugClasses() {
        const { allDrugClasses } = this.state;
        return (<ul className="dropdown-item-wrapper">
            {
                (allDrugClasses.map((x, i) => {
                    let isCheck = this.handleIsChecked("drug_classes", x.classname)
                    return (<li key={`DrugClasses-${i}`}>
                        <input type="checkbox" class="k-checkbox" id={`DrugClasses-${i}`} style={{ margin: 0 }} onChange={e => this.handleMultiSelectedWithId(e.target.checked, x.classname, "drug_classes", x.classid, "classids")} checked={isCheck} />
                        <label for={`DrugClasses-${i}`} className="dropdown-item-label"  >{x.classname}</label>
                    </li>)
                }))
            }
        </ul>)
    }

    renderProducts() {
        const { allProducts } = this.state;
        return (<ul className="dropdown-item-wrapper">
            {
                (allProducts.map((x, i) => {
                    let isCheck = this.handleIsChecked("products", x.productname)
                    return (<li key={`Products-${i}`}>
                        <input type="checkbox" class="k-checkbox" id={`Products-${i}`} style={{ margin: 0 }} onChange={e => this.handleMultiSelectedWithId(e.target.checked, x.productname, "products", x.productid, "productids")} checked={isCheck} />
                        <label for={`Products-${i}`} className="dropdown-item-label"  >{x.productname}</label>
                    </li>)
                }))
            }
        </ul>)
    }

    renderReviewStatus() {
        const { data } = this.state;
        return (<ul className="dropdown-item-wrapper">
            {data.status !== "Accepted" && <li onClick={e => this.handleSingleSelected("Accepted", "status", "isReviewStatusShow")}>Accepted</li>}
            {data.status !== "Rejected" && <li onClick={e => this.handleSingleSelected("Rejected", "status", "isReviewStatusShow")}>Rejected</li>}
            {data.status !== "Pending" && <li onClick={e => this.handleSingleSelected("Pending", "status", "isReviewStatusShow")}>Pending</li>}
        </ul>)
    }
    renderTracking() {
        const { data } = this.state;
        return (<ul className="dropdown-item-wrapper">
            {data.datatracking !== "Yes" && <li onClick={e => this.handleSingleSelected("Yes", "datatracking", "isTrackingShow")}>Yes</li>}
            {data.datatracking !== "No" && <li onClick={e => this.handleSingleSelected("No", "datatracking", "isTrackingShow")}>No</li>}
        </ul>)
    }
    renderFunction() {
        const { data } = this.state;
        return (<ul className="dropdown-item-wrapper">
            {data.profession !== "Commercial" && <li onClick={e => this.handleSingleSelected("Commercial", "profession", "isFunctionShow")}>Commercial</li>}
            {data.profession !== "Medical" && <li onClick={e => this.handleSingleSelected("Medical", "profession", "isFunctionShow")}>Medical</li>}
            {/* {data.profession !== "R&D" && <li onClick={e => this.handleSingleSelected("R&D", "profession", "isFunctionShow")}>R&D</li>} */}
        </ul>)
    }
    renderProductPreference() {
        const { data } = this.state;
        return (<ul className="dropdown-item-wrapper">
            {data.contentprefernce !== "Yes" && <li onClick={e => this.handleSingleSelected("Yes", "contentprefernce", "isProductPreference")}>Yes</li>}
            {data.contentprefernce !== "No" && <li onClick={e => this.handleSingleSelected("No", "contentprefernce", "isProductPreference")}>No</li>}
        </ul>)
    }
    renderRussianUser() {
        const { data } = this.state;
        return (<ul className="dropdown-item-wrapper">
            {data.datatracking !== true && <li onClick={e => this.handleSingleSelected(true, "russianuser", "isRussianuserShow")}>Yes</li>}
            {data.datatracking !== false && <li onClick={e => this.handleSingleSelected(false, "russianuser", "isRussianuserShow")}>No</li>}
        </ul>)
    }
    renderAccessGroup() {
        const { Answers, Authoring, MISEHCP, AllContent, JanssenScience, LimitedUser } = this.state;
        return (<ul className="ul-access-group">
            <li>
                <input type="checkbox" name="Answers" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handelAccessGroup(e)} checked={Answers} />
                <label for="Answers" className="dropdown-item-label"  >Answers</label>
            </li>
            {Answers && <li>
                <ul className="ul-access-group">
                    <li>
                        <input type="checkbox" name="MISEHCP" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handelAccessGroup(e)} checked={MISEHCP} />
                        <label for="MISEHCP" className="dropdown-item-label"  >MISE</label>
                    </li>
                    <li>
                        <input type="checkbox" name="AllContent" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handelAccessGroup(e)} checked={AllContent} />
                        <label for="AllContent" className="dropdown-item-label"  >MSL</label>
                    </li>
                </ul>
            </li>}
            <li>
                <input type="checkbox" name="JanssenScience" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handelAccessGroup(e)} checked={JanssenScience} />
                <label for="JanssenScience" className="dropdown-item-label"  >Janssen Science</label>
            </li>
            <li>
                <input type="checkbox" name="Authoring" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handelAccessGroup(e)} checked={Authoring} />
                <label for="Authoring" className="dropdown-item-label"  >Authoring</label>
            </li>
            <li>
                <input type="checkbox" name="LimitedUser" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handelAccessGroup(e)} checked={LimitedUser} />
                <label for="LimitedUser" className="dropdown-item-label"  >Limited User</label>
            </li>
        </ul>)
    }
    renderCSV(csvData) {
        if (csvData) {
            return (csvData.replace(/;/g, '; '))
        } else {
            return (<></>);
        }
    }


    render() {
        return (
            <Modal
                show={this.props.showUserDetails}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition"
                scrollable={true}
            >
                <div style={{ borderLeft: `5px solid #1DA1E5`, overflow: "auto" }}>
                    <div>
                        <Modal.Header className="uudModalHeader" style={{ justifyContent: "left" }}>
                            <div className="actionItem-headerPart-icon">
                                <img src={IMSLogo} alt="iMedical Search" style={{ width: "190px", paddingTop: "2%" }} />
                            </div>
                            <div className="actionItem-headerPart-title">
                                <p>You Can Now Review And Edit The Details</p>
                            </div>
                        </Modal.Header>
                        <Modal.Body scrollable={true} style={{ maxHeight: "62vh" }} onMouseLeave={e => this.handelDropdown("isReviewStatusShow", false)} >
                            {this.state.isProcessing ?
                                <div className="wnfy-content-loader-wapper">
                                    <span className="wnfy-content-loader">
                                        <img src={imsLoader} width="50" height="50" alt="" />
                                    </span>
                                </div>
                                : <div>
                                    {this.state.data && <>
                                        <div className="UserDetail-MetaDataProperty-Name" onClick={e => this.handelDropdown("isReviewStatusShow", false)}>
                                            <span>{this.state.data.username}</span>
                                            <span>WWID: {this.state.data.wwid}</span>
                                        </div>
                                        <div className="UserDetail-MetaDataProperty-Disable" onClick={e => this.handelDropdown("isReviewStatusShow", false)}>
                                            <b>
                                                <label>Department :&nbsp;</label>
                                            </b>
                                            <label>{this.state.data.department}</label>
                                        </div>
                                        <div className="UserDetail-MetaDataProperty-Disable UserDetail-MetaDataProperty-Even" onClick={e => this.handelDropdown("isReviewStatusShow", false)}>
                                            <b>
                                                <label>E-Mail :&nbsp;</label>
                                            </b>
                                            <label>{this.state.data.email}</label>
                                        </div>
                                        <div className="UserDetail-MetaDataProperty"
                                            onClick={e => this.handelDropdown("isReviewStatusShow", !this.state.dropdownProperty.isReviewStatusShow)}>
                                            <div className="MetaDataProperty">
                                                <b>
                                                    <label>Review Status :&nbsp;</label>
                                                </b>
                                                <label>{this.state.data.status}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isReviewStatusShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isReviewStatusShow &&
                                            this.renderReviewStatus()
                                        }
                                        <div className="UserDetail-MetaDataProperty UserDetail-MetaDataProperty-Even"
                                            onClick={e => this.handelDropdown("isRegionsShow", !this.state.dropdownProperty.isRegionsShow)}>
                                            <div className="MetaDataProperty">
                                                <b style={{ width: "90px" }}>
                                                    <label>Region(s) :&nbsp;</label>
                                                </b>
                                                <label style={{ width: "820px" }}>{this.renderCSV(this.state.data.regions)}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon" >
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" id="ActionsArrowRegions" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isRegionsShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isRegionsShow &&
                                            this.renderRegions()
                                        }
                                        <div className="UserDetail-MetaDataProperty"
                                            onClick={e => this.handelDropdown("isCountriesShow", !this.state.dropdownProperty.isCountriesShow)}>
                                            <div className="MetaDataProperty">
                                                <b style={{ width: "140px" }}>
                                                    <label>Countries :&nbsp;</label>
                                                </b>
                                                <label style={{ width: "1200px" }}>{this.renderCSV(this.state.data.countries ? this.state.data.countries : "None")}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" id="ActionsArrowCountries" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isCountriesShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isCountriesShow &&
                                            this.renderCountries()
                                        }
                                        <div className="UserDetail-MetaDataProperty"
                                            onClick={e => this.handelDropdown("isRussianuserShow", !this.state.dropdownProperty.isRussianuserShow)}>
                                            <div className="MetaDataProperty">
                                                <b>
                                                    <label>Russian User :&nbsp;</label>
                                                </b>
                                                <label>{this.state.data.russianuser ? "Yes" : "No"}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" id="ActionsArrowRussianuser" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isRussianuserShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isRussianuserShow &&
                                            this.renderRussianUser()
                                        }
                                        <div className="UserDetail-MetaDataProperty UserDetail-MetaDataProperty-Even"
                                            onClick={e => this.handelDropdown("isTrackingShow", !this.state.dropdownProperty.isTrackingShow)}>
                                            <div className="MetaDataProperty">
                                                <b>
                                                    <label>Tracking :&nbsp;</label>
                                                </b>
                                                <label>{this.state.data.datatracking}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isTrackingShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isTrackingShow &&
                                            this.renderTracking()
                                        }
                                        <div className="UserDetail-MetaDataProperty"
                                            onClick={e => this.handelDropdown("isFunctionShow", !this.state.dropdownProperty.isFunctionShow)}>
                                            <div className="MetaDataProperty">
                                                <b>
                                                    <label>Function :&nbsp;</label>
                                                </b>
                                                <label>{this.state.data.profession}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isFunctionShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isFunctionShow &&
                                            this.renderFunction()
                                        }
                                        <div className="UserDetail-MetaDataProperty UserDetail-MetaDataProperty-Even"
                                            onClick={e => this.handelDropdown("isTherapeuticAreaShow", !this.state.dropdownProperty.isTherapeuticAreaShow)}>
                                            <div className="MetaDataProperty">
                                                <b style={{ width: "175px" }}>
                                                    <label>Therapeutic Area(S) :&nbsp;</label>
                                                </b>
                                                <label style={{ width: "725px" }}>{this.renderCSV(this.state.data.therapeutic_areas)}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isTherapeuticAreaShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isTherapeuticAreaShow &&
                                            this.renderTherapeuticAreas()
                                        }
                                        <div className="UserDetail-MetaDataProperty"
                                            onClick={e => this.handelDropdown("isDrugClassShow", !this.state.dropdownProperty.isDrugClassShow)}>
                                            <div className="MetaDataProperty">
                                                <b style={{ width: "180px" }}>
                                                    <label>Drug Class(es) :&nbsp;</label>
                                                </b>
                                                <label style={{ width: "1090px" }}>{this.renderCSV(this.state.data.drug_classes)}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isDrugClassShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isDrugClassShow &&
                                            this.renderDrugClasses()
                                        }
                                        <div className="UserDetail-MetaDataProperty UserDetail-MetaDataProperty-Even"
                                            onClick={e => this.handelDropdown("isProductsShow", !this.state.dropdownProperty.isProductsShow)}>
                                            <div className="MetaDataProperty">
                                                <b style={{ width: "100px" }}>
                                                    <label>Product(s) :&nbsp;</label>
                                                </b>
                                                <label style={{ width: "830px" }}>{this.renderCSV(this.state.data.products)}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isProductsShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isProductsShow &&
                                            this.renderProducts()
                                        }
                                        <div className="UserDetail-MetaDataProperty" onClick={e => this.handelDropdown("isProductPreference", !this.state.dropdownProperty.isProductPreference)}>
                                            <div className="MetaDataProperty">
                                                <b>
                                                    <label>All Product(S) Preference :&nbsp;</label>
                                                </b>
                                                <label>{this.state.data.contentprefernce}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isProductPreference ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        {this.state.dropdownProperty.isProductPreference &&
                                            this.renderProductPreference()
                                        }
                                        {this.state.dropdownProperty.isDocumentTypeShow &&
                                            this.renderDocumentTypes()
                                        }
                                        <div className="UserDetail-MetaDataProperty UserDetail-MetaDataProperty-Even"
                                            onClick={e => this.handelDropdown("isDocumentTypeShow", !this.state.dropdownProperty.isDocumentTypeShow)}>
                                            <div className="MetaDataProperty">
                                                <b style={{ width: "240px" }}>
                                                    <label>Document Type(S) :&nbsp;</label>
                                                </b>
                                                <label style={{ width: "1100px" }}>{this.renderCSV(this.state.data.documenttypes)}</label>
                                            </div>
                                            <div className="DropdownIcon-ArrowIcon">
                                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='iMSPubMedResults-dropdownIcon-mobile'
                                                    style={{ transform: this.state.dropdownProperty.isDocumentTypeShow ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} />
                                            </div>
                                        </div>
                                        <div className="UserDetail-MetaDataProperty"
                                            onClick={e => this.handelDropdown("isAccessGroupShow", !this.state.dropdownProperty.isAccessGroupShow)}>
                                            <div className="MetaDataProperty">
                                                <b>
                                                    <label>Access Group :&nbsp;</label>
                                                </b>
                                                <div className="access-group">
                                                    {this.renderAccessGroup()}
                                                </div>
                                            </div>
                                        </div>

                                    </>}

                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button text="Cancel" customColor='#FF8000' action={this.props.handleButtonEditCancel} />
                            <Button text="Save" customColor='#2EB000' action={() => { this.handleButtonUpdate() }} />
                        </Modal.Footer>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default UpdateUserDetailsPopUp;