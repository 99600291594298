import React, { Component, createRef, Fragment } from "react";
import { withRouter, Link } from 'react-router-dom';
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import GenAIResultIndex from './GenAIResultIndex';
import { imsLoader } from '../../assets/index';
import ExpandContentPopUpModal from '../PopUp/ExpandContentPopUpModal';

class GenAIImageIndex extends Component {
    constructor(props) {
        super(props);
        this.messagesEnd = createRef();
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            chatConversation: props.chatConversation,
            attachedFiles: props.attachedFiles,
            sessionID: props.sessionID,
            isMultiQuestionProcessing: props.isMultiQuestionProcessing,
            isProcessing: true,
            attachedImageUrl: "",
            expandCommand: false,
            scrollToBottomRandom: props.scrollToBottomRandom
        }
    }
    componentDidMount() {
        let _attachedImageUrl = "";
        if (this.props.attachedFiles.length > 0) {
            let _attachedfile = this.props.attachedFiles.find(x =>
                x.name.toLowerCase().endsWith('.gif')
                || x.name.toLowerCase().endsWith('.jpeg')
                || x.name.toLowerCase().endsWith('.jpg')
                || x.name.toLowerCase().endsWith('.png'));
            if (_attachedfile) {
                _attachedImageUrl = URL.createObjectURL(_attachedfile);
            }
        }
        this.setState({ attachedImageUrl: _attachedImageUrl, isProcessing: false })

    }
    componentWillReceiveProps(nextProps) {
        if (this.state.isMultiQuestionProcessing !== nextProps.isMultiQuestionProcessing) {
            this.setState({ isMultiQuestionProcessing: nextProps.isMultiQuestionProcessing })
        }
        if (this.state.attachedFiles.length !== nextProps.attachedFiles.length) {
            let _attachedImageUrl = "";
            if (nextProps.attachedFiles.length > 0) {
                let _attachedfile = nextProps.attachedFiles.find(x =>
                    x.name.toLowerCase().endsWith('.gif')
                    || x.name.toLowerCase().endsWith('.jpeg')
                    || x.name.toLowerCase().endsWith('.jpg')
                    || x.name.toLowerCase().endsWith('.png'));
                if (_attachedfile) {
                    _attachedImageUrl = URL.createObjectURL(_attachedfile);
                }
            }
            this.setState({ attachedFiles: nextProps.attachedFiles, attachedImageUrl: _attachedImageUrl, isProcessing: false })
        }
        if (this.state.scrollToBottomRandom !== nextProps.scrollToBottomRandom) {
            this.setState({ scrollToBottomRandom: nextProps.scrollToBottomRandom }, () => {
                this.scrollToBottom();
            })
        }
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    renderChatConversation = () => {
        let { chatConversation, customWidth, customHeight, attachedFiles, sessionID, isMultiQuestionProcessing, isImageThread } = this.state;

        return (<>
            {chatConversation.map((conversation, conversationIndex) => {
                let { Question, OutputAnswer, Sources, DisplayString,
                    RecommandationResults, SourcesMultiFiles,
                    DisplayStringMultiFiles, isMultiFiles, selectedFilters,
                    renderfilters, combinedSummaryMultiFiles, Reaction, Feedback,
                    isGetAnyAns, isSuccess, lastCreatedDatehour, isComprehensive,
                    RelevantInformation, ShowRelevantInformation, threadAttachedFiles } = conversation;
                combinedSummaryMultiFiles = (Array.isArray(combinedSummaryMultiFiles) && combinedSummaryMultiFiles && combinedSummaryMultiFiles.length > 0) ? combinedSummaryMultiFiles.filter(x => x !== 'No answer found in the document') : combinedSummaryMultiFiles;
                return (<React.Fragment key={`GenAIResultIndex-${conversation.eventid}-${conversationIndex}`}>
                    <GenAIResultIndex eventid={conversation.eventid} customWidth={customWidth} customHeight={customHeight} Question={Question} OutputAnswer={OutputAnswer}
                        Sources={Sources} DisplayString={DisplayString}
                        RecommandationResults={RecommandationResults} conversationIndex={conversationIndex} updateRunGenAISearch={this.props.updateRunGenAISearch}
                        SourcesMultiFiles={SourcesMultiFiles} DisplayStringMultiFiles={DisplayStringMultiFiles} isMultiFiles={isMultiFiles} attachedFiles={attachedFiles}
                        selectedFilters={selectedFilters} renderfilters={renderfilters} CombinedSummaryMultiFiles={combinedSummaryMultiFiles}
                        reaction={Reaction} feedback={Feedback} handleOnClickReaction={this.props.handleOnClickReaction}
                        sessionID={sessionID} questionType={conversation.questionType} threadID={conversation.threadID}
                        lastCreatedDatehour={lastCreatedDatehour}
                        isGetAnyAns={isGetAnyAns} isSuccess={isSuccess} handleOnClickFeedback={this.props.handleOnClickFeedback}
                        isComprehensive={isComprehensive}
                        RelevantInformation={RelevantInformation} handleOnClickMoreInformation={this.props.handleOnClickMoreInformation} ShowRelevantInformation={ShowRelevantInformation}
                        handleHideShowRelevantInformation={this.props.handleHideShowRelevantInformation} threadAttachedFiles={threadAttachedFiles}
                        isImageThread={true} />
                    {(conversationIndex < chatConversation.length - 1) && <hr style={{ margin: "0 20px" }}></hr>}
                </React.Fragment>)
            })}
            {isMultiQuestionProcessing && <div style={{ margin: "5px 10% 2% 10%" }}>
                <div style={{ fontSize: "12px", textAlign: "center" }} className="gen-ai-search-multi-question-loader">
                    <img src={imsLoader} alt="" width={50} height={50} />
                    <div className="saveModal-heading" style={{ color: "#0074B0" }}>Please wait while we generate answers for other questions.</div>
                    <hr style={{ margin: "5px 20px" }}></hr>
                </div>
            </div>}
            <div style={{ float: "left", clear: "both", height: "200px", width: "100%" }} ref={(el) => { this.messagesEnd = el; }}>&nbsp;
            </div>
        </>)


    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        const { confirmMessageContent, confirmMessageShowModal, attachedImageUrl, isProcessing } = this.state;
        if (this.state.customWidth >= 600 && this.state.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.state.customWidth > 1000) {
            return (<><div className="gen-ai-answering-image-main">
                <div className="gen-ai-answering-image-left">
                    {this.renderChatConversation()}
                </div>
                <div className="gen-ai-answering-image-right">
                    {isProcessing ? <div style={{ fontSize: "14px", textAlign: "center" }}>
                        <img src={imsLoader} alt="" width={100} height={100} />
                        <div className="saveModal-heading" style={{ color: "#0074B0" }}>Please wait...</div>
                    </div> : <>
                        {attachedImageUrl ? <div className="gen-ai-answering-image-right-img-section">
                            <img style={{ cursor: 'pointer' }}
                                onClick={() => this.setState({ expandCommand: true })}
                                src={attachedImageUrl} className="gen-ai-answering-image-right-img" />
                        </div> : <div className="gen-ai-answering-image-no-image">
                            <p>
                                <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path d="M7.828 5l-1-1H22v15.172l-1-1v-.69l-3.116-3.117-.395.296-.714-.714.854-.64a.503.503 0 0 1 .657.046L21 16.067V5zM3 20v-.519l2.947-2.947a1.506 1.506 0 0 0 .677.163 1.403 1.403 0 0 0 .997-.415l2.916-2.916-.706-.707-2.916 2.916a.474.474 0 0 1-.678-.048.503.503 0 0 0-.704.007L3 18.067V5.828l-1-1V21h16.172l-1-1zM17 8.5A1.5 1.5 0 1 1 15.5 7 1.5 1.5 0 0 1 17 8.5zm-1 0a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5zm5.646 13.854l.707-.707-20-20-.707.707z"></path>
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                    </g>
                                </svg>
                                &nbsp;
                                No image found. Kindly re-upload image.</p>
                        </div>}
                    </>}
                </div>
            </div>
                {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} btnText="Yes" />}
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                {this.state.expandCommand && <ExpandContentPopUpModal placeholder='Enter your command here.'
                    content={attachedImageUrl} showModal={this.state.expandCommand} handleSaveContent={() => { this.setState({ expandCommand: false }) }} />}
            </>)
        }
    }
}
export default withRouter(GenAIImageIndex);