import { Component } from "react";
import './GlobalSearchFeaturesPopUp.css'
import './PinToReadingListPopUp.css'
import ExpandContentPopUpModal from '../PopUp/ExpandContentPopUpModal';
import "./CreateUserPromptTemplate.css";
class CreateUserPromptTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promptTemplateText: props.promptTemplateText,
            currentIndex: props.currentIndex,
            nodeName: "",
            totalCounts: props.totalCounts,
            expandPromptTemplate: false
        }
    }
    handleOnFocusInput = (e) => {
        this.setState({ nodeName: e.target.nodeName });
    };

    handleInputPromptTemplateText = (e) => {
        let { currentIndex } = this.state;
        this.setState({
            promptTemplateText: e.target.value,
        }, () => {
            this.props.handleInputUserPromptTemplate(currentIndex, e.target.value);
        });
    };
    componentDidMount() { }
    handleAdd = () => {
        let { currentIndex } = this.state;
        this.props.handleAddNewUserPromptTemplate(currentIndex + 1);
    };
    handleRemove = () => {
        let { currentIndex } = this.state;
        this.props.handleRemoveUserPromptTemplate(currentIndex);
    };
    handlePromptTemplate = (promptTemplateText) => {
        let { currentIndex } = this.state;
        this.setState({ promptTemplateText: promptTemplateText, expandPromptTemplate: false }, () => {
            this.props.handleInputUserPromptTemplate(currentIndex, promptTemplateText);
        })
    }
    handleOnClickPromptTemplateExpand = () => {
        this.setState({ expandPromptTemplate: true })
    }
    render() {
        let { totalCounts, expandPromptTemplate } = this.state;
        return (<>
            <div className="user-prompt-template-wrapper">
                <div className="user-prompt-template-label">
                    Prompt:
                </div>
                <div className="user-prompt-template-textArea">
                    <textarea className="user-prompt-template-list-textarea-box"
                        placeholder="Add Prompt Here"
                        value={this.state.promptTemplateText}
                        onFocus={this.handleOnFocusInput}
                        onChange={this.handleInputPromptTemplateText} />
                    <div className="user-prompt-template-add-remove-box">
                        <span
                            onClick={(e) =>
                                this.handleAdd()
                            }
                            title="Add +"
                            className="reading-list-action-text"
                            style={totalCounts < 10 ? { visibility: "visible" } : { visibility: "hidden" }}
                        >
                            Add +
                        </span>
                        <span
                            style={(totalCounts > 1 && totalCounts < 10) ? { visibility: "visible" } : { visibility: "hidden" }}
                            className="action-items-separate-divider"></span>
                        <span
                            onClick={(e) =>
                                this.handleRemove()
                            }
                            title="Remove -"
                            className="reading-list-action-text"
                            style={totalCounts > 1 ? { visibility: "visible" } : { visibility: "hidden" }}
                        >
                            Remove -
                        </span>
                    </div>
                </div>
                <div className="user-prompt-template-expand" onClick={this.handleOnClickPromptTemplateExpand}>
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                    </svg>
                </div>
            </div>
            {expandPromptTemplate && <ExpandContentPopUpModal placeholder='Add Prompt Here' content={this.state.promptTemplateText} showModal={expandPromptTemplate} handleSaveContent={this.handlePromptTemplate} />}</>
        )
    }
}
export default CreateUserPromptTemplate;