import { useState, useEffect, useRef } from "react";
import ApiServices from "../../utils/apiServices";
import TTSSidebar from "./TTSSidebar";
import BootstrapTable from 'react-bootstrap-table-next';
import { EditorPen,PlayButton,PauseButton
} from "../../assets";
import axios from "axios";
import { error } from "jquery";
import sampleAudio from '../../assets/SampleAudioTest.mp3';
import AliasModal from "./AliasModal";
import AddLexiModal from "./AddLexiModal";
import { imsLoader } from "../../assets";
const AllLexiFileTable = (props) => {
   
    let MapValue = useRef (new Map());
    
    const [alldata, setAllData] = useState([]);
    const [isPlaying , setIsPlaying] = useState(false);
    const [checkedSelect, setCheckedSelect] = useState([]);
    const [row, setRowSelect] = useState({});
    const [checked, setCheck] = useState(false);
    const [isPlayingValue , setIsPlayingValue] = useState("");
    const [audio, setAudio] = useState();
    const audioSrc = useRef(new Audio());
    const [isEdit , setIsEdit] = useState(false);
    const [editLexiFilE, setEditLexiFile] = useState({});
    //const [loader , setLoader] = useState()
    const { duration } = audioSrc.current;
    const intervalRef = useRef();
    const [refresh , setRefresh] = useState(false);
    
    


    useEffect(()=>{
        audioSrc.current = new Audio(audio);
        },[audio]); 
    
    useEffect(()=>{
        handleSelectRow ( row , checked );
       },[row,checked])
    
       useEffect(()=>{
        props.setSelectedLexiFile(checkedSelect);
       },[checkedSelect])

       useEffect(()=>{
        refreshData()
       },[props.refresh])

       const refreshData = async()=>{
        if(props.refresh === true){
        setRefresh(true);
        await fetchData();
        setRefresh(false)
        props.setSelectedLexiFile([]);
        setCheckedSelect([])
       }
       props.setRefresh(false)
    }

       useEffect(() => {
        setRefresh(true);
        fetchData()
    }, [])

       const handleSelectRow = (row, isSelect) => {
        if (isSelect) {
            if(row.docid) {
              setCheckedSelect ([...checkedSelect, row]);
            }
            else {  
              setCheckedSelect ([...checkedSelect, row])}
            }
       else {
        if(row.docid) {setCheckedSelect (checkedSelect.filter(x => x !== row))}
        else{setCheckedSelect (checkedSelect.filter(x => x !== row))}
            }
          }
          

    const fetchData = async () => {
        let res;
        await axios.get(`https://stage-ims.jnj.com/api/db/runquery?dbquery=select * from "iMS".phm_ims_ttslexiconphonemerel r INNER JOIN "iMS".phm_ims_ttsphoneme p ON p.id = r.phonemeid INNER JOIN "iMS".phm_ims_ttslexicon l ON l.id = r.lexiconid order by name asc`).then((response)=>
        res= response.data.rows).catch((error)=> console.log(error));
        setRefresh(false)
        /*let res = [
            {
                name: "Test",
                grapheme: "hello",
                phoneme: "helllooo",
            },
            {
                name: "Test1",
                grapheme: "hello1",
                phoneme: "helllooo1",
            },
            {
                name: "Test",
                grapheme: "hello2",
                phoneme: "helllooo2",
            },
        ]*/
        setAllData(res)
        res.map((val)=>{
            MapValue.current.set( val?.name , false);
        })
        props.setRefresh(false)
    }
    const fetchAudio = async (row) =>{
        let payload;
        //let text1 = text.replace(textselected, `<phoneme alphabet="ipa" ph="${phonemeValue}">${textselected}</phoneme>`)
                payload =
                 {
                    "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang=\"en-US\">\n                        <voice name="en-US-AvaNeural">\n                           
                    <phoneme alphabet="ipa" ph="${row.phoneme}">${row.grapheme}</phoneme></voice>\n                    </speak>`,
                    
                  }
                  await axios.post(
                    `https://predev-ims.jnj.com/api/tts/ssml`,
                    payload,
                    {
                      responseType: "blob",
                    }
                  ).then((response) => {
                    const data = new Blob([response.data], { type: 'audio/wav' });
                   const blobUrl = URL.createObjectURL(data);
                    setAudio(blobUrl);
                    //setLoader(false)
                   }).catch((error) => {
                    console.log(error)
                })
    }
    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (audioSrc.current.ended) {
                setIsPlayingValue("")
                clearTimeout(intervalRef.current)
            }
        }, [1000]);
    };

    const handlePlayPause = (row) =>{
        if(row.phoneme === isPlayingValue){
            setIsPlayingValue("");
            handlePause();
        }
        else{
            setIsPlayingValue(row.phoneme);
              handlePlay(row);
        }
    }

    const handlePlay = async(row) =>{
        await fetchAudio(row);
        setIsPlaying(true);
         audioSrc.current.play();
         startTimer();      
    }

    const handlePause = () =>{
        setIsPlaying(false);
        audioSrc.current.pause();
    }

    const handleEdit = (row) =>{
        setEditLexiFile(row);
        setIsEdit(true);
    }

    let columns = [
        {
            headerStyle: {
              paddingTop: '5px',
              width: "5%",
            },
            style: {
              padding:"2px"
            },
            hidden: false,
            dataField:"folderid",
            formatter: (cell,row)=>{
              return <div style={{marginTop: "10px"}}><input type="checkbox" id={row} name="row" value={row} onChange={(e)=>{
                setRowSelect(row);
                setCheck(e.target.checked);
                //props.setSelected(row);
              }}/></div>
            },
          },
          {
            dataField: 'grapheme',
            text: 'Grapheme',
            editable: true,
            headerStyle: {
                width: "20%"
            },
            style: {
                padding: "2px"
            },
        },
        {
            dataField: 'phoneme',
            text: 'Phoneme',
            editable: false,
            headerStyle: {
                width: '20%',
                height: "1%"
            },
            style: {
                padding: "2px"
            },
        },
        /*{
            dataField: 'alias',
            text: 'Alias',
            editable: false,
            headerStyle: {
                width: '20%',
                height: "1%"
            },
            style: {
                padding: "2px"
            },
        },*/
        {
            dataField: "name" ,
            text: ' Lexicon File Name',
            editable: false,
            headerStyle: {
              textAlign:"center",
              width:"20%",
              marginLeft:'10px',
            },
            style: {
               padding:"2px",
               width:'10%',
               textAlign:"center",
               marginLeft:'10px',
            },
          },
        {
            dataField: '',
            text: 'Play',
            editable: false,
            headerStyle: {
                    width: "10%"
            },
            style: {
                padding: "2px"
            },
            formatter: (cell , row,i,isPlayingValue) =>{
                // <div><button onClick={()=>handlePLayPause()}>{(isPlaying)?<img src={PauseButton}/>:<img src={PlayButton}/>}</button></div>
            return(
               <><button
               style ={{ background:"#FFFF",
                border:"1.5px solid #0078d4",
                height:"30px",
                borderRadius:"0.5rem"
               }} onClick={()=>handlePlayPause(row)}>{(isPlayingValue === row.phoneme) ? <img src ={PauseButton}/>:<img src ={PlayButton}/>}</button></>
            )
            
        },
        formatExtraData: isPlayingValue
    },
        {
            dataField: 'name',
            text: 'Edit',
            editable: false,
            headerStyle: {
                width:"10%"
            },
            style: {
                padding: "2px",
            },
            formatter: (cell,row)=>{
                return(
                    <button style={{backgroundColor:"#FFFF"}} onClick={()=>handleEdit(row)}><img src = {EditorPen}/></button>
                )
            }, 
        }
    ]
    console.log(editLexiFilE)
    return (<>
            {(refresh) ? <div style={{ textAlign: "center", marginBottom: "10px"}}>
          <img src={imsLoader} alt="Loading Filters" style={{ width: "20%" , marginLeft: "380px"}} />
      </div> : <div style={{ margin: '10px'}}>
            <div className="Table-CXonatainer"
                style={{
                    height: "60%",
                    marginTop: "5px",
                }}>      
                {<BootstrapTable
                className="tableElement"
                    keyField="name"
                    data={alldata}
                    columns={columns}
                    loading={true} />}
            </div>
        </div>}
        {(isEdit) && <AliasModal
            prevGrapheme = {editLexiFilE.grapheme}
            prevPhoneme = {editLexiFilE.phoneme}
            textselected = {editLexiFilE.phonemeid}
            handleClose = {()=>{setIsEdit(false)
            props.setRefresh(true)}}
            fromLexicon = {true}
            setAddLexiModal = {()=>{}}
            setPhonemeValue = {()=>{}}
            setIsPhoneme = {()=>{}}
            setGrapheme = {()=>{}}
            />}
    </>)
}
export default AllLexiFileTable;