import { Component } from "react";
import GenAIAnsweringWindowPreview from "../../components/Preview/GenAIAnsweringWindowPreview";
import { LimitedUser } from '../../utils/utilityServices'
class GenAIAnsweringPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }
    componentDidMount() {
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))
        let userExists = localStorage.getItem('userExists');
        if (!userExists) {
            console.log('Access Denied');
            this.props.history.push('/');
        }
        let _function = JSON.parse(localStorage.getItem('function'));
        if (_function !== "Medical" && !userAccessGroup.includes(LimitedUser)) {
            console.log('Access Denied');
            this.props.history.push('/');
        } else {
            window.addEventListener("resize", this.updateDimensions);
        }

    }
    updateDimensions = () => {
        try {
            this.setState(
                { width: window.innerWidth, height: window.innerHeight });
        } catch (error) {
            this.setState({ error: error });
        }
    };

    render() {
        if (this.state.width >= 600 && this.state.width <= 1000) {
            return (<GenAIAnsweringWindowPreview customWidth={this.state.width} customHeight={this.state.height} />)
        } else if (this.state.width > 1000) {
            return (<GenAIAnsweringWindowPreview customWidth={this.state.width} customHeight={this.state.height} />)
        }
    }
}
export default GenAIAnsweringPreview    