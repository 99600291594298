import { Component } from "react";
import Footer from "../../components/footer/Footer";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import "./ClinicalTrial.css";
import { magnifyingGlass } from "../../assets";
import SuggestionsListForCT from "../../components/AutoComplete/SuggestionListForCT";
import axios from "axios";
import { imsLoader } from "../../assets";
import { LocationOrigin, getCurrentDate, WordsMaxLength } from '../../utils/utilityServices';
let _locationOrigin = LocationOrigin;

class ClinicalTrial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            searchKeyword: '',
            showSuggestions: false,
            isClickOnSuggestion: false,
            alternateId:[],
            isData:false,
            response: [],
            isFetch: false,
            isResponseProcessing : false,
            keyEventName:'',
            keyBoardEvent:false,
            rimdocs_protocol_id: [],
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        // document.title = 'Contact Us - iMedical Search'
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    handleChangeInSearch = (event) =>{
            /* let filters = this.props.location.search
                console.log(filters) */
            this.setState({
              searchKeyword: event.target.value,
              showSuggestions: true,
              isData: false,
              isFetch: false
            });
          };
    handleOnSuggestionClick = (isClick) => {
            this.setState({
              isClickOnSuggestion: isClick,
            });
          };
    handleKeyDown = (event) =>{
        if(["Enter"].includes(event.key)){
            this.setState({
                showSuggestions: false,
                isClickOnSuggestion: false,
                keyBoardEvent:true,
                keyEventName:"Enter",
            })
        }
        if (["ArrowDown"].includes(event.key) || ["ArrowUp"].includes(event.key)) {
            //console.log('Arrow Action')
            this.setState({
              keyBoardEvent: true,
              keyEventName: event.key,
            });
          }
    }
    handleOnClickSearchIcon = async() =>{
        let {searchKeyword} = this.state;
        if(searchKeyword){
            this.setState({isResponseProcessing : true,
                isData: false,
                isFetch: false,
            })
        axios.get(`${_locationOrigin}/api/qp/getCTDetails?query=${searchKeyword}`).then((res)=>
            {
                if(res.data.body.hits.hits?.length > 0){
                    this.setState({
                        isData:true,
                        response: res.data.body.hits.hits,
                        })
                }
            }).catch((error)=>{
                console.log(error)
            }).finally(()=>{
                this.setState({
                    isFetch: true,
                    isResponseProcessing: false,
                    keyBoardEvent: false,
                    keyEventName: "",
                    showSuggestions: false,
                })
            })
        }
    }
    handleOnChange = (query) =>{
        if(query){
        this.setState({
        searchKeyword: query,
        showSuggestions: false,
        },
        () => {
            this.handleOnClickSearchIcon();
          })
        }
        else{
            this.handleOnClickSearchIcon();
        }
    }
    handleCloseSuggestion = () =>{
        this.setState({
        showSuggestions : false 
        })
    }
    render() {
        if (this.state.width < 600) {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <div className="contactWrapper">
                        <div className="contactContentWrapper">
                            <div className="contactContent mobile-helpContentWrapper">
                            </div>
                        </div>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </>
            )
        }
        else if (this.state.width < 1000) {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <HeaderWithBanner />
                    <div className="contactWrapper">
                        <div className="contactContentWrapper">
                            <div className="contactContent">
                                <h1>Clinical Study Finder</h1>
                            </div>
                        </div>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </>
            )
        }
        else {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <HeaderWithBanner />
                    <div className="contactWrapper">
                        <div className="contactContentWrapper">
                            <div className="contactContent">
                            <div className="clinical-haeder"><span>Clinical Study Finder</span></div>
                            <div className="search-input-wrapper-tabletView">
                            <div style={{ display: "flex",justifyContent:"center"}}
                            /*onMouseLeave={this.handleCloseSuggestion}*/>
                            <div className="input-box-wrapper" style={{position:"relative"}}>
                            <input name="searchBar" placeholder="What are you looking for?" 
                            class="input-search-box" title="What are you looking for?" 
                            autocomplete="off" value={this.state.searchKeyword}
                            onChange={(e)=>this.handleChangeInSearch(e)}
                            onKeyDown={this.handleKeyDown}></input>
                            <span class="icon-wrapper">
                                <span class="search-icon-wrapper-webView" onClick={()=>this.handleOnClickSearchIcon()}>
                                <img src={magnifyingGlass} class="magnifyingGlass-mobileView" alt="search" title="Search"/>
                            </span>
                            </span>
                            {this.state.searchKeyword &&
              this.state.searchKeyword.length >= 2 && (
                <SuggestionsListForCT
                  searchKeyword={this.state.searchKeyword}
                  handleOnChange={this.handleOnChange}
                  keyBoardEvent={this.state.keyBoardEvent}
                  keyEventName={this.state.keyEventName}
                  showSuggestions={this.state.showSuggestions}
                  handleOnSuggestionClick={this.handleOnSuggestionClick}
                  handleKeyBoardEvent = {()=>{this.setState({
                    keyBoardEvent : false
                  })}}
                  handleKeyWord = {(query)=>{
                    this.setState({
                        searchKeyword: query,
                        showSuggestions:true
                        })
                  }}
                />
              )}
                            </div>
                            </div>
                            </div>
                            {(this.state.isResponseProcessing) && <div style={{ margin: "5px 10% 2% 10%" }}>
                            <div style={{ fontSize: "12px", textAlign: "center" }} className="gen-ai-search-multi-question-loader">
                                <img src={imsLoader} alt="" width={60} height={60} />
                            </div>
                        </div>}
                            {(this.state.isData && this.state.response.length > 0 ) &&
                            <>{this.state.response.map((x)=>{
                                return(
                                    <>
                            <div className="clinal-alternate-record"><span style={{ marginRight: "0.3rem"}}>All IDs:</span>
                            {/*<span>{this.state.response.ct_name}&#59;</span>*/}
                            {(x._source.ctname_protocolid) && <>
                            {x._source.ctname_protocolid.map((val,index)=>{
                                return(<> <span>{val}{(index !== x._source.ctname_protocolid.length - 1) && <>&#59;</>}</span>
                                </>)
                            })}
                            </>}
                            </div>
                            {/*{(x._source?.rimdocs_protocol_id?.length > 0 && x._source.rimdocs_protocol_id[0] ) && <>
                            <div className="clinal-alternate-record"><span style={{ marginRight: "0.3rem"}}>RimDocs IDs:</span>
                            {x._source?.rimdocs_protocol_id.map((val,index)=>{
                                return(<span>{val}{(index !== x._source.rimdocs_protocol_id.length - 1) && <>&#59;</>}</span>)
                            })}
                            </div>
                            </>}*/}
                            {(x._source.rimdocs_protocol_id) && <>
                                <div className="clinal-alternate-record"><span style={{ marginRight: "0.3rem"}}>Protocol ID:</span>
                            <span>{x._source.rimdocs_protocol_id}</span>
                                </div>
                            </>}
                            {(x._gov_api_response) && <><div className='clinal-alternate-record'>ClinicalTrials.gov</div>
                             <div className="clinal-alternate-record-table">
                                <table className="ct-wrapper">
                                    <tr className="ct-rows">
                                    {(x._gov_api_response[0].study_title) && <th className="ct-cells" style={{ minWidth:"210px"}}>Study Title</th>}
                                    {(x._gov_api_response[0].nctId) &&<th className="ct-cells" style={{ minWidth:"120px"}}>NCT Number</th>}
                                    {(x._gov_api_response[0].status) && <th className="ct-cells">Status</th>}
                                    {(x._gov_api_response[0].conditions) && <th className="ct-cells">Conditions</th>}
                                    {(x._gov_api_response[0].interventions) && <th className="ct-cells">Interventions</th>}
                                    {(x._gov_api_response[0].sponsor) &&<th className="ct-cells">Sponsor</th>}
                                    {(x._gov_api_response[0].study_type) &&<th className="ct-cells">Study Type</th>}
                                    {(x._gov_api_response[0].other_ids) && <th className="ct-cells">Other IDs</th>}
                                    {(x._gov_api_response[0].acronym) && <th className="ct-cells">Acronym</th>}
                                    </tr>

                                    {x._gov_api_response.map((val)=>{return (<><tr>
                                        {(val.study_title) && <td className="ct-cells nh" style={{ backgroundColor:"#dee2e6",maxWidth:"240px",textAlign:"left"}}>
                                        <span style={{wordWrap:"break-word"}}><a href={`https://clinicaltrials.gov/study/${val.nctId}`}>
                                        {val.study_title}</a></span></td>}
                                        {(val.nctId) && <td className="ct-cells nh"><div className="ct-container">{val?.nctId}</div></td>}
                                        {(val.status) &&<td className="ct-cells nh" style={{fontWeight:"bold"}}><div className="ct-container">{val?.status}</div></td>}
                                        {(val.conditions) && <td className="ct-cells nh"><div className="ct-container" style={{ marginTop:"40px"}}>{val?.conditions?.map((val)=>{
                                            return<><ul><li>{val}</li></ul></>
                                        })}</div></td>}
                                        {(val.interventions) && <td className="ct-cells nh"><div className ="ct-container" style={{ marginTop:"40px"}}>{val?.interventions?.map((val)=>{
                                            return<><ul><li>{val}</li></ul></>
                                        })}</div></td>}
                                        {(val.sponsor) && <td className="ct-cells nh"><div className="ct-container">{val?.sponsor}</div></td>}
                                        {(val.study_type) &&<td className="ct-cells nh"><div className="ct-container">{val?.study_type}</div></td>}
                                        {(val.other_ids) &&<td className="ct-cells nh"><div className="ct-container" style={{ marginTop:"40px"}}>{val.other_ids.map((val)=>{
                                            return<><ul><li>{val}</li></ul></>
                                        })}</div></td>}
                                        {(val.acronym) && <td className="ct-cells nh"><div className="ct-container">{val.acronym}</div></td>}
                                    </tr></>)})}
                                </table>
                            </div></>}
                            </>
                                )
                            })}
                            </>}
                            {(!this.state.isData && this.state.isFetch) &&<>
                            <div style={{ display:"flex",justifyContent:"center",marginTop:"2%"}}>No Result found</div>
                            </>}
                            </div>
                        </div>
                    </div>
                    {/*<Footer customWidth={this.state.width} customHeight={this.state.height} />*/}
                </>

            )
        }
    }
}
export default ClinicalTrial;