import { useEffect, useState, useRef } from "react";
import sampleAudio from '../../assets/SampleAudioTest.mp3';
import '../../components/TTS/AudioContent.css';

import {
    PauseButton,
    PlayButton,
    SquareIcon,
    ExportBlueIcon, SaveIcon, FileBlueIcon, arrowIconBlue, SaveIconDeactivate, ExportDeactivate,
    ImportantIcon,ArrowRight,imsLoader,arrowBlueInverted,DropDownDeactivate
} from "../../assets";
import { useHistory, useParams } from "react-router-dom";
import ApiServices from "../../utils/apiServices";
import MainFolder from "../../components/TTS/MainFolder";
import TTSSidebar from "../../components/TTS/TTSSidebar";
import RightPanel from "../../components/TTS/RightPanel";
import axios from "axios";
import { data } from "jquery";
import SaveAsPopUp from "../../components/TTS/SaveAsPopUp";
import ExportPopUp from "../../components/TTS/ExportPopUp";
import AlertMessagePopUpModal from '../../components/PopUp/AlertMessagePopUpModal';

const AudioContentPage = (props) => {
    //const [isPlaying ,setIspalying] = useState(false);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const { id } = useParams();
    const [folderStructure, setFolderStructure] = useState([]);
    const [fileSelected, setFileSelected] = useState({});
    const [prevFolder, setPrevFolder] = useState([])

    const [legacyFolderData, setLegacyFolderData] = useState([]);
    const [legacyDocumentData, setLegacyDocumentData] = useState([]);
    const [audio, setAudio] = useState();

    const [displayNew, setDisplayNew] = useState(false);
    const [newTextFileSelected, setNewTextFileSelected] = useState(false);
    const [textValue, setTextValue] = useState('');
    const [saveClicked , setSaveClicked] = useState(false);

    const [voiceSelected, SetVoiceSelected] = useState(false);
    const [lang ,setLang ] = useState('English (United States)');
    const [isVoiceChanged,setIsVoiceChanged] =useState(false);
    const [ voice, setVoice ] = useState('Jenny');
    const [rate, setRate] = useState(0);
    const [isRateSelected,setIsRateSelected] = useState(false);
    const [isRateChanged, setIsRateChanged] = useState(false)

    const history = useHistory();
    const audioSrc = useRef(new Audio());
    const [onTextChangeAudio,setonTextChangeAudio] = useState(false);
    const [isTextFocused,setIsTextFocused] = useState(false);
    const [istextChange , setIsTextChange] = useState(false);
    const [loader,setLoader] = useState(false);
    const [saveAsPop,setSaveAsPopUp] = useState(false);

    const [isSelectText,setIsSelectedText] = useState(false);
    const [textselected, setTextSelected] = useState('');
    const textareaRef = useRef();

    const [lexcionID,setLexiconID]= useState();
    const [isLexicon, setIsLexicon] = useState(false);

    const [isPhoneme ,setIsPhoneme] = useState(false);
    const [phonemeValue , setPhonemeValue] = useState([]);
    
    const [isAlias, setIsAlias] = useState(false);
    const [aliasValue , setAliasValue ] = useState([]);

    const [exportModal, setExportModal] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [openList , setOpenList] = useState(true);
    const [errorText , setErrorText] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageShowModal,setAlertMessageShowModal] = useState(false);

    const [legacyText , setLegacyText] = useState('');
    const [textArray ,setTextArray] = useState(textValue);
    const [finalArray , setFinalArray] = useState([]);
    const service = new ApiServices();

    useEffect(()=>{
        let textVal = textareaRef;
        let cursorStart = textVal.current.selectionStart;
        let cursorEnd = textVal.current.selectionEnd;
        let selectedText = textValue.substring(cursorStart,cursorEnd);
        if(selectedText.length < 51){
            setTextSelected(selectedText)
            //setPhonemeValue([])
            setIsSelectedText(false)
            setErrorText(false)
        }
        else{
            setTextSelected('')
            setErrorText(true)
            setIsSelectedText(false)
        }
    },[isSelectText])
    
    useEffect(()=>{
    audioSrc.current = new Audio(audio);
    },[audio])
    
    useEffect(()=>{
     onPauseClick()
     setFileSelected({});
     setPrevFolder([])
     setFolderStructure([]);
     setLegacyDocumentData([]);
     setLegacyFolderData([]);
     setAudio();
     fetchRes();
     setLoader(false);
     setIsPlaying(false);
     setIsRateSelected(false);
     setIsLexicon(false);
     setIsPhoneme(false);
     setIsAlias(false);
     setTextSelected('');
     setIsRateChanged(false);
    }, [id])

    /*useEffect(()=>{
        if(isAlias){
            setIsPhoneme(false);
        }
    },[isAlias])

    useEffect(()=>{
        if(isPhoneme){
            setIsAlias(false)
        }
    },[isPhoneme])*/
      
    useEffect(()=>{
        if(refresh){
        window.location.reload()
        }
       }, [refresh])


   /*useEffect(()=>{
       if(istextChange && !isTextFocused){
        //fetchaudioFile(fileSelected,textValue)
        setonTextChangeAudio(false);
        setIsTextChange(false);
        /*if(audio){
            audioSrc.current = new Audio(audio);
        }
        return fetchaudioFile(fileSelected,textValue)
       }
    },[isPlaying])*/

    /*useEffect(() => {
        SetVoiceSelected(false)
        fetchRes();
    }, [])*/
    const intervalRef = useRef();
    const isReady = useRef(false);
    const { duration } = audioSrc.current;
    //---------------------function------------------
    const fetchRes = async () => {
        let service = new ApiServices();
        let res1 = await service.getAllDocDetails();
        let res2 = await service.getAllFolderDetails();
        let array1 = [];
        let array2 = [];
        let filterdoc, fol, filterFol
        if (res1) {
            setLegacyDocumentData(res1)
            filterdoc = res1?.filter((val) => {
                return val.docid == id
            });
            if(filterdoc.length > 0){
            setLegacyText(filterdoc[0].doctext)
            setFileSelected(filterdoc[0]);
            setTextValue(filterdoc[0].doctext);
            setTextArray(filterdoc[0].doctext)
            setVoice(filterdoc[0].voice);
            setLexiconID(filterdoc[0].lexiconid);
            if(filterdoc[0].rate){
                setRate(parseInt(filterdoc[0].rate))
            }
            if(filterdoc[0].language){
            setLang(filterdoc[0].language)
        }
            fetchaudioFile(filterdoc[0],filterdoc[0].doctext,filterdoc[0].voice,filterdoc[0].language,filterdoc[0].rate?.concat('%'),filterdoc[0].lexiconid)
        }
    else{
        setFileSelected({
                "docid": null,
                "doctitle": "Untitled",
                "doctext": "",
                "doctextproperties": "plain text along with porperties like voice, silence etc",
                "folderid": 0,
                "datemodified": "1-1-1",
                "voice": "Jenny",
                "rate": "1",
                "language":'English (United States)'
            })
            setRate(0)
    }}

        if (res2) {
            setLegacyFolderData(res2)
            if(filterdoc.length > 0){
            fol = filterdoc[0].folderid
            if (fol === 0) {
                let array4 = ['All Files'].concat([filterdoc[0].doctitle]);
                setFolderStructure(array4)
            }
            else {
                filterFol = res2.filter((val) => {
                    return val.folderid === fol
                })
                array1 = array1.concat([filterdoc[0].doctitle]).concat([filterFol[0].foldername])
                array2 = array2.concat(filterFol[0].folderid)

                while (filterFol[0]?.folderid != filterFol[0]?.parentfolderid) {

                    let response = res2.filter((val) => {
                        return val.folderid === filterFol[0].parentfolderid
                    })
                    array1 = array1.concat(response[0].foldername)
                    array2 = array2.concat(response[0].folderid)
                    filterFol = response
                }
                let reverse = ["My Files"].concat(array1.reverse());
                let reverse1 = array2.reverse();
                setFolderStructure(reverse);
                setPrevFolder(reverse1);
            }
        }
        else{
            let array4 = ['All Files'].concat( ['Untitled*']);
                setFolderStructure(array4)
        }
        }
    }
    //------------------DtaURI To Binary
    //--------------fetchAudio-------------//
    const fetchaudioFile = async (fileSelected, text , voice,lang,rate,lexiconid)  => {

        let lang1
        if(lang === "Spanish (Spain)"){
            lang1 = "de-DE"
        }
        else{
            lang1 = "en-US"
        }
        let service = new ApiServices();
        let voice1;
        if(voice){
            voice1=voice
        }
        else{
            voice1 = 'Jenny'
            setVoice('Jenny')
        }
        setLoader(true);
        let rate1 ;
        if(typeof(rate) === "string"){
            rate1 = rate
        }
        else{
            rate1 = `${rate}%`
        }
        
        let res;
        let payload;
        /*let payload = {
            "doctitle": `${fileSelected1.doctitle}`,
            "doctext": `${fileSelected1.doctext}`,
            "ssml": `acutal ssml`,
            "folderid": `${fileSelected1.folderid}`,
            "datemodified": `${fileSelected1.datemodified}`,
            "tag": "",
            "filetype": "txt"
        }*/
           
            if(fileSelected.docid){
                if(isLexicon){
                payload =
                 {
                    "doctitle": `${fileSelected.doctitle}`,
                    "doctext": `${text}`,
                    "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                    <lexicon uri=\"https://predev-ims.jnj.com/api/tts/lexicon?lexicon_id=${lexcionID}\"/><prosody rate=\"${rate1}\">${text}</prosody>\n </voice>\n                    </speak>`,
                    "folderid": `${fileSelected.folderid}`,
                    "datemodified": `${fileSelected.datemodified}`,
                    "tag": "tags"
                  }
               }
               else if(isPhoneme && isAlias){
                let final_text = text;
                    phonemeValue.map((val)=>{
                        final_text = final_text.replace(val.textselected , `<phoneme alphabet="ipa" ph="${val.phonemeValue}">${val.textselected}</phoneme>`)
                    })
                    aliasValue.map((val)=>{
                        final_text = final_text.replace(val.textselected , `<sub alias="${val.aliasValue}">${val.textselected}</sub>.`)
                    })
                    payload =
                 {
                    "doctitle": `${fileSelected.doctitle}`,
                    "doctext": `${text}`,
                    "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                    <prosody rate=\"${rate1}\">${final_text}</prosody>\n </voice>\n                    </speak>`,
                    "folderid": `${fileSelected.folderid}`,
                    "datemodified": `${fileSelected.datemodified}`,
                    "tag": "tags"
                  }

               }
               else if(isPhoneme){
                let final_text = text;
                    phonemeValue.map((val)=>{
                        final_text = final_text.replace(val.textselected , `<phoneme alphabet="ipa" ph="${val.phonemeValue}">${val.textselected}</phoneme>`)
                    })
               // let text1 = text.replace(textselected, `<phoneme alphabet="ipa" ph="${phonemeValue}">${textselected}</phoneme>`)
               payload =
                 {
                    "doctitle": `${fileSelected.doctitle}`,
                    "doctext": `${text}`,
                    "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                    <prosody rate=\"${rate1}\">${final_text}</prosody>\n </voice>\n                    </speak>`,
                    "folderid": `${fileSelected.folderid}`,
                    "datemodified": `${fileSelected.datemodified}`,
                    "tag": "tags"
                  }
                 
               }
               else if(isAlias){
                let final_text = text;
                    aliasValue.map((val)=>{
                        final_text = final_text.replace(val.textselected , `<sub alias="${val.aliasValue}">${val.textselected}</sub>.`)
                    })
                //let text1 = text.replace(textselected,`<sub alias="${aliasValue}">${textselected}</sub>.`);
                payload =
                 {
                    "doctitle": `${fileSelected.doctitle}`,
                    "doctext": `${text}`,
                    "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                    <prosody rate=\"${rate1}\">${final_text}</prosody>\n </voice>\n                    </speak>`,
                    "folderid": `${fileSelected.folderid}`,
                    "datemodified": `${fileSelected.datemodified}`,
                    "tag": "tags"
                  }
               }
            else{
             payload = lexiconid ? 
             {
                "doctitle": `${fileSelected.doctitle}`,
                "doctext": `${text}`,
                "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                <lexicon uri=\"https://predev-ims.jnj.com/api/tts/lexicon?lexicon_id=${lexiconid}\"/><prosody rate=\"${rate1}\">${text}</prosody>\n </voice>\n                    </speak>`,
                "folderid": `${fileSelected.folderid}`,
                "datemodified": `${fileSelected.datemodified}`,
                "tag": "tags"
              } : 
              {
                "doctitle": `${fileSelected.doctitle}`,
                "doctext": `${text}`,
                "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                <prosody rate=\"${rate1}\">${text}</prosody>\n </voice>\n                    </speak>`,
                "folderid": `${fileSelected.folderid}`,
                "datemodified": `${fileSelected.datemodified}`,
                "tag": "tags"
              }
            }}
            else{
                if(isLexicon){
                    payload =
                     {
                        "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                        <lexicon uri=\"https://predev-ims.jnj.com/api/tts/lexicon?lexicon_id=${lexcionID}\"/><prosody rate=\"${rate1}\">${text}</prosody>\n </voice>\n                    </speak>`,
                      }
                   }
                   else if(isPhoneme){
                    let final_text = text;
                    phonemeValue.map((val)=>{
                        final_text = final_text.replace(val.textselected , `<phoneme alphabet="ipa" ph="${val.phonemeValue}">${val.textselected}</phoneme>`)
                    })
                    //let text1 = text.replace(textselected, `<phoneme alphabet="ipa" ph="${phonemeValue}">${textselected}</phoneme>`)
                    payload =
                     {
                        
                        "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                        <prosody rate=\"${rate1}\">${final_text}</prosody>\n </voice>\n                    </speak>`,
                      }
                   }
                   else if(isAlias){
                    let final_text = text;
                    aliasValue.map((val)=>{
                        final_text = final_text.replace(val.textselected , `<sub alias="${val.aliasValue}">${val.textselected}</sub>.`)
                    })
                    //let text1 = text.replace(textselected,`<sub alias="${aliasValue}">${textselected}</sub>.`);
                    payload =
                     {
                        
                        "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                        <prosody rate=\"${rate1}\">${final_text}</prosody>\n </voice>\n                    </speak>`,
                      }
                   }
                else{
                 payload =
                 {
                    
                    "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang="${lang1}">\n                        <voice name=\"${lang1.concat("-").concat(voice1)}Neural\">\n                           
                    <prosody rate=\"${rate1}\">${text}</prosody>\n </voice>\n                    </speak>`,
                    
                  }
                }
            }
        await axios.post(
            `https://predev-ims.jnj.com/api/tts/ssml`,
            payload,
            {
              responseType: "blob",
            }
          ).then((response) => {
            const data = new Blob([response.data], { type: 'audio/wav' });
           const blobUrl = URL.createObjectURL(data);
            setAudio(blobUrl);
            setLoader(false)
           }).catch((error) => {
            console.log(error)
        })
    }
    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            
            if (audioSrc.current.ended) {
                setTrackProgress(0)
                setIsPlaying(false)
            } else {
                setTrackProgress(audioSrc.current.currentTime);
            }
        }, [1000]);
    };
    const onScrub = (value) => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        audioSrc.current.currentTime = value;
        setTrackProgress(audioSrc.current.currentTime);
    };

    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    };
    useEffect(() => {
        let feildVal= textArray
        finalArray.map((val)=>{
            feildVal = feildVal.replace(val,`<span class='highlightText'>${val}</span>`)
        })
        setTextArray(feildVal)
        if (isPlaying ) {
            audioSrc.current.play();
            startTimer();
        } else {
            audioSrc.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
            audioSrc.current.pause();
            clearInterval(intervalRef.current);
        };
    }, []);

    const onPlayClick = async(bool) => {
        //setIsPlaying(true);
       // await fetchRes();
       // await fetchaudioFile();
       if(istextChange && !isTextFocused){
        await fetchaudioFile(fileSelected,textValue,voice,lang,rate)
        setonTextChangeAudio(false);
        setIsTextChange(false);
    }
    else if(isVoiceChanged){
        await fetchaudioFile(fileSelected,textValue,voice,lang,rate)
        setIsVoiceChanged(false)
    }
    else if (isRateChanged){
        await fetchaudioFile(fileSelected,textValue,voice,lang,rate)
        setIsRateChanged(false)
        //setIsRateSelected(false)
    }
     else if(isLexicon){
        await fetchaudioFile(fileSelected,textValue,voice,lang,rate,rate)
        //setIsLexicon(false)
    }
    else if(isPhoneme){
        await fetchaudioFile(fileSelected,textValue,voice,lang,rate)
        //setIsPhoneme(false)
    }
    else if(isAlias){
        await fetchaudioFile(fileSelected,textValue,voice,lang,rate)
        //setIsAlias(false)
    }
    
       ///------------------------------------
           setonTextChangeAudio(true);
           setTextSelected('')
            setIsPlaying(true);
            audioSrc.current.play();
    }
    const onPauseClick = (bool) => {
        setIsPlaying(false);
        audioSrc.current.pause();
    }
    const handleNewTextFile = () => {
        const doctext = '';
        setNewTextFileSelected(true)
        setTextValue('')
    }
    const handleLexiconFile = () => {
        history.push('/TSSMyTask')
    }
    const handleSaveAs = () =>{
        setSaveAsPopUp(true)
    }
    const handleSave = () =>{
        if(fileSelected.docid !== null){
            
            let res = service. updateDocumentVoiceRate(fileSelected.docid,voice,rate,textValue,lang,lexcionID)
              if(res){
                setAlertMessage("File saved successfully");
              setAlertMessageShowModal(true);
              }
          }
          else{
            setSaveAsPopUp(true)
          }
    }
    return (<>
        <div className="TTS-home-contents-wrapper" >
            <TTSSidebar />
            <div className="ms-Stack container-337" style={{ width: "100%" }}>
                <div className="fixedPaneContainer-162" style={{ width: "210px", height:"95vh" }}>
                    <div className="file-tree-panel-pnpi7ihwoh">
                        <span className="sc-dnqmqq jteVcr nav-callout-wrapper-i4cd0385ia">
                            <div>
                                <nav className="ms-Nav selected-root-parent root-300">
                                    <div className="ms-Nav-group is-expanded">
                                        <button className="ms-Nav-chevronButton chevronButton-304"
                                        onClick={()=>setOpenList(!openList)}>
                                            {(openList)?<img className='Icon-482' src={arrowIconBlue}/>:
                                            <img className='Icon-482' src={arrowBlueInverted}/>}
                                            All Files
                                        </button>
                                        <div className="ms-Nav-groupContent groupContent-310">
                                            {(openList)?<MainFolder
                                                legacyDocumentData={legacyDocumentData}
                                                legacyFolderData={legacyFolderData}
                                                fileSelected={fileSelected}
                                                prevFolder={prevFolder}
                                            />:<></>}
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </span>
                    </div>
                </div>
                <div tabIndex="0" className="css-338"></div>
                <div className="ms-StackItem flexiblePane-340">
                    <div className="ms-StackItem css-262" style={{ height: "100%" }}>
                        <div className="ms-StackItem css-343">
                            <div className="banner"></div>
                            <div style={{ height: "37px", display: "flex" }}>{folderStructure.map((value,i) => {
                                return (
                                    (i !== folderStructure.length -1) ? <div className="container-top"><button className="button-123"
                                    onClick={()=>history.push(`/TTSHome`)}
                                    >{value}</button><span><img src={ArrowRight}/></span></div>:<div className="container-top"><button className="button-123"
                                    >{value}</button></div>
                                )
                            })}</div>
                            <div className="main wrapper">
                                <div className="ms-Stack editor-panel">
                                    <div className="tuning-command-bar-ur8d2yznta flex-row">
                                        <span class="button-bar">
                                            <div style={{ position: "relative" }}>
                                                <div className="ms-FocusZone">
                                                    <div className="ms-OverflowSet ms-CommandBar-primaryCommand primarySet-192">
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button style={{ backgroundColor: "white" }}
                                                                onClick={() => { setDisplayNew(true) }}
                                                                onMouseLeave={() => setDisplayNew(false)}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <img className="icon-345" src={FileBlueIcon}></img>
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">File</span>
                                                                    </span>
                                                                    {(displayNew) ?
                                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="45.056"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                                        // <img src={arrowIconBlue} alt="" style={{ transform: "rotate(180deg)" }} />
                                                                        :
                                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                                        // <img src={arrowIconBlue} />
                                                                    }
                                                                </span>
                                                                {(displayNew) && <ul className="TSS-sub-wrapper-menu">
                                                                    <li onClick={() => handleNewTextFile()}>New File</li>
                                                                    <li onClick={() => { handleLexiconFile() }}>Lexicon File</li>
                                                                </ul>}
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button disabled={(voiceSelected || isRateSelected || (textValue.localeCompare(legacyText) !== 0)) ? false : true} style={{ backgroundColor: "white" }}
                                                            onClick={() =>{
                                                               // await fetchaudioFile(fileSelected,textValue,voice,lang);
                                                                    handleSave()}}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    {(voiceSelected || isRateSelected || (textValue.localeCompare(legacyText) !== 0)) ? <img className="icon-345" src={SaveIcon}></img>
                                                                        : <img className="icon-345" src={SaveIconDeactivate} />}
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">Save
                                                                        </span>
                                                                        <span className="css-534" aria-hidden="true"></span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button disabled={(voiceSelected || isRateSelected || (textValue.localeCompare(legacyText) !== 0)) ? false : true} style={{ backgroundColor: "white" }}
                                                            onClick={() =>{
                                                                    setSaveClicked(true)}}
                                                            onMouseLeave={()=>setSaveClicked(false)}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <span>
                                                                            {(voiceSelected || isRateSelected || (textValue.localeCompare(legacyText) !== 0)) ? 
                                                                            <img className="" src={arrowIconBlue}></img>:
                                                                            <img className="" src={DropDownDeactivate}/>}
                                                                            </span>
                                                                </span>
                                                                {(saveClicked) && <ul className="TSS-sub-wrapper-menu"
                                                                style={{ left : '85px'}}>
                                                                    <li onClick={() =>{
                                                                    //await fetchaudioFile(fileSelected,textValue,voice,lang,rate); 
                                                                        handleSaveAs()}}>SaveAs</li>
                                                                </ul>}
                                                            </button>
                                                        </div>

                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button  style={{ backgroundColor: "white" }}
                                                            onClick={async()=>{
                                                                setExportModal(true);
                                                                await fetchaudioFile(fileSelected,textValue,voice,lang,rate);
                                                            }}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    {<img className="icon-345" src={ExportBlueIcon}></img>}
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">Export</span>
                                                                    </span>
                                                                    <img src=""></img>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button style={{ backgroundColor: "white" }}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <img src=""></img>
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">...</span>
                                                                    </span>
                                                                    <img src=""></img>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    {(loader)?
                                      <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                     <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                      </div>:<>
                                    <div className="audio-player">
                                        <div className="audio-player-wrapper">
                                            <div className="synthesis-audio-player-container"
                                                style={{ display: "flex" }}>
                                                {isPlaying ? (
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        onClick={() => onPauseClick(true)}
                                                        aria-label="Pause"
                                                    ><span className="flexContainer-177">
                                                            <img src={PauseButton} />
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        onClick={() => onPlayClick(false)}
                                                        aria-label="Play"
                                                    >
                                                        <span className="flexContainer-177">
                                                            <img src={PlayButton} />
                                                        </span>
                                                    </button>
                                                )}
                                                <div style={{ display: "flex" }}>
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        aria-label="Pause"
                                                        onClick={() => onPauseClick(false)}
                                                    >
                                                        <span className="flexContainer-177"><img src={SquareIcon} /></span>
                                                    </button>
                                                    < div className="play-bar-wrap">
                                                        <span>{(parseInt(trackProgress/60) < 10)?`0${parseInt(trackProgress / 60)}`:
                                                        `${parseInt(trackProgress/10)}`}{`:`}{(parseInt(trackProgress % 60)< 10)?`0${parseInt(trackProgress % 60)}`:
                                                        `${parseInt(trackProgress % 60)}`}</span>
                                                        <input
                                                            type="range"
                                                            value={trackProgress}
                                                            step="1"
                                                            min="0"
                                                            max={duration ? duration : `${duration}`}
                                                            className="progress"
                                                            onChange={(e) => onScrub(e.target.value)}
                                                            onMouseUp={() => onScrubEnd()}
                                                            onKeyUp={() => onScrubEnd()}
                                                            style={{
                                                                backgroundColor: "#c7e0f4",
                                                                minWidth: "269px",
                                                                marginTop: "5px",
                                                                display: "inline-block"
                                                            }}
                                                        />
                                                        <span>{(duration) ? <>{(parseInt(duration/60) < 10) ?`0${parseInt(duration/60)}`:`${parseInt(duration/60)}`}
                                                        <>{`:`}</><>{(parseInt(duration % 60) < 10) ? `0${parseInt(duration % 60)}` : `${parseInt(duration % 60)}`}</>
                                                        </>: `00:00`}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-StackItem content-editor-wrapper css-393">
                                        <div className="ms-Stack container-394">
                                            <div className="ms-StackItem flexiblePane-396">
                                                <div className="content-editor theme-tuning multi-color">
                                                    <div className="editor-view-text notranslate">
                                                    <div style={{display:"flex"}}>
                                                    <div className="voice-text">[ {voice} ]</div>
                                                    <div >{(textselected.length > 0 ) && 
                                                    <span className="highlight">{textselected}</span>}</div></div>
                                                        {(!isPlaying)?<textarea className='editor-view-text notranslate'
                                                         style={{ width: "100%", minHeight: "270px",
                                                            border: "solid 1px #0078d4",
                                                            borderRadius: '0.5rem',
                                                            padding:"1rem",
                                                            marginTop:'3px'
                                                          }} 
                                                         ref= {textareaRef}
                                                        value={textValue}
                                                         onChange={(e) => {
                                                            setTextValue(e.target.value)
                                                            setTextArray(e.target.value)
                                                            setIsTextFocused(true)
                                                            setIsTextChange(true)
                                                        }} onBlur={()=>setIsTextFocused(false)}
                                                        onSelect={()=>setIsSelectedText(true)}
                                                        ></textarea>:<div
                                                            className="editor-view-text notranslate"
                                                            style={{width: "100%", 
                                                                maxHeight: "270px",
                                                                minHeight: '270px',
                                                                padding: "1rem",
                                                                marginTop:'3px',
                                                                textAlign:'left',
                                                                overflowY:'auto',
                                                                whiteSpace:'pre-wrap',
                                                                marginBottom:'7.5px'}}
                                                            onClick={()=>setIsPlaying(false)}
                                                            dangerouslySetInnerHTML={{__html: textArray}}></div>}</div>
                                                </div>
                                            </div>
                                            <div tabindex="0" className="css-517"></div>
                                            <div className="fixedPaneContainer-162 consoleWrapper-164" >
                                                <div className="consoleToolbar-165">
                                                    {(!voiceSelected && !isRateSelected && (textValue.localeCompare(legacyText) === 0)) ?
                                                     <div classname='format-checking-message-wrapper'>
                                                   <div className="check">
                                                    <div class="ms-MessageBar-content content-509">
                                                    <div class="ms-MessageBar-icon iconContainer-510" aria-hidden="true">
                                                       <img src={ImportantIcon}/></div>
                                                    <div class="ms-MessageBar-text text-512" id="MessageBar907" role="status" aria-live="polite">
                                                        <span class="ms-MessageBar-innerText innerText-513">
                                                            <span><div class="message-wrapper">
                                                                <span>File is not modified.
                                                                </span>
                                                            </div>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    </div>
                                                    </div>
                                                </div>:
                                                <div classname='format-checking-message-wrapper'>
                                                <div className="check">
                                                 <div class="ms-MessageBar-content content-509">
                                                 <div class="ms-MessageBar-icon iconContainer-510" aria-hidden="true">
                                                    <img src={ImportantIcon}/></div>
                                                 <div class="ms-MessageBar-text text-512" id="MessageBar907" role="status" aria-live="polite">
                                                     <span class="ms-MessageBar-innerText innerText-513">
                                                         <span><div class="message-wrapper">
                                                             <span>File is  modified.
                                                             </span>
                                                         </div>
                                                         </span>
                                                     </span>
                                                 </div>
                                                 </div>
                                                 </div>
                                             </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div></>}
                                    <div className="tuning-footer-98cfl5vjew"><div class="counter"
                                    style={{display:"flex",justifyContent:"flex-end"}}><label>Total characters:<span></span></label><b><span>{textValue.length}</span> / 20,000</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <aside>
                            <div className="control-panel-cxz21dnd6r">
                            <div class="control-panel-cxz21dnd6r">
                                <RightPanel
                                setVoiceSelected = {SetVoiceSelected}
                                setlang ={setLang}
                                setIsVoiceChanged = {setIsVoiceChanged}
                                setVoice = {setVoice}
                                setIsRateSelected = {setIsRateSelected}
                                setIsRateChanged = {setIsRateChanged}
                                rate={rate}
                                phonemeValue = {phonemeValue}
                                aliasValue = {aliasValue}
                                setRate = {setRate}
                                id={id}
                                textselected = {textselected}
                                lexcionID = {lexcionID}
                                setLexiconID = {setLexiconID}
                                setIsLexicon = {setIsLexicon}
                                setIsPhoneme = {setIsPhoneme}
                                setPhonemeValue = {setPhonemeValue}
                                setIsAlias = {setIsAlias}
                                setAliasValue = {setAliasValue}
                                errorText = {errorText}
                                setErrorText = {setErrorText}
                                textArray = {textArray}
                                finalArray = {finalArray}
                                setFinalArray = {setFinalArray}
                                setTextArray = {setTextArray}
                                textValue = {textValue}/>
                            </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    {(saveAsPop) && <SaveAsPopUp
    showModal = {saveAsPop}
    handleClose = {()=>{setSaveAsPopUp(false)}}
    legacyDocumentData = {legacyDocumentData}
    fileSelected ={fileSelected}
    textValue = {textValue}
    voice ={voice}
    rate = {rate}
    lang ={lang}
    lexcionID = {lexcionID}
    setRefresh={setRefresh}
    prevFolder={prevFolder}/>}

    {(exportModal) &&<ExportPopUp
    showModal = {exportModal}
    handleClose = {()=>setExportModal(false)}
    url ={audio}
    filename ={fileSelected.doctitle}/>}
    {alertMessageShowModal && <AlertMessagePopUpModal color='#00c12c' content={alertMessage} 
            showModal={alertMessageShowModal} 
            handleClose={() => 
                {setAlertMessageShowModal(false)
                setRefresh(true);
                //props.setRefresh(true);
                //window.location.reload();
            }} />}
    </>)
}
export default AudioContentPage;