/* eslint-disable no-useless-escape */
import { Modal, ModalBody } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component } from "react";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'
import './BookmarkPopUp.css'
import ApiServices from "../../utils/apiServices";
import { snippetLongString } from "../../utils/utilityServices"
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";

class ShareThreadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shared_to_email: '',
      error: '',
      formIsValid: true,
    };
  }

  handleInput = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      shared_to_email: e.target.value
    })
  }
  handleValidation = async () => {
    this.props.handleIsProcessing(true, "Please wait...");
    let errors = {};
    let form_Is_Valid = true;
    let service = new ApiServices();
    let sharedEmail = this.state.shared_to_email;
    let username = JSON.parse(localStorage.getItem('username'));
    if (username) {
      username = username.toLowerCase();
    }
    let apiRes = await service.getUserDetailsByUserName(sharedEmail);
    if (sharedEmail?.length === 0) {
      form_Is_Valid = false;
      errors["proptError"] = 'Please enter a valid email to proceed.';
    }
    else if (username === sharedEmail) {
      form_Is_Valid = false;
      errors["proptError"] = "Please use an email address other than yours's to share."
    }
    else if (apiRes?.length === 0) {
      form_Is_Valid = false;
      errors["proptError"] = 'Email is not a registered mail.Please enter a registerd email.'
    }
    else if (!sharedEmail.includes('@its.jnj.com')) {
      form_Is_Valid = false;
      errors["proptError"] = `Email id must contain domain name ex: user@its.jnj.com`;
    }
    this.setState({ error: errors, formIsValid: form_Is_Valid });
    return form_Is_Valid;
  }

  handleSubmit = async () => {
    let { shared_to_email } = this.state;

    if (await this.handleValidation()) {
      this.props.handleShareThreadToUser(shared_to_email);
    } else {
      this.props.handleIsProcessing(false, "");
    }
  }



  render() {
    return (
      <Modal
        show={this.props.showModal}
        size="lg md sm xs"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="popup-transition"
        className="z-index-ShareThread"
        backdropClassName="z-index-ShareThread-backdrop"
        scrollable={true}
      >
        <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
          <div className="popup-cancel-btn-wrapper">
            <img
              src={popCancelBttn}
              alt=""
              onClick={e => {
                this.setState({ showPop: false });
                this.props.handleClose(false);
              }}
            />
          </div>
          <div>
            <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
            </Modal.Header>
            <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
              <div>
                <span className="create-bookmark-field">Enter the email address of the user you would like to share this thread:  ​</span>
                <br />
                <input type="text" placeholder="ex: user@its.jnj.com​" value={this.state.shared_to_email} className="create-bookmark-input-box" onChange={this.handleInput}  /*onBlur={this.handleValidation}*/
                />
                <br />
                {/*<span className="length-info">Name with a maximum of 100 characters.</span>*/}
                <div className="errorInvalidName">{this.state.error["proptError"]}</div>
              </div>
            </Modal.Body>
            <div className="bookmark-popup-button-wrapper" style={{ width: '22%' }}>
              <Button text="Cancel" customColor="#0074B0" action={() => {
                this.setState({ showPop: false });
                this.props.handleClose(false);
              }} />
              <Button text="Share" customColor="#2EB000" action={this.handleSubmit} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ShareThreadModal;
