import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import Button from "../Button";
import ApiServices from "../../utils/apiServices";
import { text } from "@fortawesome/fontawesome-svg-core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SaveAsPopUp = (props) =>{
    const fileSelected = props.fileSelected;
    const [filename,setFileName] = useState('');
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageShowModal,setAlertMessageShowModal] = useState(false);
    const [confirmMessageShowModal,setConfirmMessageShowModal] = useState(false);
    const [voiceCureent,setVoiceCurrent] = useState("");
    const [rateCurrenrt,setCurrentRate] = useState("");
    const [textCurrent, setTextCurrent] = useState("");
    const [lexcionID , setLexiconID] = useState();
    const [lang ,setLang] = useState("");
    const [saveNew,setSaveNew] = useState(false);
    const doctextproperties = "plain text along with porperties like voice, silence etc";
    const [error , setError] = useState(false);
    let currentDate = new Date().toJSON().slice(0, 10);
    const [docid ,setDocid] = useState();
    const service = new ApiServices();
    const history = useHistory();

    useEffect(()=>{
        setVoiceCurrent(props.voice);
        setCurrentRate(props.rate);
        setTextCurrent(props.textValue);
        setLang(props.lang);
        setLexiconID(props.lexcionID);
    },[props.voice,props.rate,props.textValue,props.lang,props.lexcionID])

    useEffect(()=>{
      if(props.fileSelected.docid !== null){
        setFileName(props.fileSelected.doctitle) 
      }
      else{
        setSaveNew(true)
      }
    },[props.fileSelected])

    const handleFileCheck = () =>{
      let flag = props.legacyDocumentData.filter((val)=>{
        return val.doctitle === filename;
      })
      
      if(flag.length > 0){
        setError(true)
      }
      else{
        setError(false)
        setConfirmMessageShowModal(true)
      }
    }
    const confirmHandler = async()=>{
              //post api
              if(filename === fileSelected.doctitle && !saveNew){
              let res = service. updateDocumentVoiceRate(fileSelected.docid,voiceCureent,rateCurrenrt,textCurrent,lang,lexcionID )
              if(res){
                setAlertMessage("File  saved successfully");
              setAlertMessageShowModal(true);
              }
              }
              else if(saveNew ){
                let res = await service.pushFile(currentDate,null,textCurrent,doctextproperties,filename,0,voiceCureent,rateCurrenrt,lang, lexcionID); 
                if(res){
                setAlertMessage("File created successfully");
                setAlertMessageShowModal(true);
                setDocid(res.data[0]?.rows[0]?.docid)
                }
              }
              else{
                let res
              if(props.prevFolder.length > 0){
                 res = service.pushFile(currentDate,null,textCurrent,doctextproperties,filename,props.prevFolder[props.prevFolder.length -1],voiceCureent,rateCurrenrt,lang,lexcionID)
                }
                else{
                   res = service.pushFile(currentDate,null,textCurrent,doctextproperties,filename,0,voiceCureent,rateCurrenrt,lang,lexcionID);
                }
                if(res){
               setAlertMessage("File created successfully");
              setAlertMessageShowModal(true);
                }
              }           
              
        }

    const closeAlertMessage = () => {
        //props.setSelectedID();
        setAlertMessage('')
        setAlertMessageShowModal(false);
  
    }
    const handleFileNameChange = (e) =>{
      if(saveNew){
    setFileName(e.target.value);
      }
      setFileName(e.target.value);
    }
return(<>
<Modal
          show={props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition"
          scrollable={false}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={e => {
                props.handleClose()}}
              />
            </div>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                  <Modal.Title>
                    <div>
                        Save File
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
                <div className="text">Save File As</div>
                <div className= 'input-box'style={{display: "flex",flexDirection:"column", margin:"5px"}}>
                    <input type="text" placeholder="Enter File Name" style={{width: "60%"}} 
                    onChange={(e)=>handleFileNameChange(e)} title="Enter File Name" value={filename}>
                    </input>
                    <div style={{textAlign:'center',
                      marginLeft:'60px',
                      fontSize:"12px"
                    }}>Save as .txt File</div>
                  </div>
                  {(error) && <div style = {{color:'red'}}>File Name already exist</div>}
                <div className="reading-list-button-wrapper1">
                <div style={{ width:"20%", display:"flex",justifyContent:"space-between"}}>
                    <Button text ="Save" customColor='#0074B0' action={() =>{
                    handleFileCheck()}}/>
                    <Button text="Cancel" customColor="#0074B0" action={() => {
                    props.handleClose()}} />
                  </div>
                  </div>
              </Modal.Body>
                  
            </div>
          </div>
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={"Do you want to upload this file"} showModal={confirmMessageShowModal} handleClose={() => {setConfirmMessageShowModal(false);
            props.handleClose();}} handleConfirm={() =>confirmHandler()} />}
            {alertMessageShowModal && <AlertMessagePopUpModal color='#00c12c' content={alertMessage} 
            showModal={alertMessageShowModal} 
            handleClose={() => 
                {closeAlertMessage();
                props.handleClose();
                if(!saveNew){
                props.setRefresh(true);
                }
                else{
                  history.push(`/TTSAudioContentPage/${docid}`)
                }
                //props.setRefresh(true);
                //window.location.reload();
            }} />}
        </Modal>
</>)
}
export default SaveAsPopUp;