import axios from "axios";
import { LocationOrigin, getCurrentDate } from "../utils/utilityServices";
import { UserGeoData } from "../auth/Authorization";
import { v4 as uuidv4 } from 'uuid';
import { clone } from 'underscore'
export default class threadingService {
    async indextrackingData(_eventaction, _eventid, _answeringinfo) {
        var currentdate = new Date();
        var curMonth = currentdate.getUTCMonth() + 1;
        if (curMonth < 10) {
            curMonth = "0" + curMonth;
        }
        var curDate = currentdate.getUTCDate();
        if (curDate < 10) {
            curDate = "0" + curDate;
        }
        var curHour = currentdate.getUTCHours();
        if (curHour < 10) {
            curHour = "0" + curHour;
        }
        var curMin = currentdate.getUTCMinutes();
        if (curMin < 10) {
            curMin = "0" + curMin;
        }
        var curSec = currentdate.getUTCSeconds();
        if (curSec < 10) {
            curSec = "0" + curSec;
        }
        var curMil = currentdate.getUTCMilliseconds();
        if (curMil < 10) {
            curMil = "00" + curMil;
        }
        else if (curMil < 100) {
            curMil = "0" + curMil;
        }
        var datetime =
            currentdate.getFullYear() +
            "-" +
            curMonth +
            "-" +
            curDate +
            "T" +
            curHour +
            ":" +
            curMin +
            ":" +
            curSec +
            "." +
            curMil +
            "Z";

        let currentCountry = 'united states';
        if (UserGeoData && UserGeoData.country_name) {
            currentCountry = UserGeoData.country_name.toLowerCase();
        }
        let userdisplayName = localStorage.getItem('userdisplayName');
        let wwid = localStorage.getItem('wwid');
        let username = localStorage.getItem('username');
        let _function = localStorage.getItem('function');
        let region = localStorage.getItem('region');
        let userproducts = localStorage.getItem('userproducts');
        let doctypepref = localStorage.getItem('doctypepref');
        let sid = localStorage.getItem('sid');
        let deviceType = localStorage.getItem('deviceType');
        let deviceos = localStorage.getItem('deviceOS');
        let browserName = localStorage.getItem('browserName');
        let browserVersion = localStorage.getItem('browserVersion');
        let department = localStorage.getItem('jnjDepartment');
        let companyname = localStorage.getItem('companyname');
        userdisplayName = userdisplayName || '""';
        wwid = wwid || '""';
        username = username || '""';
        _function = _function || '""';
        region = region || "";
        userproducts = userproducts || "";
        doctypepref = doctypepref || "";
        sid = sid || '""';
        deviceType = deviceType || '""';
        deviceos = deviceos || '""';
        browserName = browserName || '""';
        browserVersion = browserVersion || '""';
        department = department || '""';
        companyname = companyname || '""';


        let eventinfo = {
            "eventcategory": window.location.host,
            "eventaction": _eventaction,
            "eventlabel": "",
            "eventsource": window.location.href,
            "createddatehour": datetime,
            "updatedatehour": datetime,
            "eventid": _eventid
        }

        let userinfo = {
            "name": JSON.parse(userdisplayName),
            "wwid": JSON.parse(wwid),
            "email": JSON.parse(username),
            "function": JSON.parse(_function),
            "region": region,
            "userproducts": userproducts,
            "doctypepref": doctypepref,
            "session": JSON.parse(sid),
            "devicetype": JSON.parse(deviceType),
            "deviceos": JSON.parse(deviceos),
            "browserName": JSON.parse(browserName),
            "browserVersion": JSON.parse(browserVersion),
            "department": JSON.parse(department),
            "companyname": JSON.parse(companyname),
            "currentCountry": currentCountry
        }
        let analyticsJson = {
            eventinfo: eventinfo,
            userinfo: userinfo,
            answeringinfo: _answeringinfo
        }
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "iatd";
        let res;
        await axios.post(url, analyticsJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
            },
        }).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;
    }
    getLatestAnswerThreadData = async (wwid) => {
        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_THREADING}glatd?wwid=${wwid}&size=${15}`
        let res = [];
        await axios
            .get(url)
            .then((response) => {
                res = response;
            })
            .catch((error) => {
                console.log(error);
                res = [];
            });
        return res;
    };
    getAnswerThreadDataBySessionId = async (wwid, sessionid) => {
        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_THREADING}gatbysid?wwid=${wwid}&sessionid=${sessionid}`
        let res;
        await axios
            .get(url)
            .then((response) => {
                res = response;
            })
            .catch((error) => {
                console.log(error);
            });
        return res;
    };
    updateAnswerThreaddatehour = async (wwid, sessionid) => {
        var currentdate = new Date();
        var curMonth = currentdate.getUTCMonth() + 1;
        if (curMonth < 10) {
            curMonth = "0" + curMonth;
        }
        var curDate = currentdate.getUTCDate();
        if (curDate < 10) {
            curDate = "0" + curDate;
        }
        var curHour = currentdate.getUTCHours();
        if (curHour < 10) {
            curHour = "0" + curHour;
        }
        var curMin = currentdate.getUTCMinutes();
        if (curMin < 10) {
            curMin = "0" + curMin;
        }
        var curSec = currentdate.getUTCSeconds();
        if (curSec < 10) {
            curSec = "0" + curSec;
        }
        var curMil = currentdate.getUTCMilliseconds();
        if (curMil < 10) {
            curMil = "00" + curMil;
        }
        else if (curMil < 100) {
            curMil = "0" + curMil;
        }
        var datetime =
            currentdate.getFullYear() +
            "-" +
            curMonth +
            "-" +
            curDate +
            "T" +
            curHour +
            ":" +
            curMin +
            ":" +
            curSec +
            "." +
            curMil +
            "Z";


        let analyticsJson = {
            wwid: wwid,
            sessionid: sessionid,
            updatedatehour: datetime
        }
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "uatd";
        let res;
        await axios.post(url, analyticsJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
            },
        }).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;
    }
    getAnswerThreadDataByUser = async (wwid, from, size, searchKeyword) => {
        if (searchKeyword) {
            searchKeyword = searchKeyword.replace(/"/g, `\\"`);
        }
        let analyticsJson = {
            "wwid": wwid,
            "from": from,
            "size": size,
            "query": searchKeyword
        }
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "gatdbyuser";
        let res;
        await axios.post(url, analyticsJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
            },
        }).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;
    }
    deleteAnswerThreadDataBySessionId = async (wwid, sessionid) => {
        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_THREADING}datbysid?wwid=${wwid}&sessionid=${sessionid}`
        let res;
        await axios
            .delete(url)
            .then((response) => {
                res = response;
            })
            .catch((error) => {
                console.log(error);
            });
        return res;
    }
    deleteAllAnswerThreadDataByUser = async (wwid) => {
        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_THREADING}daatbyuser?wwid=${wwid}`
        let res;
        await axios
            .delete(url)
            .then((response) => {
                res = response;
            })
            .catch((error) => {
                console.log(error);
            });
        return res;
    }

    getAnswerThreadDataBySessionIds = async (wwid, sessionIds, from, size) => {
        let analyticsJson = {
            "wwid": wwid,
            "from": from,
            "size": size,
            "sessionIds": sessionIds,
        }
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "gatdbysids";
        let res;
        await axios.post(url, analyticsJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
            },
        }).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;
    }
    deleteIndexBySessionId = async (sessionID) => {
        const formData = new FormData();
        formData.append(`sessionID`, sessionID);

        var url = LocationOrigin + process.env.REACT_APP_IMS_API_MULTI_ANSWERS + "multi_delete";
        //url = `${'https://predev-ims.jnj.com'}${process.env.REACT_APP_IMS_API_MULTI_ANSWERS}multi_delete`;
        if (window.location.origin === 'https://predev-ims.jnj.com') {
            url = `${window.location.origin}${process.env.REACT_APP_IMS_API_MULTI_ANSWERS}multi_delete`
        }
        let config = {
            method: 'POST',
            url: url,
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        }
        let res;
        await axios(config).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;

    }
    updateThreadData = async (eventid, newEventid, answeringinfo) => {
        var currentdate = new Date();
        var curMonth = currentdate.getUTCMonth() + 1;
        if (curMonth < 10) {
            curMonth = "0" + curMonth;
        }
        var curDate = currentdate.getUTCDate();
        if (curDate < 10) {
            curDate = "0" + curDate;
        }
        var curHour = currentdate.getUTCHours();
        if (curHour < 10) {
            curHour = "0" + curHour;
        }
        var curMin = currentdate.getUTCMinutes();
        if (curMin < 10) {
            curMin = "0" + curMin;
        }
        var curSec = currentdate.getUTCSeconds();
        if (curSec < 10) {
            curSec = "0" + curSec;
        }
        var curMil = currentdate.getUTCMilliseconds();
        if (curMil < 10) {
            curMil = "00" + curMil;
        }
        else if (curMil < 100) {
            curMil = "0" + curMil;
        }
        var datetime =
            currentdate.getFullYear() +
            "-" +
            curMonth +
            "-" +
            curDate +
            "T" +
            curHour +
            ":" +
            curMin +
            ":" +
            curSec +
            "." +
            curMil +
            "Z";

        let wwid = localStorage.getItem('wwid');
        wwid = wwid || '""';
        let analyticsJson = {
            wwid: JSON.parse(wwid),
            eventid: eventid,
            updatedatehour: datetime,
            answeringinfo: answeringinfo,
            neweventid: newEventid
        }
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "uatbyeid";
        let res;
        await axios.post(url, analyticsJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
            },
        }).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;
    }
    updateThreadRelevantInformationData = async (eventid, relevantInformation) => {
        var currentdate = new Date();
        var curMonth = currentdate.getUTCMonth() + 1;
        if (curMonth < 10) {
            curMonth = "0" + curMonth;
        }
        var curDate = currentdate.getUTCDate();
        if (curDate < 10) {
            curDate = "0" + curDate;
        }
        var curHour = currentdate.getUTCHours();
        if (curHour < 10) {
            curHour = "0" + curHour;
        }
        var curMin = currentdate.getUTCMinutes();
        if (curMin < 10) {
            curMin = "0" + curMin;
        }
        var curSec = currentdate.getUTCSeconds();
        if (curSec < 10) {
            curSec = "0" + curSec;
        }
        var curMil = currentdate.getUTCMilliseconds();
        if (curMil < 10) {
            curMil = "00" + curMil;
        }
        else if (curMil < 100) {
            curMil = "0" + curMil;
        }
        var datetime =
            currentdate.getFullYear() +
            "-" +
            curMonth +
            "-" +
            curDate +
            "T" +
            curHour +
            ":" +
            curMin +
            ":" +
            curSec +
            "." +
            curMil +
            "Z";

        let wwid = localStorage.getItem('wwid');
        wwid = wwid || '""';
        let analyticsJson = {
            wwid: JSON.parse(wwid),
            eventid: eventid,
            updatedatehour: datetime,
            relevantInformation: relevantInformation,
        }
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "uataribyeid";
        let res;
        await axios.post(url, analyticsJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
            },
        }).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;
    }
    updateThreadAnswerReactionData = async (eventid, reaction) => {
        var currentdate = new Date();
        var curMonth = currentdate.getUTCMonth() + 1;
        if (curMonth < 10) {
            curMonth = "0" + curMonth;
        }
        var curDate = currentdate.getUTCDate();
        if (curDate < 10) {
            curDate = "0" + curDate;
        }
        var curHour = currentdate.getUTCHours();
        if (curHour < 10) {
            curHour = "0" + curHour;
        }
        var curMin = currentdate.getUTCMinutes();
        if (curMin < 10) {
            curMin = "0" + curMin;
        }
        var curSec = currentdate.getUTCSeconds();
        if (curSec < 10) {
            curSec = "0" + curSec;
        }
        var curMil = currentdate.getUTCMilliseconds();
        if (curMil < 10) {
            curMil = "00" + curMil;
        }
        else if (curMil < 100) {
            curMil = "0" + curMil;
        }
        var datetime =
            currentdate.getFullYear() +
            "-" +
            curMonth +
            "-" +
            curDate +
            "T" +
            curHour +
            ":" +
            curMin +
            ":" +
            curSec +
            "." +
            curMil +
            "Z";

        let wwid = localStorage.getItem('wwid');
        wwid = wwid || '""';
        let analyticsJson = {
            wwid: JSON.parse(wwid),
            eventid: eventid,
            updatedatehour: datetime,
            answer_reaction: reaction,
        }
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "uatarbyeid";
        let res;
        await axios.post(url, analyticsJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
            },
        }).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;
    }
    updateThreadAnswerReportFeedbackData = async (eventid, currentFeedback) => {
        var currentdate = new Date();
        var curMonth = currentdate.getUTCMonth() + 1;
        if (curMonth < 10) {
            curMonth = "0" + curMonth;
        }
        var curDate = currentdate.getUTCDate();
        if (curDate < 10) {
            curDate = "0" + curDate;
        }
        var curHour = currentdate.getUTCHours();
        if (curHour < 10) {
            curHour = "0" + curHour;
        }
        var curMin = currentdate.getUTCMinutes();
        if (curMin < 10) {
            curMin = "0" + curMin;
        }
        var curSec = currentdate.getUTCSeconds();
        if (curSec < 10) {
            curSec = "0" + curSec;
        }
        var curMil = currentdate.getUTCMilliseconds();
        if (curMil < 10) {
            curMil = "00" + curMil;
        }
        else if (curMil < 100) {
            curMil = "0" + curMil;
        }
        var datetime =
            currentdate.getFullYear() +
            "-" +
            curMonth +
            "-" +
            curDate +
            "T" +
            curHour +
            ":" +
            curMin +
            ":" +
            curSec +
            "." +
            curMil +
            "Z";

        let wwid = localStorage.getItem('wwid');
        wwid = wwid || '""';
        let analyticsJson = {
            wwid: JSON.parse(wwid),
            eventid: eventid,
            updatedatehour: datetime,
            answer_feedback: currentFeedback,
            answer_feedback_updatedatehour: datetime,
        }
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "uatarfbyeid";
        let res;
        await axios.post(url, analyticsJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
            },
        }).then((response) => {
            res = response;
        }).catch((error) => {
            console.log(error);
        });
        return res;
    }
    delay = ms => new Promise(res => setTimeout(res, ms));
    shareAnswerThreadDataBySessionId = async (wwid, sessionid, userInfo) => {
        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_THREADING}gatbysidForShare?wwid=${wwid}&sessionid=${sessionid}`
        let _answerThreadData = [], _answerThreadDataSource = [];
        await axios
            .get(url)
            .then((response) => {
                _answerThreadData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });

        if (_answerThreadData && _answerThreadData.length > 0) {
            let currentCountry = '';
            let userdisplayName = userInfo.usertitle;
            let wwid = `${userInfo.wwid}`;
            let username = userInfo.email;
            let _function = userInfo.function;
            let region = userInfo.region;
            let userproducts = userInfo.products
            let doctypepref = userInfo.docs;
            let sid = "";
            let deviceType = '';
            let deviceos = '';
            let browserName = '';
            let browserVersion = '';
            let department = '';
            let companyname = '';
            let shared_from_wwid = JSON.parse(localStorage.getItem('wwid'));
            let shared_from_email = JSON.parse(localStorage.getItem('username'));
            let shared_from_name = JSON.parse(localStorage.getItem('userdisplayName'));
            userdisplayName = userdisplayName || '';
            wwid = wwid || '';
            username = username || '';
            _function = _function || '';
            region = region || "";
            userproducts = userproducts || "";
            doctypepref = doctypepref || "";
            sid = sid || uuidv4();
            deviceType = deviceType || '';
            deviceos = deviceos || '';
            browserName = browserName || '';
            browserVersion = browserVersion || '';
            department = department || '';
            companyname = companyname || '';
            let userinfo = {
                "name": userdisplayName,
                "wwid": wwid,
                "email": username,
                "function": _function,
                "region": region,
                "userproducts": userproducts,
                "doctypepref": doctypepref,
                "session": sid,
                "devicetype": deviceType,
                "deviceos": deviceos,
                "browserName": browserName,
                "browserVersion": browserVersion,
                "department": department,
                "companyname": companyname,
                "currentCountry": currentCountry
            }
            let _session_id = uuidv4();
            for (let i = 0; i < _answerThreadData.length; i++) {
                let source = _answerThreadData[i]._source;
                let _eventid = uuidv4();
                source.answeringinfo.session_id = _session_id;
                source.eventinfo.eventid = _eventid;
                source.eventinfo.createddatehour = clone(getCurrentDate());
                source.eventinfo.updatedatehour = clone(getCurrentDate());
                source.answeringinfo.lastcreateddatehour = clone(getCurrentDate());
                source.userinfo = userinfo;
                source.answeringinfo.sharedfromwwid = shared_from_wwid
                source.answeringinfo.sharedfromemail = shared_from_email
                source.answeringinfo.sharedfromname = shared_from_name
                source.answeringinfo.issharedthread = true;
                _answerThreadDataSource.push(source);
            }
            let analyticsJson = {
                shareThreadData: _answerThreadDataSource,
                newSessionId: _session_id,
            }
            var url = LocationOrigin + process.env.REACT_APP_IMS_API_THREADING + "psatd";
            //url = 'http://localhost:32843' + process.env.REACT_APP_IMS_API_THREADING + "psatd";

            let result = await axios.post(url, analyticsJson, {
                headers: {
                    // Overwrite Axios's automatically set Content-Type
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                return true;
            }).catch((error) => {
                return false;
            });
            return result;
        } else {
            return false;
        }
        return false;
    };
}