/* eslint-disable array-callback-return */
import axios from "axios";
import Services from "../../utils/services";
import { Component } from "react";
import { LocationOrigin, getCurrentDate, WordsMaxLength } from '../../utils/utilityServices';
let _locationOrigin = LocationOrigin;

class SuggestionsListForCT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            fieldedSuggestions: [],
            keyEventName: '',
            cursor: -1,
            query:""
        }
    }
    componentDidMount() {
        this.getSuggestionsList(this.props.searchKeyword)
        this.setState({
            keyEventName: ''
        })
    }
    getSuggestionsList = async (keyword) => {
        let services = new Services();
        if (this.props.searchKeyword.length > 0) {
            let res = await axios.get(`${_locationOrigin}/api/qp/getCTSuggestions?query=${keyword}`)
            if (res.data) {
                this.setState({
                    suggestions: res.data.ctSuggestions,
                    //fieldedSuggestions: res.data.fieldSuggestions
                })
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.searchKeyword !== this.props.searchKeyword) {
            this.getSuggestionsList(nextProps.searchKeyword)
        }
        if (nextProps.keyBoardEvent) {
            if (nextProps.keyEventName.includes('ArrowDown') || nextProps.keyEventName.includes('ArrowUp')) {
                this.setState({
                    keyEventName: this.props.keyEventName
                }, () => {
                    this.handleKeyDown(this.state.keyEventName)
                    this.props.handleKeyBoardEvent();
                })
            }
            else if(nextProps.keyEventName.includes('Enter')){
                this.setState({
                    keyEventName: this.props.keyEventName
                }, () => {
                    this.handleOnEnter()
                    this.props.handleKeyBoardEvent();
                })
            }
        }
    }
    componentWillUnmount() {
        this.setState({
            suggestions: [],
            fieldedSuggestions: [],
            keyEventName: '',
            cursor: 0
        })
    }
    listOfSuggestions = (arr) => {
        if (arr.length > 0) {
            return (
                arr.map((item, index) => {
                    if (item) {
                        return (
                            <li key={index} onClick={e => this.setSearchKeyword(item)} 
                            className={this.state.cursor === index ? 'suggestions-list-active' : ''} 
                            onMouseOver={()=>{this.setState({
                                cursor:-1,
                            })}} onMouseEnter={e => this.handleOnEnter}>{this.convertToHtml(item)}</li>
                        )
                    }
                })
            )
        }
    }
    convertToHtml = (item) => {
        return (<div dangerouslySetInnerHTML={{ __html: item }}></div>)
    }
    setSearchKeyword = (item,e) => {
        let fieldedValue, query;
        let keyword = item.replaceAll('<b>', '').replaceAll('</b>', '');
        if (keyword.lastIndexOf(' in all ') > 0) {
            let indexofField = keyword.lastIndexOf('in all');
            fieldedValue = keyword.substring(indexofField + 7, keyword.length);
            query = keyword.substring(0, indexofField - 1);
            /* fieldedValue = keyword.split(' in all ')[1]
            query = keyword.split(' in all ')[0] */
            if(e?.includes('ArrowDown') || e?.includes('ArrowUp')){
               // this.props.handleKeyWord(query)
               this.setState({
                query : query
               })
            }
            else{
            this.props.handleOnChange(query, fieldedValue)
            this.props.handleOnSuggestionClick(true);
            }
        }
        else {
            query = keyword
            if(e?.includes('ArrowDown') || e?.includes('ArrowUp')){
               // this.props.handleKeyWord(query)
               this.setState({
                query:query
               })
            }else{
            this.props.handleOnChange(query, null);
            }
        }
    }
    handleOnEnter = () => { 
            this.props.handleOnChange(this.state.query, null);
    }
    handleKeyDown = (e) => {
        const { cursor, suggestions } = this.state
        if (e === 'ArrowUp' && cursor > 0) {
            this.setState(prevState => ({
                cursor: prevState.cursor - 1
            }), () => {
                this.setSearchKeyword(suggestions[this.state.cursor],e)
            })
        } else if (e === 'ArrowDown' && cursor < suggestions.length - 1) {
            this.setState({
                cursor: cursor + 1
            }, () => {
                this.setSearchKeyword(suggestions[this.state.cursor],e)
            })
        }
    }
    
    render() {
        return (
            <>
                {this.props.showSuggestions &&
                    <div className="suggestions-list-wrapper">
                        {this.state.suggestions.length > 0 || this.state.fieldedSuggestions.length > 0 ?
                            <ul>
                                {this.listOfSuggestions(this.state.suggestions)}
                                {this.state.suggestions.length > 0 && this.state.fieldedSuggestions.length > 0
                                    // &&
                                    // <hr/>
                                }
                                {this.state.fieldedSuggestions.length > 0 &&
                                    <>
                                        <div className="suggestions-fielded-header">Are you looking for..</div>
                                        {this.listOfSuggestions(this.state.fieldedSuggestions)}
                                    </>
                                }
                            </ul>
                            :
                            <></>
                        }
                    </div>
                }
            </>
        )
    }
}
export default SuggestionsListForCT;