import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component, useEffect, useState } from "react";
import Button from "../Button";
import './CreateFolderPopUp.css';
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import ApiServices from "../../utils/apiServices";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import axios from "axios";

const CreateLexiModal =(props)=>{
   const [lexiName , setLexiName] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageShowModal,setAlertMessageShowModal] = useState(false);
    
    const [confirmMessageShowModal,setConfirmMessageShowModal] = useState(false);
    const [lexiData , setLexiData] = useState();
    const [error , setError] = useState(false);

    useEffect(()=>{
      FetchLexiconData();
    },[])

    const FetchLexiconData = async() =>{
      await axios.get(`https://stage-ims.jnj.com/api/db/runquery?dbquery=select name from "iMS".phm_ims_ttslexicon`).then(
        (res)=>setLexiData(res.data.rows)).catch((error)=>console.log(error))
    } 

    const confirmHandler = ()=>{
      //post api
      async function api(){
        let service = new ApiServices()
          let myapiResponse = await service.pushLexiFile(lexiName); 
          if (myapiResponse) {
            setAlertMessage("Lexicon File created successfully");
            setAlertMessageShowModal(true)
        }
      }
      api()
      //props.handleClose()
      //window.location.reload();
    }
    const closeAlertMessage = () => {
      //props.setSelectedID();
      setAlertMessage("");
      setAlertMessageShowModal(false);
      props.setRefresh()
  }
  const handleValidation = () =>{
    let dataCheck = lexiData.filter((val)=>{
      return val.name === lexiName
    })
    if(dataCheck.length === 0){
      setConfirmMessageShowModal(true)
    }
    else if(dataCheck.length > 0){
      setError(true)
    }
  }
    return(<>
   <Modal
          show={props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition"
          scrollable={false}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={e => {
                props.handleClose()}}
              />
            </div>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                  <Modal.Title>
                    <div>
                        Add new Lexicon File
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
                <div className= 'input-box'style={{display: "flex",flexDirection:"column", margin:"5px"}}>
                    <span style={{paddingTop:"5px"}}>Name *</span>
                    <input type="text" placeholder="Name your Lexicon File" value={lexiName} style={{width: "60%"}} onChange={(e)=>{
                      setError(false)
                      setLexiName(e.target.value)}}></input>
                </div>
                {(error) && <div style={{ color : 'red'}}>Lexicon File Name Already Exist.
                </div>}
                
                <div className="reading-list-button-wrapper1">
                  <div style={{ width:"20%", display:"flex",justifyContent:"space-between"}}>
                    <Button text ="Create" customColor='#0074B0' action={() =>{
                      handleValidation()}}/>
                    <Button text="Cancel" customColor="#0074B0" action={() => {
                    props.handleClose()}} />
                    </div>
                  </div>
              </Modal.Body>
                  
            </div>
          </div>
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={"Do you want to save this file"} showModal={confirmMessageShowModal} handleClose={() => {setConfirmMessageShowModal(false);
            props.handleClose();}} handleConfirm={() =>confirmHandler()} />}
                        {alertMessageShowModal && <AlertMessagePopUpModal color='#00c12c' content={alertMessage} 
            showModal={alertMessageShowModal} 
            handleClose={() => 
                {closeAlertMessage();
                props.handleClose();
                //window.location.reload();
            }} />}
        </Modal>
    </>)
}
export default CreateLexiModal;