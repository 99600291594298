import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component, useEffect, useState } from "react";
import Button from "../Button";
import './CreateFolderPopUp.css';
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import ApiServices from "../../utils/apiServices";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";

const UploadPopUp = (props) =>{
    const [file , setFile]= useState(null);
    const [fileContent , setFileContent] = useState('');
    const [confirmMessageShowModal,setConfirmMessageShowModal] = useState(false);
    const [folder_id,setFolderId] = useState();
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageShowModal,setAlertMessageShowModal] = useState(false);
    const docstream = null;
    const doctextproperties = "plain text along with porperties like voice, silence etc";
    const voice = 'Jenny',rate = 0,language='English (United States)';
    let currentDate = new Date().toJSON().slice(0, 10);
      useEffect(()=>{
           setFileContent('');
           setFile(null);
           if(props.selectedFolder){
            setFolderId(props.selectedFolder)
          }
          else if(props.selectedID === null){
            const func = async () =>{
              /*let service = new ApiServices();
              let last=await service.getlastFolderId();*/
              setFolderId(0)
            }
            func()
          }
          else{
            setFolderId(props.selectedID?.folderid) ;
          }
      },[])
    const confirmHandler = ()=>{
      //post api
      async function api(){
        let service = new ApiServices();
          let myapiResponse = await service.pushFile(currentDate,docstream,fileContent,doctextproperties,file.name,folder_id,voice,rate,language); 
          if (myapiResponse) {
            setAlertMessage("File Name saved successfully");
            setAlertMessageShowModal(true)
        }
      }
      api()
      //props.handleClose()
      //window.location.reload();
    }
    const closeAlertMessage = () => {
      //props.setSelectedID();
      setAlertMessage("");
      setAlertMessageShowModal(false);

  }
    const handleFileChange = (e) => {
      const selectedFile = e. target.files[0];
      setFile(e.target.files[0])
        let reader = new FileReader();
        reader.readAsText(selectedFile);
        reader.onloadend = (e) => {
          setFileContent(e.target.result)
        };
        //reader.readAsText(selectedFile)
    }
    return(<>
   <Modal
          show={props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition"
          scrollable={false}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={e => {
                props.handleClose()}}
              />
            </div>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                  <Modal.Title>
                    <div>
                        Upload File
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
                <div className="text">Upload plain text files (.txt) or SSML files (.txt) less than 10MB. File(s) shall be formatted in UTF-8</div>
                <div className= 'input-box'style={{display: "flex",flexDirection:"column", margin:"5px"}}>
                    <span style={{paddingTop:"5px"}}>Text(.txt)*</span>
                    <input type="file" placeholder="Browse Files" style={{width: "60%"}} 
                    onChange={(e)=>handleFileChange(e)} title="Browse Files" data-max-size="2048"></input>
                </div>
                <div className="reading-list-button-wrapper1">
                <div style={{ width:"20%", display:"flex",justifyContent:"space-between"}}>
                    <Button text ="Upload" customColor='#0074B0' action={() =>{
                    setConfirmMessageShowModal(true)}}/>
                    <Button text="Cancel" customColor="#0074B0" action={() => {
                    props.handleClose()}} />
                  </div>
                  </div>
              </Modal.Body>
                  
            </div>
          </div>
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={"Do you want to upload this file"} showModal={confirmMessageShowModal} handleClose={() => {setConfirmMessageShowModal(false);
            props.handleClose();}} handleConfirm={() =>confirmHandler()} />}
            {alertMessageShowModal && <AlertMessagePopUpModal color='#00c12c' content={alertMessage} 
            showModal={alertMessageShowModal} 
            handleClose={() => 
                {closeAlertMessage();
                props.handleClose();
                props.setRefresh(true);
                //window.location.reload();
            }} />}
        </Modal>
    </>)
}

export default UploadPopUp;