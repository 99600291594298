import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import "./index.css"
import { imsLoader } from "../../assets";
import _response from "./responses.json"
import GenAIGraphViewMilestoneItem from "./GenAIGraphViewMilestoneItem"
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import axios from 'axios';
import { LocationOrigin, getCurrentDate } from '../../utils/utilityServices';
import { Modal } from "react-bootstrap";


const GraphInProcessing = 'GraphInProcessing';
const GraphSucceeded = 'GraphSucceeded';
const GraphUnsucceeded = 'GraphUnsucceeded';
let limitString = 250;
let _locationOrigin = LocationOrigin;
//_locationOrigin = 'https://predev-ims.jnj.com';

class GenAIGraphViewMilestone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            milestoneName: '',
            userGraphInfo: props.userGraphInfo,
            question: props.question,
            isShowMore: false,
            milestones: [],// _response,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            isSuccessAlertMessageShow: false,
            isProcessing: true,
            spinnerMessage: '',
        }
    }
    componentDidMount() {
        this.fetchMilestone();
    }
    fetchMilestone = () => {
        let { userGraphInfo } = this.state;
        let url = '';
        this.setState({ isProcessing: true })
        let _payload = {
            "thread_id": userGraphInfo.graphAPIResponseThreadId
        }
        url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_GRAPH}fetch_milestone`
        if (window.location.origin === 'https://predev-ims.jnj.com') {
            url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_GRAPH}fetch_milestone`
        }
        else if (window.location.origin === 'http://localhost:3000') {
            url = `https://predev-ims.jnj.com/api/poctest/fetch_milestone`;
        }

        var config;
        config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: _payload
        }
        axios(config)
            .then(async (resp) => {
                let response = resp.data;
                if (response && response.length > 0) {
                    this.setState({ milestones: response })
                }
            })
            .catch(async (error) => {
                this.errorAlertMessage("Sorry, something went wrong.");
            })
            .finally(async () => {
                this.setState({
                    isProcessing: false,
                    spinnerMessage: '',
                })
            });
    }
    handleAddMilestoneClose = () => {
        this.props.handleClose(false)
    }
    handleMilestoneName = (e) => {
        this.setState({ milestoneName: e.target.value })
    }
    handleClickOnRevertToMilestone = async (_userGraphInfo, _userSelectedGraphTypes, _userGraphRequirements, _question) => {
        this.setState({ isProcessing: true })
        let isSuccess = await this.props.handleClickOnRevertToMilestone(_userGraphInfo, _userSelectedGraphTypes, _userGraphRequirements, _question)
        this.setState({ isProcessing: false })
        if (isSuccess) {
            this.successAlertMessage(`Revert Milestone successfully.`);            
        } else {
            this.errorAlertMessage("Sorry, something went wrong.");
        }
    }
    snippetLongString = (snippet) => {
        if (snippet.length > limitString) {
            let text = "";
            let spanIndex = snippet.indexOf('<span class=');
            if (spanIndex !== -1) {
                text = snippet.substring(spanIndex, (spanIndex + limitString))//cuts to spanIndex+150
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            } else {
                text = snippet.substring(0, limitString)//cuts to 70
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            }
            //text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    renderMilestones = () => {
        let { milestones, customWidth, customHeight } = this.state;
        if (milestones && milestones.length > 0) {
            return (<>
                {
                    milestones.map((item, index) => {
                        return (<GenAIGraphViewMilestoneItem indexReverttoMilestoneData={this.props.indexReverttoMilestoneData} handleClickOnRevertToMilestone={this.handleClickOnRevertToMilestone} currentIndex={index} totalItems={milestones.length} customWidth={customWidth} customHeight={customHeight} key={`GenAIGraphViewMilestoneItem-${index}`} milestoneName={item.Title} graph={item.graph} data={item.data} threadId={item.thread_id} requirements={item.requirement} graphTypes={item.graphType} />)
                    })
                }
            </>);
        } else {
            return (<div className="gen-ai-graph-view-milestone-container-bottom-empty">
                <p>You currently do not have any milestone added.</p>
            </div>);
        }

    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true,
            isSuccessAlertMessageShow: true,
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true,
            isSuccessAlertMessageShow: false,
        })
    }
    closeAlertMessage = () => {
        let { isSuccessAlertMessageShow } = this.state;
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false,
            showPreview: false
        }, () => {
            if (isSuccessAlertMessageShow) {
                this.props.handleClose(false);
            }
        })
    }

    render() {
        let { milestoneName, userGraphInfo, question, isShowMore } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        let text = "";
        if (!isShowMore) {
            text = this.snippetLongString(question);
        }
        else {
            text = question;
        }
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-graph-view-milestone-container">
                <div className="gen-ai-graph-view-milestone-container-top">
                    <span>&nbsp;</span>
                    <p className="gen-ai-graph-view-milestone-container-top-title">
                        <svg style={{ marginRight: "2px" }} fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 100 100" enable-background="new 0 0 100 100">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <g>
                                    <g>
                                        <path d="M85.088,80.271c2.715,0,4.912-2.2,4.912-4.917V24.645c0-2.717-2.197-4.917-4.912-4.917H14.912 c-2.714,0-4.912,2.2-4.912,4.917v50.709c0,2.717,2.197,4.917,4.912,4.917H85.088z M80.175,70.443h-60.35V29.556h60.35V70.443z"></path> </g> <g> <g> <path d="M41.564,65.897c-0.927,0-1.819-0.37-2.481-1.029l-4.896-4.897l-5.96,4.932c-1.5,1.242-3.708,1.024-4.942-0.466 c-1.234-1.495-1.026-3.71,0.466-4.941l8.424-6.969c1.394-1.156,3.434-1.06,4.719,0.223l4.072,4.075l8.979-13.989 c0.69-1.07,1.874-1.688,3.181-1.602c1.272,0.082,2.4,0.846,2.945,2.002l5.299,11.206L71.602,36.45 c0.96-1.688,3.099-2.266,4.787-1.318c1.683,0.963,2.273,3.102,1.315,4.79L64.133,63.784c-0.646,1.135-1.901,1.82-3.183,1.769 c-1.31-0.046-2.481-0.821-3.041-2.008l-5.479-11.591L44.516,64.28c-0.573,0.897-1.521,1.48-2.577,1.597 C41.815,65.887,41.688,65.897,41.564,65.897z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        View Milestones</p>
                    <div onClick={e => { this.handleAddMilestoneClose() }} style={{ cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="30px" height="30px">
                            <path fill="currentColor" d="M 64 6 C 48.5 6 33.9 12 23 23 C 12 33.9 6 48.5 6 64 C 6 79.5 12 94.1 23 105 C 34 116 48.5 122 64 122 C 79.5 122 94.1 116 105 105 C 116 94 122 79.5 122 64 C 122 48.5 116 33.9 105 23 C 94.1 12 79.5 6 64 6 z M 64 12 C 77.9 12 90.900781 17.399219 100.80078 27.199219 C 110.70078 36.999219 116 50.1 116 64 C 116 77.9 110.60078 90.900781 100.80078 100.80078 C 90.900781 110.60078 77.9 116 64 116 C 50.1 116 37.099219 110.60078 27.199219 100.80078 C 17.299219 91.000781 12 77.9 12 64 C 12 50.1 17.399219 37.099219 27.199219 27.199219 C 36.999219 17.299219 50.1 12 64 12 z M 50.5625 47.5 C 49.8 47.5 49.05 47.800391 48.5 48.400391 C 47.3 49.600391 47.3 51.499609 48.5 52.599609 L 59.800781 64 L 48.400391 75.300781 C 47.200391 76.500781 47.200391 78.4 48.400391 79.5 C 49.000391 80.1 49.8 80.400391 50.5 80.400391 C 51.2 80.400391 51.999609 80.1 52.599609 79.5 L 64 68.199219 L 75.300781 79.5 C 75.900781 80.1 76.700391 80.400391 77.400391 80.400391 C 78.100391 80.400391 78.9 80.1 79.5 79.5 C 80.7 78.3 80.7 76.400781 79.5 75.300781 L 68.199219 64 L 79.5 52.699219 C 80.7 51.499219 80.699609 49.600391 79.599609 48.400391 C 78.399609 47.200391 76.500391 47.200391 75.400391 48.400391 L 64 59.800781 L 52.699219 48.400391 C 52.099219 47.800391 51.325 47.5 50.5625 47.5 z" />
                        </svg>
                    </div>
                </div>
                <div className="gen-ai-graph-view-milestone-container-bottom-list" style={{ overflow: "auto" }}>
                    {this.renderMilestones()}
                </div>
                {this.state.isProcessing &&
                    <Modal
                        show={this.state.isProcessing}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="savePopUp"
                        className="z-index-milestone"
                        backdropClassName="z-index-milestone-backdrop"
                        scrollable={true}>
                        <div style={{ padding: "0 5%" }}>
                            <div style={{ fontSize: "14px", textAlign: "center" }}>
                                <Modal.Body>
                                    <img src={imsLoader} alt="" width={100} height={100} />
                                    <div className="saveModal-heading" style={{ color: "#0074B0" }}>Please wait...</div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>}
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </div>)
        }
    }

}
export default withRouter(GenAIGraphViewMilestone);