import { banner } from "../assets";
export const UtilityServices = {
  getPdfCSS() {
    return `body {
                margin: 0;
                font-family: var(--bs-font-sans-serif);
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                -webkit-text-size-adjust: 100%;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }

            .App {
                text-align: center;
                font-size: 16px;
            }

            .bannerImage {
                background: url(${LocationOrigin}${banner}) no-repeat 0 0;
                padding: 25px 0 45px;
                position: relative;
                background-size: 100% 100%;
            }

            img,
            svg {
                vertical-align: middle;
            }

            .download-results-page-wrapper {
                width: 100%;
                font-family: 'J&J CircularTT-Medium';
                color: #333;
            }

            .download-results-page-contents-wrapper {
                font-size: 14px;
                text-align: left;
                padding: 1% 3%;
            }

            .results-div-docTitle-wrapper {
                flex: 1 1;
                padding-right: 1%;
            }

            .resultDetailsDiv {
                width: 95%;
                display: flex;
                flex-direction: column;
            }

            .results-title-action-outer-wrapper {
                display: flex;
                flex-direction: row;
                padding-top: 0.5%;
            }

            .resultDetailsDiv a {
                color: #0074B0;
                font-weight: bold;
                font-size: 14px;
                display: inline;
                word-break: break-word;
                cursor: pointer;
                text-decoration: none;
            }

            .citation-results-meta-property-wrapper {
                padding-top: 1%;
            }

            .PHM-MetaDataProperty {
                box-sizing: border-box;
                font-size: 14px;
                line-height: 1.42857143;
                color: #333;
            }
            .PHM-MetaDataProperty-SubType {
                box-sizing: border-box;
                font-size: 14px;
                line-height: 1.42857143;
                color: #333;
                margin-left: 30px;
            }
            .resultsDivFooter {
                margin: 20px 0;
                height: 1px;
                border-bottom: 1px solid #dcdcdc;
                width: 105%;
                float: left;
            }
            .citation-right-find-descriptor-wrapper {
                font-family: 'J&J CircularTT-Medium';
                padding-bottom: 5px;
                display: inline-flex;
                flex-direction: row;
            }
            .citation-right-find-descriptor-header-wrapper {
                background: #5e8ebd;
                color: white;
                padding-left: 8px !important;
                padding: 4px;
                text-align: left;
                border-radius: 6px;
                cursor: pointer;
                width: 325px;
            }
            .order-info-icn {
                height: 17px;
                width: 17px;
                border-radius: 14px;
                margin-right: 5px;
                float: right;
                margin-top: 2px;
                vertical-align: middle;
            }
            .citation-info-icon-wrapper {
                height: 17px;
                width: 17px;
                float: left;
                margin-left: -20px;
                margin-top: 1px;
            }
            .document-descriptor-header-wrapper{
                margin: 5px 0px 3px;
                color: white;
                background: linear-gradient(0deg, rgb(2, 0, 36) 0%, rgba(227, 152, 67, 0.99) 0%, rgba(243, 144, 29, 0.73) 100%);
                padding: 6px;
                border-radius: 6px;
                cursor: pointer;
                font-weight: bold;
                text-shadow: rgb(204 132 24) 2px 2px;
              }
              .document-descriptor-wrapper{
                font-family: 'J&J CircularTT-Medium';
                padding-bottom: 5px;
              }.document-descriptor-img{
                width: 17px;
                height: 17px;
                padding-bottom: 3px;
                padding-left: 2px;
                transition: all 0.3s ease 0s;
                transform: rotate(0deg);
              }
              .document-descriptor-img-rotate{
                width: 17px;
                height: 17px;
                padding-bottom: 3px;
                padding-left: 2px;
                transition: all 0.3s ease 0s;
                transform: rotate(90deg);
              }
              .document-descriptor-text-wrapper{
                padding: 14px;
                  background: linear-gradient(0deg, rgb(2, 0, 36) 0%, rgba(252, 221, 1, 0.26) 0%, rgba(252, 222, 11, 0.29) 100%);
                  font-size: 13.5px;
                  border-radius: 0px 0px 10px 10px;
                  display: block;
              }
              .keyTakeaways-info-icn{
                height: 22px;
                width:22px;
                border-radius: 14px;
                margin-right: 5px;
                float: right;
              }`;
  },
  getNotebookPdfCSS() {
    return `body {
                margin: 0;
                font-family: var(--bs-font-sans-serif);
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                -webkit-text-size-adjust: 100%;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                padding: 30px 35px 0px 25px;
            }

            .App {
                text-align: center;
                font-size: 16px;
            }

            .bannerImage {
                background: url(${LocationOrigin}${banner}) no-repeat 0 0;
                padding: 25px 0 45px;
                position: relative;
                background-size: 100% 100%;
            }

            img,
            svg {
                vertical-align: middle;
            }

            .download-results-page-wrapper {
                width: 100%;
                font-family: 'J&J CircularTT-Medium';
                color: #333;
            }

            .download-results-page-contents-wrapper {
                font-size: 14px;
                text-align: left;
                padding: 1% 3%;
            }

            .results-div-docTitle-wrapper {
                flex: 1 1;
                padding-right: 1%;
            }

            .resultDetailsDiv {
                width: 95%;
                display: flex;
                flex-direction: column;
            }

            .results-title-action-outer-wrapper {
                display: flex;
                flex-direction: row;
                padding-top: 0.5%;
            }

            .resultDetailsDiv a {
                color: #0074B0;
                font-weight: bold;
                font-size: 14px;
                display: inline;
                word-break: break-word;
                cursor: pointer;
                text-decoration: none;
            }

            .citation-results-meta-property-wrapper {
                padding-top: 1%;
            }

            .PHM-MetaDataProperty {
                box-sizing: border-box;
                font-size: 14px;
                line-height: 1.42857143;
                color: #333;
            }
            .PHM-MetaDataProperty-SubType {
                box-sizing: border-box;
                font-size: 14px;
                line-height: 1.42857143;
                color: #333;
                margin-left: 30px;
            }
            .resultsDivFooter {
                margin: 20px 0;
                height: 1px;
                border-bottom: 1px solid #dcdcdc;
                width: 105%;
                float: left;
            }
            .citation-right-find-descriptor-wrapper {
                font-family: 'J&J CircularTT-Medium';
                padding-bottom: 5px;
                display: inline-flex;
                flex-direction: row;
            }
            .citation-right-find-descriptor-header-wrapper {
                background: #5e8ebd;
                color: white;
                padding-left: 8px !important;
                padding: 4px;
                text-align: left;
                border-radius: 6px;
                cursor: pointer;
                width: 325px;
            }
            .order-info-icn {
                height: 17px;
                width: 17px;
                border-radius: 14px;
                margin-right: 5px;
                float: right;
                margin-top: 2px;
                vertical-align: middle;
            }
            .citation-info-icon-wrapper {
                height: 17px;
                width: 17px;
                float: left;
                margin-left: -20px;
                margin-top: 1px;
            }
            .document-descriptor-header-wrapper{
                margin: 5px 0px 3px;
                color: white;
                background: linear-gradient(0deg, rgb(2, 0, 36) 0%, rgba(227, 152, 67, 0.99) 0%, rgba(243, 144, 29, 0.73) 100%);
                padding: 6px;
                border-radius: 6px;
                cursor: pointer;
                font-weight: bold;
                text-shadow: rgb(204 132 24) 2px 2px;
              }
              .document-descriptor-wrapper{
                font-family: 'J&J CircularTT-Medium';
                padding-bottom: 5px;
              }.document-descriptor-img{
                width: 17px;
                height: 17px;
                padding-bottom: 3px;
                padding-left: 2px;
                transition: all 0.3s ease 0s;
                transform: rotate(0deg);
              }
              .document-descriptor-img-rotate{
                width: 17px;
                height: 17px;
                padding-bottom: 3px;
                padding-left: 2px;
                transition: all 0.3s ease 0s;
                transform: rotate(90deg);
              }
              .document-descriptor-text-wrapper{
                padding: 14px;
                  background: linear-gradient(0deg, rgb(2, 0, 36) 0%, rgba(252, 221, 1, 0.26) 0%, rgba(252, 222, 11, 0.29) 100%);
                  font-size: 13.5px;
                  border-radius: 0px 0px 10px 10px;
                  display: block;
              }
              .keyTakeaways-info-icn{
                height: 22px;
                width:22px;
                border-radius: 14px;
                margin-right: 5px;
                float: right;
              }
              .message-text>table {
                margin-top: 10px;
                font-size: 0.9em;
                /* min-width: 400px; */
                /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
              }
              .message-text>table>thead tr {
                color: #ffffff;
                text-align: center;
                font-weight: bold;
              }
              .message-text>table,
              .message-text>table th,
              .message-text>table td {
                border: 1px solid #A9A9A9;
                border-collapse: collapse;
              }
              .message-text>table th,
              .message-text>table td {
                padding: 10px 10px;
                color: #000000;
              }
              .message-text>table th {
                text-align: center;
              }
              .message-text
              {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                overflow: auto;
              }
              .message-text>table{
                margin-left: 0px;
              }
              .greenlight-table-wrapper table{
                border-style: solid;
                border-color: black;
              }
              .greenlight-table-wrapper table tbody tr td,.greenlight-table-wrapper table thead tr th {
                border-right-width: 1px;
                border-top-width: 1px;
                border-bottom-width: 0px;
                border-left-width: 0px;
                border-style: solid;
                border-color: black;
              }
             `;
  },
  getDocumentPreviewKeywordColorCode() {
    return [
      "#ff7f50",
      "#b0e0e6",
      "#dda0dd",
      "#ffdab9",
      "#c1ffc1",
      "#00bfff",
      "#ffd700",
      "#adff2f",
      "#e9967a",
      "#f08080",
      "#32cd32",
      "#ffb6c1",
      "#ffa07a",
      "#87cefa",
      "#66cdaa",
      "#ffa500",
      "#da70d6",
      "#eee8aa",
      "#9aff9a",
      "#afeeee",
      "#db7093",
      "#f4a460",
      "#ee82ee",
      "#ffff00",
      "#9acd32",
    ];
  },

  getExcludeKeywords() {
    return [
      "a",
      "am",
      "do",
      "he",
      "her",
      "hers",
      "him",
      "his",
      "his",
      "i",
      "is",
      "it",
      "its",
      "me",
      "mine",
      "much",
      "my",
      "our",
      "ours",
      "she",
      "their",
      "them",
      "they",
      "was",
      "what",
      "why",
      "will ",
      "you",
      "your",
      "yours",
      "in",
      "on",
      "-",
    ];
  },
  getSelectedYearForRecentlyAddedUpdated(appliedFilter) {
    if (appliedFilter === "All") return { min: 0, max: 6 };
    else if (appliedFilter === "Earlier than Yesterday")
      return { min: 0, max: 5 };
    else if (appliedFilter === "Earlier than One Week Ago")
      return { min: 0, max: 4 };
    else if (appliedFilter === "Earlier than One Month Ago")
      return { min: 0, max: 3 };
    else if (appliedFilter === "Earlier than Six Months Ago")
      return { min: 0, max: 2 };
    else if (appliedFilter === "Earlier than One Year Ago")
      return { min: 0, max: 1 };
    else if (appliedFilter === "One Year Ago or sooner")
      return { min: 1, max: 6 };
    else if (appliedFilter === "One Year Ago to Yesterday")
      return { min: 1, max: 5 };
    else if (appliedFilter === "One Year Ago to One Week Ago")
      return { min: 1, max: 4 };
    else if (appliedFilter === "One Year Ago to One Month Ago")
      return { min: 1, max: 3 };
    else if (appliedFilter === "One Year Ago - Six Months Ago")
      return { min: 1, max: 2 };
    else if (appliedFilter === "Six Months Ago or sooner")
      return { min: 2, max: 6 };
    else if (appliedFilter === "Six Months Ago to Yesterday")
      return { min: 2, max: 5 };
    else if (appliedFilter === "Six Months Ago to One Week Ago")
      return { min: 2, max: 4 };
    else if (appliedFilter === "Six Months Ago - One Month Ago")
      return { min: 2, max: 3 };
    else if (appliedFilter === "One Month Ago or sooner")
      return { min: 3, max: 6 };
    else if (appliedFilter === "One Month Ago to Yesterday")
      return { min: 3, max: 5 };
    else if (appliedFilter === "One Month Ago - One Week Ago")
      return { min: 3, max: 4 };
    else if (appliedFilter === "One Week Ago or sooner")
      return { min: 4, max: 6 };
    else if (appliedFilter === "One Week Ago - Yesterday")
      return { min: 4, max: 5 };
    else if (appliedFilter === "Yesterday") return { min: 5, max: 6 };
    else return { min: 0, max: 6 };
  },
  getSelectedYearDisplay(yearSelection) {
    if (yearSelection.min === 0 && yearSelection.max === 6) return "All";
    else if (yearSelection.min === 0 && yearSelection.max === 5)
      return "Earlier than Yesterday";
    else if (yearSelection.min === 0 && yearSelection.max === 4)
      return "Earlier than One Week Ago";
    else if (yearSelection.min === 0 && yearSelection.max === 3)
      return "Earlier than One Month Ago";
    else if (yearSelection.min === 0 && yearSelection.max === 2)
      return "Earlier than Six Months Ago";
    else if (yearSelection.min === 0 && yearSelection.max === 1)
      return "Earlier than One Year Ago";
    else if (yearSelection.min === 1 && yearSelection.max === 6)
      return "One Year Ago or sooner";
    else if (yearSelection.min === 1 && yearSelection.max === 5)
      return "One Year Ago to Yesterday";
    else if (yearSelection.min === 1 && yearSelection.max === 4)
      return "One Year Ago to One Week Ago";
    else if (yearSelection.min === 1 && yearSelection.max === 3)
      return "One Year Ago to One Month Ago";
    else if (yearSelection.min === 1 && yearSelection.max === 2)
      return "One Year Ago - Six Months Ago";
    else if (yearSelection.min === 2 && yearSelection.max === 6)
      return "Six Months Ago or sooner";
    else if (yearSelection.min === 2 && yearSelection.max === 5)
      return "Six Months Ago to Yesterday";
    else if (yearSelection.min === 2 && yearSelection.max === 4)
      return "Six Months Ago to One Week Ago";
    else if (yearSelection.min === 2 && yearSelection.max === 3)
      return "Six Months Ago - One Month Ago";
    else if (yearSelection.min === 3 && yearSelection.max === 6)
      return "One Month Ago or sooner";
    else if (yearSelection.min === 3 && yearSelection.max === 5)
      return "One Month Ago to Yesterday";
    else if (yearSelection.min === 3 && yearSelection.max === 4)
      return "One Month Ago - One Week Ago";
    else if (yearSelection.min === 4 && yearSelection.max === 6)
      return "One Week Ago or sooner";
    else if (yearSelection.min === 4 && yearSelection.max === 5)
      return "One Week Ago - Yesterday";
    else if (yearSelection.min === 5 && yearSelection.max === 6)
      return "Yesterday";
    else return "";
  },
};
export const recentlyAddedUpdated = [
  {
    key: "Recently Added_Updated",
    doc_count: 14529,
  },
  //, {
  //     "key": "Recently Added_Updated/Earlier than One Year Ago",
  //     "doc_count": 9587
  // },
  // {
  //     "key": "Recently Added_Updated/One Year Ago - Six Months Ago",
  //     "doc_count": 7859
  // },
  // {
  //     "key": "Recently Added_Updated/Six Months Ago - One Month Ago",
  //     "doc_count": 6589
  // },
  // {
  //     "key": "Recently Added_Updated/One Month Ago - One Week Ago",
  //     "doc_count": 5478
  // },
  // {
  //     "key": "Recently Added_Updated/One Week Ago - Yesterday",
  //     "doc_count": 3258
  // },
  // {
  //     "key": "Recently Added_Updated/Yesterday",
  //     "doc_count": 256
  // }
];

export const DocumentPreviewOrderBy = {
  Hits: "Hits",
  Pages: "Pages",
};
export const DocumentPreviewSearch = {
  Search: "Search",
  Collapse: "Collapse",
};

export const PubMedFilters = {
  "Drugs - JNJ": 1,
  "Drugs - Competitor": 2,
  "Drug Dosing And Administration": 3,
  "Disease States": 4,
  "Real World Evidence": 5,
  Language: 6,
};

export const CSEPSelectedProduct = [
  "cinnarizine",
  "domperidone",
  "flunarizine hydrochloride",
  "galantamine",
  "galantamine HBr",
  "haloperidol",
  "haloperidol decanoate",
  "hydromorphone hydrochloride",
  "itraconazole",
  "levofloxacin",
  "mebendazole",
  "methylphenidate HCl",
  "oxybutynin chloride ER",
  "pancrelipase",
  "pentosan polysulfate sodium",
  "rabeprazole ER",
  "rabeprazole sodium",
  "risperidone",
  "risperidone Consta",
  "risperidone M - TAB",
  "topiramate",
  "tramadol hydrochloride",
  "tramadol hydrochloride - acetaminophen",
];

export const CSEPDocumentType = [
  "Clinical Safety Documents",
  "ClinicalTrials.gov Records",
  "Communication Materials",
  "Competitive Intelligence",
  "Copy Review Pieces",
  "Labeling Documents - Core, EU, US",
  "Labeling Documents - Rest of World",
  "Medical Education Event Materials",
  "Medical Literature",
  "Medical Literature Compilations",
  "Scientific Response Documents",
  "Slide Decks",
  "Submission-Related Documents",
  "Training, Congress and Internal Materials",
];
export const filterMapping = {
  "ACClinicalTrials/Clinical Trials/": "Clinical Trials - JNJ/",
  "ACCompetitorDrugs/Competitor Drugs/": "Drugs - Competitor/",
  "ACCongressesPath/": "Congress Names/",
  "ACJNJDrugs/JNJ Drugs/": "Drugs - JNJ/",
  "ACTherapeuticAreas/Therapeutic Areas/": "Disease States/",
  "ContentSource/": "Content Source/",
  "mpBoostRegions/": "Regions/",
  "mpClinicalTrials/": "Clinical Trials - JNJ/",
  "mpComponentName/": "Document Subtypes/",
  "mpCongressPublicationYear/": "Publication Year/",
  "mpDocumentType/": "Document Type/",
  "mpLanguage/": "Language/",
  "mpPhaseOfStudy/": "Phase of Study/",
  "mpSubmissionRelated/": "Document Subtypes/",
  "semCompetitorDrugs/": "Drugs - Competitor/",
  "semDrugDosingAdmin/": "Drug Dosing And Administration/",
  "semJNJDrugs/": "Drugs - JNJ/",
  "semRWE/": "Real World Evidence/",
  "semTAs/": "Disease States/",
  "ACDocType/": "Copy Review Piece Key Fields/",
  "/1Scientific Responses (All but PH TA)": "/Scientific Responses",
  "/Scientific Responses (All but PH TA)": "/Scientific Responses",
  "/Scientific Responses/Standard Response Documents": "/Scientific Responses",
  "Content Source/SharePoint Online - 2WIRE": "Content Source/2WIRE",
  "/AD26.COV2.S": "/Ad26.COV2.S",
  //"/Labeling Documents":"/Labeling Documents - Core, EU, US",
  "Disease States/CNS/CNS Diseases": "Disease States/CNS Diseases",
  "Drugs - JNJ/Cardiovascular Drugs/Pulmonary Hypertension Drugs":
    "Drugs - JNJ/Pulmonary Hypertension Drugs",
  "Drugs - JNJ/Hematology Drugs/ibrutinib":
    "Drugs - JNJ/Oncology Drugs/Hematology Drugs/ibrutinib",
  "/Solid Tumor Drugs/erdafitinib":
    "/Solid Tumor Drugs/Tyrosine Kinase Inhibitors/erdafitinib",
  "Content Source/Medical Literature Publications Above 2012":
    "Document Type/Medical Literature",
  "Content Source/Medical Literature Publications Till 2012":
    "Document Type/Medical Literature",
  "Content Source/Medical Literature Abstract-Presentation-Posters":
    "Document Type/Medical Literature",
  "Content Source/ERIS Labeling (RIMdocs)":
    "Document Type/Labeling Documents - Core, EU, US;Document Type/Labeling Documents - Rest of World",
  "mpdocumenttype/": "Document Type/",
  "Document Type/Training Materials":
    "Document Type/Training, Congress and Internal Materials",
  "/Psychiatry Drugs/esketamine":
    "/Psychiatry Drugs/Antidepressant Drugs/esketamine",
  "/Psychiatry Drugs/PP1M": "/Psychiatry Drugs/Antipsychotic Drugs/PP1M",
  "/Psychiatry Drugs/PP3M": "/Psychiatry Drugs/Antipsychotic Drugs/PP3M",
  "/Antiviral Drugs Respiratory/Anti-Influenza Virus Drugs":
    "/Antiviral Drugs Respiratory/Antiviral Drugs Influenza",
  "/Integrated Read-Outs (IROs) - iMedical Insights": "/iMedical Insights",
  "/Oncology/": "/Oncology Diseases/",
  "/Q&A": "/Q%26A",
  ">": ";",
};
export const snippetLongString = (snippet, count = 100) => {
  if (snippet.length > count) {
    let text = snippet.substring(0, count); //cuts to 70
    text = text.substring(0, 100); //cuts from last space (to avoid cutting the middle of a word)
    return text;
  }
  return snippet;
};
export const nlpDocumnetTypeMapping = {
  "Labeling Documents": "Document Type/Labeling Documents - Core, EU, US;Document Type/Labeling Documents - Rest of World",
  "Scientific Response Documents": "Document Type/Scientific Response Documents",
  "Slide Decks": "Document Type/Slide Decks",
  "Medical Literature": "Document Type/Medical Literature/Posters"
};
// export const assistantDocumnetTypeMapping = {
//   "Labels": "Document Type/Labeling Documents - Core, EU, US",
//   "ScientificReponses": "Document Type/Scientific Response Documents/Scientific Responses",
//   "SlideDecks": "Document Type/Slide Decks",
// };
export const assistantDocumnetTypeMappingForOther = {
  "Labels": "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)",
  "ScientificReponses": "Document Type/Scientific Response Documents/Scientific Responses",
};
export const assistantDocumnetTypeMappingForMICC = {
  "HCP": "Target Audience/HCP",
  "Consumer": "Target Audience/Consumer",
};
export const assistantDocumnetTypeCountriesFilter = "Countries/United States";
export const assistantDocumnetTypeSRFilter = "Document Type/Scientific Response Documents/Scientific Responses";
export const doaminMapping = {
  "ERIS": "RIMdocs",
  "ERIS_LL": "RIMdocs_LL",
  "TrialCatalog": "CTGov",
  "Navigator": "SKM",
  "SFM": "SFM",
  "SRAP": "SRAP",
  "iMR": "iMR",
  "SRD": "SRD",
  "CMIR": "CMIR",
  "MIAs": "MIAs",
  "SR": "SR",
  "MLR": "MLR",
  "PERC": "PERC",
  "IROs": "IROs",
  "domain": "domain",
  "GCSR": "GCSR",
  "2WIRE": "2WIRE",
}

export const greetings = [
  "'Allo",
  "'Allo 'Allo",
  "Aye oop",
  "Ay up",
  "Ahoy",
  "G'day",
  "Greetings",
  "Hello",
  "Hey there",
  "Hey",
  "Hi there",
  "Hi",
  "Hiya",
  "How are things",
  "How are ya",
  "How ya doin'",
  "How's it goin'",
  "How's it going",
  "How's life",
  "Howdy",
  "Sup",
  "What's new",
  "What's up",
  "Yo",
  "Good morning",
  "Good afternoon",
  "Good evening",
  "It’s nice to meet you",
  "It’s a pleasure to meet you",
  "What’s up",
  "Nice to meet you",
  "Hi, how’s it going?",
  "Hey, what’s up?"
]
export const DomainMapping = {
  2: "RIMdocs",
  7: "CTGov",
  8: "RIMdocs_LL",
  11: "SKM",
  19: "CMIR_LATAM",
  20: "SR",
  21: "CMIR",
  22: "SRAP",
  23: "GCSR",
  24: "SRD",
  25: "MLR",
  27: "MLR",
  28: "MLR",
  29: "SFM",
  31: "2WIRE",
  32: "2WIRE",
  33: "iMR",
  34: "iMR",
  35: "iMR_LATAM",
  36: "PERC",
  37: "IROs",
  38: "MIAs",
  39: "2WIRE",
  40: "iMR_ASPAC"
};
export const sectionAnsSeparator = 'ǂ$±@';
export const AccessGroups = ['MIKI', 'MICC'];
export const MICCGroupName = 'MICC';
export const Answers = "Answers";
export const MISEHCP = "MISEHCP";
export const MICCConsumer = "MICCConsumer";
export const AllContent = "AllContent";
export const Authoring = "Authoring";
export const LimitedUser = "LimitedUser";
export const MISEHCPFilter = "Document Type/Scientific Response Documents/Scientific Responses/HCP";
export const MICCConsumerFilter = { "Consumer": "Target Audience/Consumer" }
export const WordsMaxLength = 10000;

// export const AllContentFilter = {
//   "Labels": "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)",
//   "HCP": "Target Audience/HCP",
//   "Consumer": "Target Audience/Consumer",
// }

export const AllContentFilter = {
  "Labels": "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)",
  "Scientific Responses": "Document Type/Scientific Response Documents/Scientific Responses/HCP",
  "Slide Decks": "Document Type/Slide Decks/North America",
  "Posters": "Document Type/Medical Literature/Posters",
}

export const GenAI2AllContentFilter = {
  "Labels": "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)",
  "Scientific Responses": "Document Type/Scientific Response Documents/Scientific Responses/HCP",
  "Slide Decks": "Document Type/Slide Decks/North America",
  "Posters": "Document Type/Medical Literature/Posters",
  "Clinical Study Reports": "Document Type/Submission-Related Documents/Clinical Study Reports",
  "Protocols": "Document Type/Submission-Related Documents/Protocols",
}

export const ExcludeWWIDForAnalyticalLogs = [643044437, 643041767, 643051747];

export const getCurrentDate = () => {
  var currentdate = new Date();
  var curMonth = currentdate.getUTCMonth() + 1;
  if (curMonth < 10) {
    curMonth = "0" + curMonth;
  }
  var curDate = currentdate.getUTCDate();
  if (curDate < 10) {
    curDate = "0" + curDate;
  }
  var curHour = currentdate.getUTCHours();
  if (curHour < 10) {
    curHour = "0" + curHour;
  }
  var curMin = currentdate.getUTCMinutes();
  if (curMin < 10) {
    curMin = "0" + curMin;
  }
  var curSec = currentdate.getUTCSeconds();
  if (curSec < 10) {
    curSec = "0" + curSec;
  }
  var curMil = currentdate.getUTCMilliseconds();
  if (curMil < 10) {
    curMil = "00" + curMil;
  }
  else if (curMil < 100) {
    curMil = "0" + curMil;
  }
  var datetime =
    currentdate.getFullYear() +
    "-" +
    curMonth +
    "-" +
    curDate +
    "T" +
    curHour +
    ":" +
    curMin +
    ":" +
    curSec +
    "." +
    curMil +
    "Z";
  return datetime;
}
export const multiFilesAccept = ".doc,.docx,.ppt,.pptx,.pdf,.jpeg,.jpg,.png,.gif";
export const multiFilesOnlyImageAccept = ".jpeg,.jpg,.png,.gif";
//export const multiFilesAccept = ".doc,.docx,.ppt,.pptx,.pdf";

export const DocmentTypeForProtocolNo = ["Clinical Study Reports", "Protocols", "Posters"]
export const DropdownGraphTypes = {
  scatterPlot: "Scatter Plot",
  lineCharts: "Line Charts",
  barChart: "Bar Chart",
  pieChart: "Pie Chart",
  bubbleChart: "Bubble Chart",
};
export const LocationOrigin = window.location.origin === 'https://predev-ims.jnj.com' ? "https://stage-ims.jnj.com" :
  window.location.origin === "http://localhost:3000"
    ? "https://stage-ims.jnj.com"
    : window.location.origin;
