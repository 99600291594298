import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import "./index.css"
import { imsLoader } from "../../assets";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import axios from 'axios';
import { LocationOrigin } from '../../utils/utilityServices';
import { Modal } from "react-bootstrap";

const GraphInProcessing = 'GraphInProcessing';
const GraphSucceeded = 'GraphSucceeded';
const GraphUnsucceeded = 'GraphUnsucceeded';
let limitString = 250;
let _locationOrigin = LocationOrigin;
//_locationOrigin = 'https://predev-ims.jnj.com';

class GenAIGraphAddMilestone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            milestoneName: '',
            userGraphInfo: props.userGraphInfo,
            userGraphRequirements: props.userGraphRequirements,
            userSelectedGraphTypes: props.userSelectedGraphTypes,
            question: props.question,
            isShowMore: false,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            isSuccessAlertMessageShow: false,
            isProcessing: false,
            spinnerMessage: '',
            requirements: []
        }
    }
    handleAddMilestoneClose = () => {
        this.props.handleClose(false)
    }
    handleMilestoneName = (e) => {
        this.setState({ milestoneName: e.target.value })
    }
    handleClickOnSaveMilestone = (e) => {
        let { question, userGraphRequirements, userSelectedGraphTypes, milestoneName, userGraphInfo } = this.state;
        let url = '';
        let query = question.trim();
        this.setState({ isProcessing: true })
        let _payload = {
            "data": query,
            "requirement": userGraphRequirements,
            "graphType": userSelectedGraphTypes,
            "graph": userGraphInfo.userGraphHTML,
            "Title": milestoneName,
            "thread_id": userGraphInfo.graphAPIResponseThreadId
        }
        url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_GRAPH}save_milestone`
        if (window.location.origin === 'https://predev-ims.jnj.com') {
            url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_GRAPH}save_milestone`
        }
        else if (window.location.origin === 'http://localhost:3000') {
            url = `https://predev-ims.jnj.com/api/poctest/save_milestone`;
        }

        var config;
        config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: _payload
        }
        axios(config)
            .then(async (resp) => {
                let response = resp.data;
                // response = raw;
                if (response && response.status_code && response.status_code === 200) {
                    this.props.indexAddMilestoneData(_payload);
                    this.successAlertMessage(`Milestone saved successfully.`);
                } else {
                    this.errorAlertMessage("Sorry, something went wrong.");
                }
            })
            .catch(async (error) => {
                this.errorAlertMessage("Sorry, something went wrong.");
            })
            .finally(async () => {
                this.setState({
                    isProcessing: false,
                    spinnerMessage: '',
                })
            });
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true,
            isSuccessAlertMessageShow: true,
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true,
            isSuccessAlertMessageShow: false,
        })
    }
    closeAlertMessage = () => {
        let { isSuccessAlertMessageShow } = this.state;
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false,
            showPreview: false
        }, () => {
            if (isSuccessAlertMessageShow) {
                this.props.handleClose(false);
            }
        })
    }
    snippetLongString = (snippet) => {
        if (snippet.length > limitString) {
            let text = "";
            let spanIndex = snippet.indexOf('<span class=');
            if (spanIndex !== -1) {
                text = snippet.substring(spanIndex, (spanIndex + limitString))//cuts to spanIndex+150
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            } else {
                text = snippet.substring(0, limitString)//cuts to 70
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            }
            //text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }

    render() {
        let { milestoneName, userGraphInfo, question, isShowMore, spinnerMessage } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        let text = "";
        if (!isShowMore) {
            text = this.snippetLongString(question);
        }
        else {
            text = question;
        }
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-graph-add-milestone-container">
                <div className="gen-ai-graph-add-milestone-container-top">
                    <span>&nbsp;</span>
                    <p className="gen-ai-graph-add-milestone-container-top-title">
                        <svg viewBox="0 0 24 24" height="24px" width="24px" data-prefix="far" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M16.577 8.52566L6.65811 5.21937C6.3578 5.11927 6.20764 5.06921 6.10382 5.14405C6 5.21888 6 5.37716 6 5.69371V13L16.577 9.47434C17.1653 9.27824 17.4594 9.18019 17.4594 9C17.4594 8.81981 17.1653 8.72176 16.577 8.52566Z" fill="#222222"></path>
                                <path d="M6 13V5.69371C6 5.37716 6 5.21888 6.10382 5.14405C6.20764 5.06921 6.3578 5.11927 6.65811 5.21937L16.577 8.52566C17.1653 8.72176 17.4594 8.81981 17.4594 9C17.4594 9.18019 17.1653 9.27824 16.577 9.47434L6 13ZM6 13V18V19" stroke="#222222" stroke-width="2" stroke-linecap="round"></path>
                            </g>
                        </svg>
                        Add Milestone</p>
                    <div onClick={e => { this.handleAddMilestoneClose() }} style={{ cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="30px" height="30px">
                            <path fill="currentColor" d="M 64 6 C 48.5 6 33.9 12 23 23 C 12 33.9 6 48.5 6 64 C 6 79.5 12 94.1 23 105 C 34 116 48.5 122 64 122 C 79.5 122 94.1 116 105 105 C 116 94 122 79.5 122 64 C 122 48.5 116 33.9 105 23 C 94.1 12 79.5 6 64 6 z M 64 12 C 77.9 12 90.900781 17.399219 100.80078 27.199219 C 110.70078 36.999219 116 50.1 116 64 C 116 77.9 110.60078 90.900781 100.80078 100.80078 C 90.900781 110.60078 77.9 116 64 116 C 50.1 116 37.099219 110.60078 27.199219 100.80078 C 17.299219 91.000781 12 77.9 12 64 C 12 50.1 17.399219 37.099219 27.199219 27.199219 C 36.999219 17.299219 50.1 12 64 12 z M 50.5625 47.5 C 49.8 47.5 49.05 47.800391 48.5 48.400391 C 47.3 49.600391 47.3 51.499609 48.5 52.599609 L 59.800781 64 L 48.400391 75.300781 C 47.200391 76.500781 47.200391 78.4 48.400391 79.5 C 49.000391 80.1 49.8 80.400391 50.5 80.400391 C 51.2 80.400391 51.999609 80.1 52.599609 79.5 L 64 68.199219 L 75.300781 79.5 C 75.900781 80.1 76.700391 80.400391 77.400391 80.400391 C 78.100391 80.400391 78.9 80.1 79.5 79.5 C 80.7 78.3 80.7 76.400781 79.5 75.300781 L 68.199219 64 L 79.5 52.699219 C 80.7 51.499219 80.699609 49.600391 79.599609 48.400391 C 78.399609 47.200391 76.500391 47.200391 75.400391 48.400391 L 64 59.800781 L 52.699219 48.400391 C 52.099219 47.800391 51.325 47.5 50.5625 47.5 z" />
                        </svg>
                    </div>
                </div>
                <div className="gen-ai-graph-add-milestone-container-bottom">
                    <div className="gen-ai-graph-add-milestone-container-bottom-header">
                        <div className="gen-ai-graph-add-milestone-container-bottom-right">
                            <div className="gen-ai-graph-add-milestone-container-bottom-right-controle">
                                <div className="gen-ai-graph-add-milestone-container-bottom-right-controle-label">
                                    <svg viewBox="0 0 24 24" height="24px" width="24px" data-prefix="far" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path d="M16.577 8.52566L6.65811 5.21937C6.3578 5.11927 6.20764 5.06921 6.10382 5.14405C6 5.21888 6 5.37716 6 5.69371V13L16.577 9.47434C17.1653 9.27824 17.4594 9.18019 17.4594 9C17.4594 8.81981 17.1653 8.72176 16.577 8.52566Z" fill="#222222"></path>
                                            <path d="M6 13V5.69371C6 5.37716 6 5.21888 6.10382 5.14405C6.20764 5.06921 6.3578 5.11927 6.65811 5.21937L16.577 8.52566C17.1653 8.72176 17.4594 8.81981 17.4594 9C17.4594 9.18019 17.1653 9.27824 16.577 9.47434L6 13ZM6 13V18V19" stroke="#222222" stroke-width="2" stroke-linecap="round"></path>
                                        </g>
                                    </svg>
                                    Milestone Name
                                </div>
                                <div className="gen-ai-graph-add-milestone-container-bottom-right-controle-text">
                                    <input type="text" placeholder="Enter Milestone Name" value={milestoneName} onChange={(e) => this.handleMilestoneName(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="gen-ai-graph-add-milestone-container-bottom-left" style={{ overflow: "auto" }}>
                            {(userGraphInfo && userGraphInfo.graphAPIStatus === GraphSucceeded) &&
                                <iframe srcDoc={userGraphInfo.userGraphHTML}
                                    ref={ref => { this.userGraphiframe = ref }}
                                    // width="100%"
                                    // height="100%"
                                    // onLoad={this.resizeIframe}
                                    frameborder="0"
                                    scrolling="auto"
                                />}
                            <div className="gen-ai-graph-add-milestone-container-bottom-question">
                                {text}
                                {question.length > limitString &&
                                    <div className="gen-ai-graph-view-milestone-container-bottom-question-message-show-more-less">{isShowMore ?
                                        <span onClick={() => { this.setState({ isShowMore: false }) }}>Show Less</span> :
                                        <span onClick={() => { this.setState({ isShowMore: true }) }}>Show More</span>}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="gen-ai-graph-add-milestone-container-bottom-footer">
                        <button type="button" onClick={this.handleClickOnSaveMilestone} disabled={!milestoneName} style={milestoneName ? { backgroundColor: "#0074B0" } : { backgroundColor: "#cecece" }} className="gen-ai-graph-add-milestone-container-bottom-footer-save">
                            Save Milestone
                        </button>
                    </div>
                </div>
                {this.state.isProcessing &&
                    <Modal
                        show={this.state.isProcessing}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="savePopUp"
                        className="z-index-milestone"
                        backdropClassName="z-index-milestone-backdrop"
                        scrollable={true}>
                        <div style={{ padding: "0 5%" }}>
                            <div style={{ fontSize: "14px", textAlign: "center" }}>
                                <Modal.Body>
                                    <img src={imsLoader} alt="" width={100} height={100} />
                                    <div className="saveModal-heading" style={{ color: "#0074B0" }}>{spinnerMessage ? spinnerMessage : `Please wait...`} </div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>}
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </div>)
        }
    }

}
export default withRouter(GenAIGraphAddMilestone);