import { useEffect, useState } from "react";
export default function ZooButton(props) {
    const {
        customColor,
        text,
        action,
        isDisabled
    } = props;
    useEffect(() => {
        setColor(customColor); 
    }, [customColor]) 
    const [color, setColor] = useState(customColor);
    const [bgColor, setBgColor] = useState("#fff") ;
    const [fontColor ,setFontColor] = useState(customColor);
    const styles = {
        color: color,
        border: `1px solid ${customColor}`,
        background: bgColor,
        minWidth: "60px",
        borderRadius: "5px",
        cursor:isDisabled?"none":"pointer",
        fontWeight:"bold",
        fontSize:'larger'
    }
    const resetStyle = {
        color: color,
        border: `1px solid ${customColor}`,
        background: bgColor,
        minWidth: "20px",
        minHeight: "50px",
        borderRadius: "50%",
        cursor:isDisabled?"none":"pointer",
    }
    return (
        <button style={styles} onClick={(e) => action()} disabled={isDisabled} onMouseEnter={() => { 
            if(!isDisabled){
                setColor('#fff'); 
                setBgColor(customColor);
                setFontColor('#FFFF')
            }}} onMouseLeave={() => { 
                if(!isDisabled){
                setColor(customColor); 
                setBgColor('#fff');
                setFontColor(customColor)
              }}}>
                {(props.text === "Zoom_In") && <svg width="27px" height="27px" viewBox="0 0 24 24" fill="none" 
                xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 11.381 14.4415 12.6296 13.5355 13.5355C12.6296 14.4415 11.381 15 10 15C7.23858 15 5 12.7614 5 10ZM10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C11.5719 17 13.0239 16.481 14.1921 15.6063L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L15.6063 14.1921C16.481 13.0239 17 11.5719 17 10C17 6.13401 13.866 3 10 3ZM11 8C11 7.44772 10.5523 7 10 7C9.44772 7 9 7.44772 9 8V9H8C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11H9V12C9 12.5523 9.44772 13 10 13C10.5523 13 11 12.5523 11 12V11H12C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9H11V8Z" fill={`${fontColor}`}>
                </path> </g></svg>}
                {(props.text === "Zoom_Out") &&<svg width="27px" height="27px" viewBox="0 0 24 24" fill="none" 
                xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 11.381 14.4415 12.6296 13.5355 13.5355C12.6296 14.4415 11.381 15 10 15C7.23858 15 5 12.7614 5 10ZM10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C11.5719 17 13.0239 16.481 14.1921 15.6063L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L15.6063 14.1921C16.481 13.0239 17 11.5719 17 10C17 6.13401 13.866 3 10 3ZM8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11H12C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9H8Z" fill={`${fontColor}`}></path> </g>
                </svg>}
                {(props.text === "Reset") && <svg width="27px" height="27px" viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="none" stroke={`${fontColor}`} stroke-width="2" d="M20,8 C18.5974037,5.04031171 15.536972,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 L12,21 C16.9705627,21 21,16.9705627 21,12 M21,3 L21,9 L15,9">
                    </path> </g></svg>}
              </button>
    )
}