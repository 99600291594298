import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { popCancelBttn, imsLoader } from "../../assets";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import sampleAudio from '../../assets/SampleAudioTest.mp3'
import Button from "../Button";
import { Box } from "@mui/material";
import ApiServices from "../../utils/apiServices";
const ExportPopUp = (props) =>{
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageShowModal,setAlertMessageShowModal] = useState(false);
    const [confirmMessageShowModal,setConfirmMessageShowModal] = useState(false);
    const [selectedDoc , setSelectedDoc] = useState({});
    const [URL,setUrl] = useState("");
    const filename = props.filename;

   /* const fetchRes = async() =>{
      let service = new ApiServices();
      let res1 = await service.getAllDocuments();
      let filterDoc = res1.filter((val)=>{
        return val.docid ===
      })
    }*/
    const confirmHandler = ()=>{
        //post api
        if(props.url){
        const aTag = document.createElement('a');
        aTag.href= props.url;
        aTag.setAttribute("download",`${(filename).split(".")[0]}.wav`);
        document.body.append(aTag);
        aTag.click();
        aTag.remove();
        }
        else{
          const data = new Blob([filename.doctext], { type: 'text/plain' });
           const blobUrl = window.URL.createObjectURL(data);
          const aTag = document.createElement("a");
          aTag.href = blobUrl;
          aTag.setAttribute("download",`${(filename.doctitle)}.txt`);
          document.body.append(aTag);
          aTag.click();
          aTag.remove();
        }
        props.handleClose();
  }
  const closeAlertMessage = () => {
    //props.setSelectedID();
    setAlertMessage('')
    setAlertMessageShowModal(false);

}
    return(
    <Modal
    show = {props.showModal}
    size="lg md sm xs"
    centered
    aria-labelledby="contained-modal-title-vcenter"
    dialogClassName="popup-transition"
    scrollable={false}>
        <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={e => {
                props.handleClose()}}
              />
              </div>
              </div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                  <Modal.Title>
                    <div>
                      Export File
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
                <Box sx= {{border : "1px solid #0078d4",
                  borderRadius: "1rem",
                  minHeight:"100px"
                }}>
                <div className="text" style={{ margin:"10px", fontWeight:"600"}}>Export to local disk </div>
                <div style={{margin:"5px",fontWeight:"200",fontSize:"12px"}}>{(props.url) ? <>Export Audio to your local disk</>:<>Export text to your local disk</>}</div>
                </Box>
                <div className="reading-list-button-wrapper1">
                <div style={{ width:"20%", display:"flex",justifyContent:"space-between"}}>
                    <Button text ="Save" customColor='#0074B0' action={() =>{
                    setConfirmMessageShowModal(true)}}/>
                    <Button text="Cancel" customColor="#0074B0" action={() => {
                    props.handleClose()}} />
                    </div>
                  </div>
              </Modal.Body>
              {confirmMessageShowModal && <ConfirmMessagePopUpModal content={"Do you want to download this file"} showModal={confirmMessageShowModal} handleClose={() => {setConfirmMessageShowModal(false);
            props.handleClose();}} handleConfirm={() =>confirmHandler()} />}
            {alertMessageShowModal && <AlertMessagePopUpModal color='#00c12c' content={alertMessage} 
            showModal={alertMessageShowModal} 
            handleClose={() => 
                {closeAlertMessage();
                props.handleClose();
                //props.setRefresh(true);
                //window.location.reload();
            }} />}

    </Modal>)
}
export default ExportPopUp;