import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { sanitize } from 'dompurify';
import moment from "moment";
import ConfirmMessagePopUpModal from "./../PopUp/ConfirmMessagePopUpModal"
import { ContextMenu, MenuItem, ContextMenuTrigger, showMenu, hideMenu } from "react-contextmenu";
import CollectionpopUp from "./../PopUp/CollectionpopUp"
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import { imsLoader } from '../../assets/index';
import ShareTemplateModal from "../PopUp/ShareThreadModal";
import { Modal } from "react-bootstrap";
import threadingService from "../../services/threadingService";
import trackingService from "../../services/trackingService";
import ApiServices from "../../utils/apiServices";
import {
    UserData
} from "../../auth/Authorization";

let ts = new threadingService();
class GenAILibraryThreadItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            source: props.source,
            threadCollections: props.threadCollections,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,
            collectionPopup: false,
            shareModal: false,
            spinnerMessage: '',
            isProcessing: false,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
        }
    }

    renderDisplayString = () => {
        let { display_string, output_answer, isGraphThread } = this.state.source.answeringinfo;
        let { source } = this.state;
        if (isGraphThread) {
            return (
                <Link to={`/genAISearchPOC/${source.answeringinfo.session_id}`} className="gen-ai-answering-library-thread-item-graph">
                </Link>)
        }
        if (display_string.length > 0) {

            let finalDisplayString = display_string.map((dsitem, dsindex) => {
                let display_ans = dsitem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                let temp = [];
                const div = document.createElement('div');
                let _sectionData = display_ans;
                if (_sectionData) {
                    _sectionData = _sectionData.replace(/<br>·/g, '<br>');
                }
                div.innerHTML = _sectionData;
                const spans = div.getElementsByTagName('span');
                if (spans && spans.length > 0) {
                    for (let span = 0; span < spans.length; span++) {
                        temp.push(spans[span].innerHTML);
                    }

                    display_ans = temp.join('').replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim()
                } else {
                    display_ans = display_ans.replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim()
                }
                return display_ans;
            }).join(' ')
            if (window.location.pathname.startsWith('/genAILibraryPOC') ||
                window.location.pathname.startsWith('/genAICollectionPOC')) {
                return (<Link to={`/genAISearchPOC/${source.answeringinfo.session_id}`} title={finalDisplayString} dangerouslySetInnerHTML={{ __html: sanitize(`${finalDisplayString}`) }} className="gen-ai-answering-library-thread-item-ans">
                </Link>)
            }
            else {
                return (
                    <Link to={`/genAISearch/${source.answeringinfo.session_id}`} title={finalDisplayString} dangerouslySetInnerHTML={{ __html: sanitize(`${finalDisplayString}`) }} className="gen-ai-answering-library-thread-item-ans">
                    </Link>)
            }
        } else {
            if (output_answer === "We don't know the answer. Can you try new questions.") {
                output_answer = `No Answer found. Please try a new question.`;
            }
            if (window.location.pathname.startsWith('/genAILibraryPOC') ||
                window.location.pathname.startsWith('/genAICollectionPOC')) {
                return (
                    <Link to={`/genAISearchPOC/${source.answeringinfo.session_id}`} title={output_answer} dangerouslySetInnerHTML={{ __html: sanitize(`${output_answer}`) }} className="gen-ai-answering-library-thread-item-ans">
                    </Link>)
            }
            else {
                return (
                    <Link to={`/genAISearch/${source.answeringinfo.session_id}`} title={output_answer} dangerouslySetInnerHTML={{ __html: sanitize(`${output_answer}`) }} className="gen-ai-answering-library-thread-item-ans">
                    </Link>)
            }
        }
    }
    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }

    confirmHandler = async () => {
        const { confirmMessageId } = this.state;
        this.setState(
            {

                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                await this.props.deleteAnswerThreadDataBySessionId(confirmMessageId);
                this.setState({ confirmMessageId: -1 });
            }
        );
    }


    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    handleDeleteThreadClick = (e, data) => {
        this.showConfirmMessage("Are you sure you want to delete this thread?", data._id)
    }
    handleShareThreadClick = () => {
        this.setState({ shareModal: true })
    }
    handleShareThread = () => {
        this.setState({ shareModal: true })
    }
    handleShareModalClose = () => {
        this.setState({
            shareModal: false,
        });
    }
    handleIsProcessing = (isProcessing, spinnerMessage) => {
        this.setState({ isProcessing: isProcessing, spinnerMessage: spinnerMessage });
    }
    indexShareData = (sharedToEmail) => {
        let { session_id } = this.state.source.answeringinfo;
        let shared_from_wwid = JSON.parse(localStorage.getItem('wwid'));
        let shared_from_email = JSON.parse(localStorage.getItem('username'));
        let shared_from_name = JSON.parse(localStorage.getItem('userdisplayName'));
        let answeringinfo = {
            session_id: session_id,
            sharedToEmail: sharedToEmail,
            sharedfromwwid: shared_from_wwid,
            sharedfromemail: shared_from_email,
            sharedfromname: shared_from_name,
            issharedthread: true
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'),
            localStorage.getItem('wwid'),
            localStorage.getItem('username'),
            localStorage.getItem('function'),
            localStorage.getItem('region'),
            localStorage.getItem('userproducts'),
            localStorage.getItem('doctypepref'),
            localStorage.getItem('sid'),
            localStorage.getItem('deviceType'),
            localStorage.getItem('deviceOS'),
            localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'),
            localStorage.getItem('jnjDepartment'),
            localStorage.getItem('companyname'),
            `Share Thread Answering2.0`,
            '',
            window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
            {}, {}, answeringinfo);
    }
    handleShareThreadToUser = async (sharedToEmail) => {
        let { session_id } = this.state.source.answeringinfo;

        this.setState({ isProcessing: true, spinnerMessage: "Please wait..." });
        let service = new ApiServices();
        let apiRes = await service.getUserDetailsByUserName(sharedToEmail);
        if (apiRes?.length > 0) {
            let response = await ts.shareAnswerThreadDataBySessionId(UserData.sub, session_id, apiRes[0]);
            if (response) {
                this.setState({ isProcessing: false, spinnerMessage: "", shareModal: false }, () => {
                    this.indexShareData(sharedToEmail);
                    this.successAlertMessage("The thread shared successfully.")
                });
            } else {
                this.setState({ isProcessing: false, spinnerMessage: "", shareModal: false }, () => {
                    this.errorAlertMessage("Something Went Wrong. Kindly try after some time.");
                });
            }
        } else {
            this.setState({ isProcessing: false, spinnerMessage: "", shareModal: false }, () => {
                this.errorAlertMessage("Something Went Wrong. Kindly try after some time.");
            });
        }
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false,
            showPreview: false
        })
    }
    handleAddtoCollectionClick = (e, data) => {
        this.setState({ collectionPopup: true });
    }
    handleLeftMouseClick = (e, _id) => {
        const x = e.clientX;
        const y = e.clientY;
        this.props.handleOnOpenedContextMenuClick(_id);
        showMenu({
            position: { x, y },
            id: _id
        });
    }
    onContextMenuHide = () => {
        this.props.handleOnOpenedContextMenuClick("");
    }
    componentWillReceiveProps(nextProps) {
        let { source } = this.state;
        if (nextProps && nextProps.openedContextMenuId !== source.answeringinfo.session_id
        ) {
            hideMenu({
                id: source.answeringinfo.session_id
            });
        }
        if (nextProps && nextProps.openedContextMenuId !== `CL-${source.answeringinfo.session_id}`) {
            hideMenu({
                id: `CL-${source.answeringinfo.session_id}`
            });
        }
    }
    handleCloseCollection = async (isUpdateAnything) => {
        this.setState({ collectionPopup: false });
        if (isUpdateAnything) {
            await this.props.updateThreadAndCollection();
        }
    };
    render() {
        let { source, threadCollections, spinnerMessage } = this.state;
        let { updatedatehour } = this.state.source.eventinfo;
        let { isGraphThread, issharedthread, sharedfromemail, sharedfromname } = source.answeringinfo;
        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, } = this.state;
        return (<>
            <div className="gen-ai-answering-library-thread-item">
                <div className="gen-ai-answering-library-thread-item-title">
                    {(window.location.pathname.startsWith('/genAILibraryPOC')) ?
                        <Link to={`/genAISearchPOC/${source.answeringinfo.session_id}`} title={source.answeringinfo.question} className="gen-ai-answering-library-thread-item-title-link" >
                            {source.answeringinfo.question}
                        </Link> :
                        <Link to={`/genAISearch/${source.answeringinfo.session_id}`} title={source.answeringinfo.question} className="gen-ai-answering-library-thread-item-title-link" >
                            {source.answeringinfo.question}
                        </Link>}
                </div>
                {this.renderDisplayString()}

                <div className="gen-ai-answering-library-thread-item-time-action">
                    <div className="gen-ai-answering-library-thread-item-time">
                        <div className="gen-ai-answering-library-thread-item-time-ago" title={moment(updatedatehour).format("MMM DD, YYYY hh:mm a")} data-state="closed">
                            <span>
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                </svg>
                            </span>
                            <span className="truncate">&nbsp; {moment(updatedatehour).fromNow()}</span>
                        </div>
                        {(isGraphThread) && <>&nbsp;&nbsp;
                            <div className="gen-ai-answering-library-thread-item-time-ago" title="Graph thread" data-state="closed">
                                <span>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.004 512.004" width="16px" height="16px" fill="#000000">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="4.096032"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <rect x="76.802" y="247.47" style={{ fill: "#000000" }} width="51.2" height="187.733"></rect>
                                            <rect x="178.861" y="170.329" style={{ fill: "#000000" }} width="51.883" height="265.216"></rect>
                                            <rect x="281.602" y="213.337" style={{ fill: "#000000" }} width="51.2" height="221.867"></rect>
                                            <rect x="384.002" y="128.003" style={{ fill: "#000000" }} width="51.2" height="307.2"></rect>
                                            <path d="M136.535,230.403H68.269c-4.693,0-8.533,3.84-8.533,8.533v204.8c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533v-204.8C145.069,234.243,141.229,230.403,136.535,230.403z M128.002,435.203h-51.2V247.47h51.2 V435.203z"></path>
                                            <path d="M238.935,153.945h-68.267c-4.523,0-8.192,3.669-8.192,8.192v281.6c0,4.523,3.669,8.192,8.192,8.192h68.267 c4.523,0,8.192-3.669,8.192-8.192v-281.6C247.127,157.614,243.458,153.945,238.935,153.945z M230.743,435.545h-51.883V170.329 h51.883V435.545z"></path>
                                            <path d="M341.335,196.27h-68.267c-4.693,0-8.533,3.84-8.533,8.533v238.933c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533V204.803C349.869,200.11,346.029,196.27,341.335,196.27z M332.802,435.203h-51.2V213.337h51.2 V435.203z"></path>
                                            <path d="M443.736,110.937h-68.267c-4.693,0-8.533,3.84-8.533,8.533v324.267c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533V119.47C452.269,114.777,448.429,110.937,443.736,110.937z M435.202,435.203h-51.2v-307.2h51.2 V435.203z"></path>
                                            <path d="M102.411,162.137c1.775,0,3.575-0.555,5.111-1.707l98.5-73.873l97.894,40.789c2.918,1.229,6.255,0.725,8.687-1.263 l88.465-72.38v23.1c0,4.719,3.823,8.533,8.533,8.533c4.71,0,8.533-3.814,8.533-8.533V34.137c0-4.719-3.823-8.533-8.533-8.533h-51.2 c-4.71,0-8.533,3.814-8.533,8.533s3.823,8.533,8.533,8.533h29.184l-81.826,66.961l-97.673-40.704c-2.79-1.161-5.99-0.759-8.405,1.05 l-102.4,76.8c-3.772,2.825-4.531,8.175-1.707,11.938C97.248,160.959,99.817,162.137,102.411,162.137z"></path>
                                            <path d="M509.502,471.837l-25.6-25.6c-3.337-3.337-8.73-3.337-12.066,0s-3.337,8.73,0,12.066l11.034,11.034H42.669V29.136 L53.702,40.17c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5c3.337-3.337,3.337-8.73,0-12.066l-25.6-25.6 c-3.337-3.337-8.73-3.337-12.066,0l-25.6,25.6c-3.337,3.337-3.337,8.73,0,12.066s8.73,3.337,12.066,0l11.034-11.034V477.87 c0,4.719,3.823,8.533,8.533,8.533h448.734l-11.034,11.034c-3.337,3.337-3.337,8.73,0,12.066c1.664,1.664,3.849,2.5,6.033,2.5 s4.369-0.836,6.033-2.5l25.6-25.6C512.838,480.566,512.838,475.173,509.502,471.837z"></path>
                                            <polygon style={{ fill: "#000000" }} points="110.935,247.47 110.935,418.137 76.802,418.137 76.802,435.203 128.002,435.203 128.002,247.47 "></polygon>
                                            <polygon style={{ fill: "#000000" }} points="213.677,170.329 213.677,418.478 178.861,418.478 178.861,435.545 230.743,435.545 230.743,170.329 "></polygon>
                                            <polygon style={{ fill: "#000000" }} points="315.735,213.337 315.735,418.137 281.602,418.137 281.602,435.203 332.802,435.203 332.802,213.337 "></polygon>
                                            <polygon style={{ fill: "#000000" }} points="418.135,128.003 418.135,418.137 384.002,418.137 384.002,435.203 435.202,435.203 435.202,128.003 "></polygon> </g>
                                    </svg>
                                </span>
                                <span className="truncate">&nbsp; Graph</span>
                            </div>
                        </>}
                        {(source.answeringinfo.attachedFiles && source.answeringinfo.attachedFiles.length > 0) &&
                            <>&nbsp;&nbsp;
                                <div className="gen-ai-answering-library-thread-item-time-ago" title="Multi file thread" data-state="closed">
                                    <span>
                                        <svg fill="currentColor" className="svg-inline--fa fa-clock fa-sm " version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.623 52.623" >
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M14.371,52.623c-3.535,0-6.849-1.368-9.332-3.852s-3.852-5.797-3.852-9.332c0-3.534,1.368-6.848,3.852-9.331L32.333,2.814 c3.905-3.903,9.973-3.728,14.114,0.413c4.141,4.141,4.319,10.208,0.414,14.114L21.22,42.982c-2.407,2.407-6.327,2.411-8.738,0 c-2.409-2.41-2.408-6.33,0-8.738l17.369-17.369c0.586-0.586,1.535-0.586,2.121,0c0.586,0.586,0.586,1.535,0,2.121L14.603,36.365 c-1.239,1.239-1.239,3.256,0,4.496c1.241,1.239,3.257,1.237,4.496,0L44.74,15.22c2.695-2.696,2.518-6.94-0.414-9.872 s-7.176-3.109-9.872-0.413L7.16,32.229c-1.917,1.917-2.973,4.478-2.973,7.21c0,2.733,1.056,5.294,2.973,7.211 s4.478,2.973,7.211,2.973c2.732,0,5.293-1.056,7.21-2.973l27.294-27.294c0.586-0.586,1.535-0.586,2.121,0s0.586,1.535,0,2.121 L23.702,48.771C21.219,51.254,17.905,52.623,14.371,52.623z"></path>
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="truncate">&nbsp; Multi file thread</span>
                                </div>
                            </>
                        }
                    </div>
                    <div className="gen-ai-answering-library-thread-item-action">
                        {(!window.location.pathname.startsWith('/genAICollection/')) &&
                            <>
                                {threadCollections.length > 0 &&
                                    <div>
                                        <span>
                                            <ContextMenuTrigger id={`CL-${source.answeringinfo.session_id}`} >
                                                <button title="Collections" data-testid="thread-dropdown-menu" onClick={e => this.handleLeftMouseClick(e, `CL-${source.answeringinfo.session_id}`)} type="button" className="gen-ai-answering-library-thread-item-action-menu">
                                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="grid-round-2" className="svg-inline--fa fa-grid-round-2 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="currentColor" d="M128 176a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 128a96 96 0 1 1 192 0zM128 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 384a96 96 0 1 1 192 0zM336 128a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192zm0 208a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48a96 96 0 1 1 -192 0 96 96 0 1 1 192 0z">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </ContextMenuTrigger>
                                            <ContextMenu onHide={e => this.onContextMenuHide()} id={`CL-${source.answeringinfo.session_id}`} className="gen-ai-answering-library-thread-collections-item-contextMenu">
                                                <div className="gen-ai-answering-library-thread-item-contextMenu-collections-title">
                                                    Collections
                                                </div>
                                                <div className="gen-ai-answering-library-thread-item-contextMenu-collections-item">
                                                    {threadCollections.map((tcItem, tcIndex) => {
                                                        return (<div className="gen-ai-answering-library-thread-item-contextMenu-collections-item-title">
                                                            {(window.location.pathname.startsWith('/genAILibraryPOC')) ?
                                                                <Link to={`/genAICollectionPOC/${tcItem.collection_id}`} title={`${tcIndex + 1}. ${tcItem.collection_name}`} className="gen-ai-answering-library-thread-collection-item-title-link" >
                                                                    {tcIndex + 1}. {tcItem.collection_name}
                                                                </Link>
                                                                : <Link to={`/genAICollection/${tcItem.collection_id}`} title={`${tcIndex + 1}. ${tcItem.collection_name}`} className="gen-ai-answering-library-thread-collection-item-title-link" >
                                                                    {tcIndex + 1}. {tcItem.collection_name}
                                                                </Link>}
                                                        </div>)
                                                    })}
                                                </div>
                                            </ContextMenu>
                                        </span>
                                    </div>
                                }
                                <button data-testid="add-to-collection" title={threadCollections.length > 0 ? "Update Collection" : "Add to Collection"} onClick={this.handleAddtoCollectionClick} type="button" className="gen-ai-answering-library-thread-item-action-add" data-state="closed">
                                    <div className="flex items-center leading-none justify-center gap-xs">
                                        {threadCollections.length > 0 ? <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="pen-to-square" className="svg-inline--fa fa-pen-to-square fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"></path>
                                        </svg> :
                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path>
                                            </svg>
                                        }
                                    </div>
                                </button>
                                <div>
                                    <span>
                                        <ContextMenuTrigger id={source.answeringinfo.session_id} >
                                            <button data-testid="thread-dropdown-menu" onClick={e => this.handleLeftMouseClick(e, source.answeringinfo.session_id)} type="button" className="gen-ai-answering-library-thread-item-action-menu">

                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis" className="svg-inline--fa fa-ellipsis fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="currentColor" d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path>
                                                </svg>
                                            </button>
                                        </ContextMenuTrigger>
                                        <ContextMenu id={source.answeringinfo.session_id} onHide={e => this.onContextMenuHide()} className="gen-ai-answering-library-thread-item-contextMenu">
                                            <MenuItem data={{ _id: source.answeringinfo.session_id }} onClick={this.handleAddtoCollectionClick}>
                                                {threadCollections.length > 0 ? <>
                                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="pen-to-square" className="svg-inline--fa fa-pen-to-square fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="currentColor" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"></path>
                                                    </svg>
                                                    <span>Update Collection</span></>

                                                    : <>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path></svg>
                                                        <span>Add to Collection</span>
                                                    </>
                                                }
                                            </MenuItem>
                                            <MenuItem data={{ _id: source.answeringinfo.session_id }} onClick={this.handleDeleteThreadClick}>
                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" className="svg-inline--fa fa-trash fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path></svg>
                                                <span>Delete Thread</span>
                                            </MenuItem>
                                            <MenuItem data={{ _id: source.answeringinfo.session_id }} onClick={this.handleShareThreadClick}>
                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" className="svg-inline--fa fa-trash fa-fw " role="img" fill="none" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 18.5088C1 13.1679 4.90169 8.77098 9.99995 7.84598V5.51119C9.99995 3.63887 12.1534 2.58563 13.6313 3.73514L21.9742 10.224C23.1323 11.1248 23.1324 12.8752 21.9742 13.7761L13.6314 20.2649C12.1534 21.4144 10 20.3612 10 18.4888V16.5189C7.74106 16.9525 5.9625 18.1157 4.92778 19.6838C4.33222 20.5863 3.30568 20.7735 2.55965 20.5635C1.80473 20.3511 1.00011 19.6306 1 18.5088ZM12.4034 5.31385C12.2392 5.18613 11.9999 5.30315 11.9999 5.51119V9.41672C11.9999 9.55479 11.8873 9.66637 11.7493 9.67008C8.09094 9.76836 4.97774 12.0115 3.66558 15.1656C3.46812 15.6402 3.31145 16.1354 3.19984 16.6471C3.07554 17.217 3.00713 17.8072 3.00053 18.412C3.00018 18.4442 3 18.4765 3 18.5088C3.00001 18.6437 3.18418 18.6948 3.25846 18.5822C3.27467 18.5577 3.29101 18.5332 3.30747 18.5088C3.30748 18.5088 3.30746 18.5088 3.30747 18.5088C3.63446 18.0244 4.01059 17.5765 4.42994 17.168C4.71487 16.8905 5.01975 16.6313 5.34276 16.3912C7.05882 15.1158 9.28642 14.3823 11.7496 14.3357C11.8877 14.3331 12 14.4453 12 14.5834V18.4888C12 18.6969 12.2393 18.8139 12.4035 18.6862L20.7463 12.1973C20.875 12.0973 20.875 11.9028 20.7463 11.8027L12.4034 5.31385Z" fill="#0F1729"></path>
                                                    </g>
                                                </svg>
                                                <span>Share Thread</span>
                                            </MenuItem>
                                        </ContextMenu>
                                    </span>
                                </div>
                            </>}
                    </div>
                </div>
                {(issharedthread && sharedfromemail) && <div className="gen-ai-answering-library-thread-item-time-action gen-ai-answering-library-thread-item-time-action-marginTop">
                    <div className="gen-ai-answering-library-thread-item-time">
                        <div className="gen-ai-answering-library-thread-item-time-ago" title="Shared thread" data-state="closed">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" className="svg-inline--fa fa-trash fa-fw " role="img" fill="none" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 18.5088C1 13.1679 4.90169 8.77098 9.99995 7.84598V5.51119C9.99995 3.63887 12.1534 2.58563 13.6313 3.73514L21.9742 10.224C23.1323 11.1248 23.1324 12.8752 21.9742 13.7761L13.6314 20.2649C12.1534 21.4144 10 20.3612 10 18.4888V16.5189C7.74106 16.9525 5.9625 18.1157 4.92778 19.6838C4.33222 20.5863 3.30568 20.7735 2.55965 20.5635C1.80473 20.3511 1.00011 19.6306 1 18.5088ZM12.4034 5.31385C12.2392 5.18613 11.9999 5.30315 11.9999 5.51119V9.41672C11.9999 9.55479 11.8873 9.66637 11.7493 9.67008C8.09094 9.76836 4.97774 12.0115 3.66558 15.1656C3.46812 15.6402 3.31145 16.1354 3.19984 16.6471C3.07554 17.217 3.00713 17.8072 3.00053 18.412C3.00018 18.4442 3 18.4765 3 18.5088C3.00001 18.6437 3.18418 18.6948 3.25846 18.5822C3.27467 18.5577 3.29101 18.5332 3.30747 18.5088C3.30748 18.5088 3.30746 18.5088 3.30747 18.5088C3.63446 18.0244 4.01059 17.5765 4.42994 17.168C4.71487 16.8905 5.01975 16.6313 5.34276 16.3912C7.05882 15.1158 9.28642 14.3823 11.7496 14.3357C11.8877 14.3331 12 14.4453 12 14.5834V18.4888C12 18.6969 12.2393 18.8139 12.4035 18.6862L20.7463 12.1973C20.875 12.0973 20.875 11.9028 20.7463 11.8027L12.4034 5.31385Z" fill="#0F1729"></path>
                                </g>
                            </svg>
                            <span className="truncate"><b>Shared By:</b>&nbsp;{sharedfromname}</span>
                            <span className="action-items-separate-divider"></span>
                            <span className="truncate"><b>Email Id:</b>&nbsp; {sharedfromemail}</span>
                        </div>
                    </div>
                </div>
                }
            </div>
            {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
            {(this.state.shareModal) && <ShareTemplateModal showModal={this.state.shareModal} handleShareThreadToUser={this.handleShareThreadToUser} handleIsProcessing={this.handleIsProcessing} handleClose={() => this.handleShareModalClose()} />}
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            {this.state.isProcessing &&
                <Modal
                    show={this.state.isProcessing}
                    size="lg md sm xs"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogClassName="savePopUp"
                    className="gen-ai-answering-search-spinner-popup"
                    backdropClassName="z-index-gen-ai-answering-search-spinner-popup"
                    scrollable={true}>
                    <div style={{ padding: "0 5%" }}>
                        <div style={{ fontSize: "14px", textAlign: "center" }}>
                            <Modal.Body>
                                <img src={imsLoader} alt="" width={100} height={100} />
                                <div className="saveModal-heading" style={{ color: "#0074B0" }}>{spinnerMessage ? spinnerMessage : `Please wait while finding answers.`} </div>
                            </Modal.Body>
                        </div>
                    </div>
                </Modal>}
            {this.state.collectionPopup && (
                <CollectionpopUp
                    showModal={this.state.collectionPopup}
                    handleClose={this.handleCloseCollection}
                    customWidth={this.state.customWidth}
                    sessionId={source.answeringinfo.session_id}
                    threadCollections={threadCollections.map(x => x.collection_id)}
                />
            )}
        </>)
    }
}
export default withRouter(GenAILibraryThreadItem);

