import { Modal } from "react-bootstrap";
import {
  popCancelBttn,
  imsLoader
} from "../../assets";
import { v4 as uuidv4 } from "uuid";
import { Component } from "react";
import Button from "../Button";
import "./GlobalSearchFeaturesPopUp.css";
import "./PinToReadingListPopUp.css";
import SuccessReadingList from "./SucessReadingList";
import ApiServices from "../../utils/apiServices";
import RenameNotebookPopup from "./RenameNotebookPopup";
import { snippetLongString } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "./ConfirmMessagePopUpModal"
import { getVarType } from "../../components/GenerateArrayStrings";
import trackingService from "../../services/trackingService";
import moment from 'moment';
import { isEmptyObject } from "jquery";
class NotebookListPopUpGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPop: false,
      isPin: true,
      isManage: false,
      apiResponse: [],
      notebookListName: "",
      notebookListID: "",
      docID: "",
      isLoaded: false,
      showSuccess: false,
      selectedNotebookLists: [],
      showRenamePop: false,
      itemtoBeRenamedName: "",
      itemtoBeRenamedID: "",
      successMessage: "",
      error: "",
      nodeName: "",
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
      isInsertSuccess: false,
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageId: -1,
      isChecked: false
    };
  }
  async componentDidMount() {
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    let service = new ApiServices();
    this.setState({
      showPop: this.props.showModal,
      docID: this.props.docID,
    });
    let apiRes = await service.getNotebookLists(wwid);
    //console.log(apiRes);
    this.setState(
      {
        apiResponse: apiRes,
        isLoaded: true,
      }
    );
    if (this.props.isFromGlobalFeaturesMenu) {
      this.setState({
        isPin: false,
        isManage: true,
      })
    }
    if (this.props.selectedNotebookName) {
      if (this.props.isNewNotebook) {
        this.setState({ notebookListName: this.props.selectedNotebookName })
      } else if (this.props.selectedNotebook) {
        this.setState({ selectedNotebookLists: [this.props.selectedNotebook.notebooklist_id] })
      }
    }
  }

  showConfirmMessage = (message, id) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageId: id
    })
  }

  confirmHandler = async () => {
    const { confirmMessageId } = this.state;
    let username = JSON.parse(localStorage.getItem('username'))
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    if (username) {
      username = username.toLowerCase();
    }
    let service = new ApiServices();

    await service.deleteNotebookLists(confirmMessageId, wwid);
    this.setState(
      {
        isLoaded: false,
        confirmMessageContent: "",
        confirmMessageShowModal: false,
        confirmMessageId: -1
      },
      async () => {
        let { selectedNotebookLists } = this.state
        let apiRes = await service.getNotebookLists(wwid);
        let notebookIds = apiRes.map(x => x.notebooklist_id);
        selectedNotebookLists = selectedNotebookLists.filter(x => notebookIds.includes(x.notebooklist_id));
        this.setState({
          apiResponse: apiRes,
          isLoaded: true,
          selectedNotebookLists: selectedNotebookLists
        });
      }
    );
  }


  closeConfirmMessage = () => {
    this.setState({
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    })
  }
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }

  handleInput = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      notebookListName: e.target.value,
      selectedNotebookLists: []
    });
  };
  handleCheckBox = (e, itemCounts) => {
    /*let arr = this.state.selectedNotebookLists;
      arr=e.target.value;
      if (e.target.checked) {
        arr.push(e.target.value);
      } else {
        var idx = arr.indexOf(e.target.value);
        if (idx !== -1) {
          arr.splice(idx, 1);
        }
      }*/
    let arr = [];
    arr.push(e.target.value)
    this.setState({
      selectedNotebookLists: arr,
    });
  };
  onClickNotebook = (e) => {
    let { selectedNotebookLists } = this.state;
    let selectValue = e.target.value;
    let arr = [];
    if (!selectedNotebookLists.includes(selectValue)) {
      arr.push(e.target.value)
    }
    this.setState({
      selectedNotebookLists: arr,
      notebookListName: ''
    });
  }
  URLConstructor = (item, shareOption) => {
    let url
    let docTitle = encodeURIComponent(item.docTitle)
    let { orderedBy, orderedFor } = item;
    if (!orderedBy) orderedBy = '';
    if (!orderedFor) orderedFor = '';
    if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL' || item.domain === 'CTGov') {
      url = `/DocURL?ID=${item.docID}&ref=${shareOption}&cs=${item.domain}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
      // url = `/DocURL?ID=${docTitle}&ref=${shareOption}&cs=${item.domain}`
    }
    else if (item.domain === 'SKM' || item.domain === "MIAs" || item.domain === "MIAsReferences") {
      url = `/DocURL?ID=${item.docID}&ref=${shareOption}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
    }
    else {
      url = `/DocURL?ID=${item.docID}&ref=${shareOption}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
      if (item.a_status === "Approved Needs Review" || item.status === "Approved Needs Review") {
        url = url + `&docState=anr`
      }
    }
    if (item.enclosureDocID) {
      url += `&enclosureID=${item.enclosureDocID}`
    }

    return url
  }
  indexData = (orignaltext, summarizetext, commandtype, notebooklistId, notebooklistName, referencetext, notesText, tableSummarizedHtml, item) => {
    if (!isEmptyObject(item)) {
      let { orderedBy, orderedFor } = item;
      if (!orderedBy) orderedBy = '';
      if (!orderedFor) orderedFor = '';
      let docVersion = '', docNumber = ''
      if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL') {
        docVersion = item.r_version_label;
        docNumber = item.jj_number;
      }
      else if (item.domain === 'SKM') {
        docNumber = item.domain;
      }
      else if (item.domain === 'CTGov') {
        docNumber = item.docID;
      }
      else {
        docVersion = item.majorVersionNumber;
        docNumber = item.documentNumber;
      }
      let url = '';
      if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL' || item.domain === 'CTGov') {
        url = `/DocURL?ID=${item.docID}&cs=${item.domain}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
      }
      else if (item.domain === 'SKM' || item.domain === "MIAs") {
        url = `/DocURL?ID=${item.docID}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
      }
      else {
        url = `/DocURL?ID=${item.docID}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
        if (item.a_status === "Approved Needs Review" || item.status === "Approved Needs Review") {
          url = url + `&docState=anr`
        }
      }
      if (item.enclosureDocID) {
        url += `&enclosureID=${item.enclosureDocID}`
      }
      let newUrl = window.origin + url;
      let authoringinfo = {
        "orignaltext": orignaltext,
        "summarizetext": summarizetext,
        "commandtype": commandtype,
        "notebooklistId": notebooklistId,
        "notebooklistName": notebooklistName,
        "referencetext": referencetext,
        "notesText": notesText,
        "tableSummarizedHtml": tableSummarizedHtml
      }
      const ts = new trackingService();
      ts.indexTrackingData(localStorage.getItem('userdisplayName'),
        localStorage.getItem('wwid'),
        localStorage.getItem('username'),
        localStorage.getItem('function'),
        localStorage.getItem('region'),
        localStorage.getItem('userproducts'),
        localStorage.getItem('doctypepref'),
        localStorage.getItem('sid'),
        localStorage.getItem('deviceType'),
        localStorage.getItem('deviceOS'),
        localStorage.getItem('browserName'),
        localStorage.getItem('browserVersion'),
        localStorage.getItem('jnjDepartment'),
        localStorage.getItem('companyname'),
        `Save Notebook`,
        '',
        window.location.href, '', '', item.docTitle, docVersion, docNumber, item.searchID, newUrl, item.tempJNJDrugs, item.docType, 'Save Notebook', '', '', item.domain, '', '', item.createdDate, orderedBy, orderedFor,
        {}, authoringinfo);
    } else {
      let authoringinfo = {
        "orignaltext": orignaltext,
        "summarizetext": summarizetext,
        "commandtype": commandtype,
        "notebooklistId": notebooklistId,
        "notebooklistName": notebooklistName,
        "referencetext": referencetext,
        "notesText": notesText,
        "tableSummarizedHtml": tableSummarizedHtml
      }
      const ts = new trackingService();
      ts.indexTrackingData(localStorage.getItem('userdisplayName'),
        localStorage.getItem('wwid'),
        localStorage.getItem('username'),
        localStorage.getItem('function'),
        localStorage.getItem('region'),
        localStorage.getItem('userproducts'),
        localStorage.getItem('doctypepref'),
        localStorage.getItem('sid'),
        localStorage.getItem('deviceType'),
        localStorage.getItem('deviceOS'),
        localStorage.getItem('browserName'),
        localStorage.getItem('browserVersion'),
        localStorage.getItem('jnjDepartment'),
        localStorage.getItem('companyname'),
        `Save Notebook`,
        '',
        window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        {}, authoringinfo);
    }

  }
  onclickProceed = async () => {
    if (this.handleValidation()) {
      let { selectedNotebookLists, notebookListName } = this.state;
      if (selectedNotebookLists.length > 0) {
        let selectedNotebook = selectedNotebookLists[0];
        let nlName = this.state.apiResponse.find(
          (e) => e.notebooklist_id === selectedNotebook
        );
        this.props.handleSelectedNotebook(nlName.notebooklist_name, false, nlName)
      } else {
        this.props.handleSelectedNotebook(notebookListName, true, null);
      }
    }

  }
  _onclickProceed = async () => {
    // let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let wwid = JSON.parse(localStorage.getItem("wwid"));

    let service = new ApiServices();
    if (this.handleValidation()) {
      let referencetexts = [], referencetext = '', _notesText = '';
      let { notesText, citationText } = this.props
      if (citationText) {
        referencetext = citationText;
      } else {
        let { docTitle, authors, genericName, literatureSource } = this.props.sourceItem;
        let strAuthors = (authors && authors.length > 0) ? getVarType(authors) === 'Array' ? authors.join("; ") : authors : "";
        if (strAuthors) {
          referencetexts.push(`${strAuthors}`)
        }
        if (docTitle) {
          referencetexts.push(`${docTitle}`)
        }

        let strGenericName = (genericName && genericName.length > 0) ? getVarType(genericName) === 'Array' ? genericName.join("; ") : genericName : "";
        if (strGenericName) {
          referencetexts.push(`${strGenericName}`)
        }
        if (literatureSource) {
          referencetexts.push(`${literatureSource}`)
        }
        // if (publishedDate) {
        //   referencetexts.push(`${moment(publishedDate).format("D-MMM-YYYY")}`)
        // }
        // let strAuthors = (authors && authors.length > 0) ? getVarType(authors) === 'Array' ? authors.join("; ") : authors : "";
        // if (strAuthors) {
        //   referencetexts.push(`Authors: ${strAuthors}`)
        // }
        // if (docTitle) {
        //   referencetexts.push(`Title: ${docTitle}`)
        // }

        // let strGenericName = (genericName && genericName.length > 0) ? getVarType(genericName) === 'Array' ? genericName.join("; ") : genericName : "";
        // if (strGenericName) {
        //   referencetexts.push(`Generic Name: ${strGenericName}`)
        // }
        // if (literatureSource) {
        //   referencetexts.push(`Source: ${literatureSource}`)
        // }
        // if (publishedDate) {
        //   referencetexts.push(`Published Date: ${moment(publishedDate).format("D-MMM-YYYY")}`)
        // }
        referencetext = referencetexts.join(', ');
      }
      if (notesText) {
        _notesText = notesText;
      }
      let { sourceItem } = this.props;
      let _url = '', _docTitle = ''
      if (this.props.sourceItem && !isEmptyObject(this.props.sourceItem)) {
        _url = this.URLConstructor(this.props.sourceItem, 'notebook')
        _docTitle = this.props.sourceItem.docTitle
      }
      let { summeryText, summarizeText, commandType, commandText } = this.props;
      if (this.state.notebookListName !== "") {
        let listID = uuidv4();
        let _summeryText = summeryText.split(' ').filter((x, i) => i < 2000).join(' ')
        let _summarizeText = commandType !== 'CreateTable' ? summarizeText : '';
        let _tableSummarizedHtml = commandType === 'CreateTable' ? summarizeText : '';
        await service.insertUpdateNotebookList(
          wwid,
          `NL_${listID}`,
          this.state.notebookListName,
          _summeryText,
          _summarizeText,
          referencetext,
          _notesText,
          _tableSummarizedHtml,
          commandType,
          commandText,
          _docTitle,
          _url,
        );

        this.indexData(_summeryText, _summarizeText, commandType, `NL_${listID}`, this.state.notebookListName, referencetext, _notesText, _tableSummarizedHtml, sourceItem);
        //console.log("ID:"+`RL_${listID}`+" Name:"+this.state.notebookListName+" Doc ID:"+this.state.docID)
        this.setState({
          successMessage:
            "The note was saved successfully to new Notebook.",
        });
      }
      if (this.state.selectedNotebookLists.length > 0) {
        for (const itr of this.state.selectedNotebookLists) {
          let nlName = this.state.apiResponse.find(
            (e) => e.notebooklist_id === itr
          );
          let _summarizeText = commandType !== 'CreateTable' ? summarizeText : '';
          let _tableSummarizedHtml = commandType === 'CreateTable' ? summarizeText : '';
          let _summeryText = summeryText.split(' ').filter((x, i) => i < 2000).join(' ')
          await service.insertUpdateNotebookList(
            wwid,
            itr,
            nlName.notebooklist_name,
            _summeryText,
            _summarizeText,
            referencetext,
            _notesText,
            _tableSummarizedHtml,
            commandType,
            commandText,
            _docTitle,
            _url,
          );
          this.indexData(_summeryText, _summarizeText, commandType, itr, nlName.notebooklist_name, referencetext, _notesText, _tableSummarizedHtml, sourceItem);
        }
        this.setState({
          successMessage:
            "The note was saved successfully to selected existing Notebook(s).",
        });
      }
      if (
        this.state.notebookListName !== "" &&
        this.state.selectedNotebookLists.length > 0
      ) {
        this.setState({
          successMessage:
            "The note was saved successfully to selected existing new Notebook(s) and the new Notebook.",
        });
      }
      this.setState({
        showSuccess: true,
        isInsertSuccess: true
      });
    }
  };
  handleOnClickView = async (nID, nName) => {
    let url = `/notebookView?notebookName=${encodeURIComponent(nName)}&notebookId=${nID}`;
    window.open(url, "_blank");
  };
  handleRename = (id, name) => {
    this.setState({
      showRenamePop: true,
      itemtoBeRenamedName: name,
      itemtoBeRenamedID: id,
    });
  };
  handleRenameClose = (status) => {
    this.setState(
      {
        showRenamePop: status,
        isLoaded: false,
      },
      async () => {
        // let username = JSON.parse(
        //   localStorage.getItem("username")
        // ).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem("wwid"));
        let service = new ApiServices();
        let apiRes = await service.getNotebookLists(wwid);
        if (this.props.selectedNotebookName
          && !this.props.isNewNotebook
          && this.props.selectedNotebook.notebooklist_id === this.state.itemtoBeRenamedID) {
          let nlName = apiRes.find(
            (e) => e.notebooklist_id === this.props.selectedNotebook.notebooklist_id
          );
          this.props.updateSelectedNotebook(nlName.notebooklist_name, false, nlName)
        }
        this.setState({
          apiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  };
  handleRemove = async (id) => {
    this.showConfirmMessage("Are you sure you want to delete this notebook?", id)
  };
  handleSuccessClose = (status) => {
    this.setState({
      showSuccess: status,
      //showPop: false,
      isManage: true,
      isPin: false,
      isLoaded: false
    },
      async () => {
        // let username = JSON.parse(
        //   localStorage.getItem("username")
        // ).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem("wwid"));
        let service = new ApiServices();
        let apiRes = await service.getNotebookLists(wwid);
        this.setState(
          {
            apiResponse: apiRes,
            isLoaded: true
          }
        );
      });
    //this.props.handleClose(false);
  };
  handleOnFocusInput = (e) => {
    this.setState({ nodeName: e.target.nodeName });
  };
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    let notebookList = this.state.apiResponse.map((data) =>
      data.notebooklist_name.toLowerCase()
    );
    if (
      this.state.notebookListName.length <= 0 &&
      this.state.selectedNotebookLists.length <= 0 &&
      this.state.nodeName !== "INPUT"
    ) {
      formIsValid = false;
      this.errorAlertMessage("Please enter either New Notebook name or select any Existing Notebook to proceed.")
      // errors["pinNotebookList"] = 'Please enter a valid name to proceed.';
    }
    if (
      this.state.notebookListName.length <= 0 &&
      this.state.nodeName === "INPUT" &&
      this.state.selectedNotebookLists.length <= 0
    ) {
      formIsValid = false;
      errors["pinNotebookList"] = `Please enter a valid name to proceed.`;
    }
    if (notebookList.includes(this.state.notebookListName.toLowerCase())) {
      formIsValid = false;
      errors[
        "pinNotebookList"
      ] = `You already have a notebook with entered title. Please enter a new title.`;
    }
    if (
      this.state.notebookListName.length > 0 &&
      this.state.selectedNotebookLists.length > 0
    ) {
      formIsValid = false;
      errors["pinNotebookList"] = `Please either uncheck Existing Notebook or create new notebook to proceed.`
    }
    if (
      this.state.selectedNotebookLists.length > 1
    ) {
      formIsValid = false;
      errors["pinNotebookList"] = `Please select either one Notebook`
    }

    this.setState({ error: errors });
    return formIsValid;
  }
  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, } = this.state;
    const { confirmMessageContent, confirmMessageShowModal } = this.state;
    let isInsertSuccess = this.state.isInsertSuccess || this.props.isFromGlobalFeaturesMenu;
    if (this.props.width <= 600) {
      return (
        <>
          <Modal
            show={this.state.showPop}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            className="z-index-authoring"
            backdropClassName="z-index-authoring-backdrop"
            scrollable={true}
          >
            <div style={{ borderTop: `5px solid #1DA1E5` }}>
              {/* <div className="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={e => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }}
                />
              </div> */}
              <div>
                <Modal.Header
                  className="gsfModalHeader"
                  style={{ justifyContent: "left", padding: "0.5rem 0" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingTop: "1%",
                    }}
                  >
                    <Modal.Title>
                      <div className="actionItem-headerPart">
                        {!isInsertSuccess && (
                          <div
                            onClick={(e) => {
                              this.setState({ isPin: true, isManage: false });
                            }}
                            className={
                              this.state.isPin
                                ? "active-pin-features-button"
                                : "pin-features-buttons"
                            }
                            style={{ margin: "1% 0" }}
                          >
                            <span>Select Notebook</span>
                          </div>
                        )}
                        {/* <div className="action-button-saperation-line"></div> */}
                        <div
                          onClick={(e) => {
                            this.setState({ isPin: false, isManage: true });
                          }}
                          className={
                            this.state.isManage
                              ? "active-pin-features-button"
                              : "pin-features-buttons"
                          }
                          style={{ margin: "1% 0" }}
                        >
                          <span>View/Manage Notebooks</span>
                        </div>
                      </div>
                    </Modal.Title>
                  </div>
                </Modal.Header>
                <Modal.Body scrollable={true} style={{ height: "45vh" }}>
                  {this.state.isPin && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          <div className="errorInvalidName">
                            {this.state.error["pinNotebookList"]}
                          </div>
                          <span style={{ color: "black" }}>
                            My Notebooks
                          </span>{" "}
                          :
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <ul className="reading-list-wrapper">
                                {this.state.apiResponse.map((item) => {
                                  return (
                                    <li key={item.notebooklist_id}>
                                      <span style={{ marginRight: "5px" }}>
                                        <input
                                          type="radio"
                                          value={item.notebooklist_id}
                                          className="k-radio"
                                          name="RadioButton"
                                          // onChange={e => this.handleCheckBox(e, item.docCount)}
                                          onClick={this.onClickNotebook}
                                          checked={this.state.selectedNotebookLists[0] === item.notebooklist_id}
                                        />
                                      </span>
                                      {item.notebooklist_name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </span>
                          ) : (
                            <span>
                              You currently do not have any notebooks created.
                              <br />
                            </span>
                          )}
                          <br />
                          <div>
                            Create A New Notebook By Entering A Title Below:
                          </div>
                          <br />
                          <input
                            type="text"
                            placeholder="Type here"
                            value={this.state.notebookListName}
                            className="reading-list-input-box"
                            onChange={this.handleInput}
                            onFocus={this.handleOnFocusInput}
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div className="errorInvalidName">
                            {this.state.error["pinNotebookList"]}
                          </div>
                          <br />
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.isManage && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper">
                                <tr>
                                  <th style={{ color: "#333" }}>
                                    Notebook Name
                                  </th>
                                  <th
                                    style={{ textAlign: "center", color: "#333" }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                                <tbody>
                                  {this.state.apiResponse.map((item) => {
                                    return (
                                      <tr key={item.notebooklist_id}>
                                        <td>{item.notebooklist_name}</td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "100%",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <span
                                            onClick={(e) =>
                                              this.handleOnClickView(
                                                item.notebooklist_id,
                                                item.notebooklist_name
                                              )
                                            }
                                            title="View"
                                            className="reading-list-action-text"
                                          >
                                            View
                                          </span>
                                          <span
                                            onClick={(e) =>
                                              this.handleRename(
                                                item.notebooklist_id,
                                                item.notebooklist_name
                                              )
                                            }
                                            title="Rename"
                                            className="reading-list-action-text"
                                          >
                                            Rename
                                          </span>
                                          <span
                                            title="Remove"
                                            className="reading-list-action-text"
                                            onClick={(e) =>
                                              this.handleRemove(
                                                item.notebooklist_id
                                              )
                                            }
                                          >
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span>
                              {" "}
                              You currently do not have any notebooks created.
                            </span>
                          )}
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Modal.Body>
                {this.state.isPin ? (
                  <div className="pin-popup-button-wrapper">
                    {/* <div style={{margin:"1% 0"}}> */}
                    <button
                      className="pin-pop-up-button-cancel"
                      onClick={(e) => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    >
                      Cancel
                    </button>
                    {/* <Button text="Cancel" customColor="#0074B0" action={() => { this.setState({ showPop: false });this.props.handleClose(false); }} /> */}
                    {/* </div>
                    <div style={{margin:"1% 0"}}> */}
                    <button
                      className="pin-pop-up-button-proceed"
                      onClick={(e) => {
                        this.onclickProceed();
                      }}
                    >
                      Select  notebook
                    </button>
                    {/* <Button text="Pin" customColor="#2EB000" action={this.onclickProceed} /> */}
                    {/* </div> */}
                  </div>
                ) : (
                  <div className="pin-popup-button-wrapper">
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {this.state.showSuccess && (
              <>
                <SuccessReadingList
                  display={this.state.showSuccess}
                  handleClose={(status) => this.handleSuccessClose(status)}
                  data={this.state.successMessage}
                  width={this.props.width}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
            {this.state.showRenamePop && (
              <>
                <RenameNotebookPopup
                  showModal={this.state.showRenamePop}
                  handleClose={(status) => this.handleRenameClose(status)}
                  nName={this.state.itemtoBeRenamedName}
                  nID={this.state.itemtoBeRenamedID}
                  width={this.props.width}
                  apiResponse={this.state.apiResponse}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
          </Modal>
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
        </>
      );
    } else if (this.props.width > 600 && this.props.width <= 1000) {
      return (
        <>
          <Modal
            show={this.state.showPop}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            className="z-index-authoring"
            backdropClassName="z-index-authoring-backdrop"
            scrollable={true}
          >
            <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
              {/* <div className="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={e => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }}
                />
              </div> */}
              <div>
                <Modal.Header
                  className="gsfModalHeader"
                  style={{ justifyContent: "left", padding: "0.5rem 0" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingTop: "1%",
                    }}
                  >
                    <Modal.Title>
                      <div className="actionItem-headerPart">
                        {!isInsertSuccess && (<>
                          <div
                            onClick={(e) => {
                              this.setState({ isPin: true, isManage: false });
                            }}
                            className={
                              this.state.isPin
                                ? "active-pin-features-button"
                                : "pin-features-buttons"
                            }
                            style={{ margin: "1% 0" }}
                          >
                            <span>Select Notebook</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                        </>
                        )}
                        <div
                          onClick={(e) => {
                            this.setState({ isPin: false, isManage: true });
                          }}
                          className={
                            this.state.isManage
                              ? "active-pin-features-button"
                              : "pin-features-buttons"
                          }
                          style={{ margin: "1% 0" }}
                        >
                          <span>View/Manage Notebooks</span>
                        </div>
                      </div>
                    </Modal.Title>
                  </div>
                </Modal.Header>
                <Modal.Body scrollable={true} style={{ height: "45vh" }}>
                  {this.state.isPin && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          <div className="errorInvalidName">
                            {this.state.error["pinNotebookList"]}
                          </div>
                          <span style={{ color: "black" }}>
                            My Notebooks
                          </span>{" "}
                          :
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <ul className="reading-list-wrapper">
                                {this.state.apiResponse.map((item) => {
                                  return (
                                    <li key={item.notebooklist_id}>
                                      <span style={{ marginRight: "5px" }}>
                                        <input
                                          type="radio"
                                          value={item.notebooklist_id}
                                          className="k-radio"
                                          name="RadioButton"
                                          // onChange={e => this.handleCheckBox(e, item.docCount)}
                                          onClick={this.onClickNotebook}
                                          checked={this.state.selectedNotebookLists[0] === item.notebooklist_id}
                                        />
                                      </span>
                                      {item.notebooklist_name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </span>
                          ) : (
                            <span>
                              You currently do not have any notebooks created.
                            </span>
                          )}
                          <div>
                            Create A New Notebook By Entering A Title Below:
                          </div>
                          <br />
                          <input
                            type="text"
                            placeholder="Type here"
                            value={this.state.notebookListName}
                            className="reading-list-input-box"
                            onChange={this.handleInput}
                            onFocus={this.handleOnFocusInput}
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div className="errorInvalidName">
                            {this.state.error["pinNotebookList"]}
                          </div>
                          <br />
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.isManage && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper">
                                <tr>
                                  <th>Notebook Name</th>
                                  <th style={{ textAlign: "center" }}>Date</th>
                                  <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                                <tbody>
                                  {this.state.apiResponse.map((item) => {
                                    return (
                                      <tr key={item.notebooklist_id}>
                                        <td>{item.notebooklist_name}</td>
                                        <td style={{ textAlign: "center" }}>
                                          {moment(item.added_on).format("D-MMM-YYYY")}
                                        </td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "100%",
                                          }}
                                        >
                                          <span
                                            onClick={(e) =>
                                              this.handleOnClickView(
                                                item.notebooklist_id,
                                                item.notebooklist_name
                                              )
                                            }
                                            title="View"
                                            className="reading-list-action-text"
                                          >
                                            View
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            onClick={(e) =>
                                              this.handleRename(
                                                item.notebooklist_id,
                                                item.notebooklist_name
                                              )
                                            }
                                            title="Rename"
                                            className="reading-list-action-text"
                                          >
                                            Rename
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            title="Remove"
                                            className="reading-list-action-text"
                                            onClick={(e) =>
                                              this.handleRemove(
                                                item.notebooklist_id
                                              )
                                            }
                                          >
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span>
                              {" "}
                              You currently do not have any notebooks created.
                            </span>
                          )}
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Modal.Body>
                {this.state.isPin ? (
                  <div
                    className="gsf-popup-button-wrapper"
                    style={{ width: "40%" }}
                  >
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                    <Button
                      text="Select Notebook"
                      customColor="#2EB000"
                      action={this.onclickProceed}
                    />
                  </div>
                ) : (
                  <div className="reading-list-button-tablet-wrapper">
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {this.state.showSuccess && (
              <>
                <SuccessReadingList
                  display={this.state.showSuccess}
                  handleClose={(status) => this.handleSuccessClose(status)}
                  data={this.state.successMessage}
                  width={this.props.width}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
            {this.state.showRenamePop && (
              <>
                <RenameNotebookPopup
                  showModal={this.state.showRenamePop}
                  handleClose={(status) => this.handleRenameClose(status)}
                  nName={this.state.itemtoBeRenamedName}
                  nID={this.state.itemtoBeRenamedID}
                  width={this.props.width}
                  apiResponse={this.state.apiResponse}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
          </Modal>
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
        </>
      );
    } else {
      return (
        <>
          <Modal
            show={this.state.showPop}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            className="z-index-authoring"
            backdropClassName="z-index-authoring-backdrop"
            scrollable={true}
          >
            <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
              <div className="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={(e) => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }}
                />
              </div>
              <div>
                <Modal.Header
                  className="gsfModalHeader"
                  style={{ justifyContent: "left" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "60%",
                      paddingTop: "1%",
                    }}
                  >
                    <Modal.Title>
                      <div className="actionItem-headerPart" style={isInsertSuccess ? { justifyContent: "flex-start" } : {}}>
                        {!isInsertSuccess && (<>
                          <div
                            onClick={(e) => {
                              this.setState({ isPin: true, isManage: false });
                            }}
                            className={
                              this.state.isPin
                                ? "active-features-button"
                                : "features-buttons"
                            }
                          >
                            <span>Select Notebook</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                        </>
                        )}

                        <div
                          onClick={(e) => {
                            this.setState({ isPin: false, isManage: true });
                          }}
                          className={
                            this.state.isManage
                              ? "active-features-button"
                              : "features-buttons"
                          }
                        >
                          <span>View/Manage Notebooks</span>
                        </div>
                      </div>
                    </Modal.Title>
                  </div>
                </Modal.Header>
                <Modal.Body scrollable={true} style={{ height: "45vh" }}>
                  {this.state.isPin && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          <div className="errorInvalidName">
                            {this.state.error["pinNotebookList"]}
                          </div>
                          <span style={{ color: "black" }}>
                            My Notebooks
                          </span>{" "}
                          :
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <ul className="reading-list-wrapper" style={{ maxHeight: "21vh" }}>
                                {this.state.apiResponse.map((item) => {
                                  return (
                                    <li key={item.notebooklist_id}>
                                      <span style={{ marginRight: "5px" }}>
                                        <input
                                          type="radio"
                                          value={item.notebooklist_id}
                                          className="k-radio"
                                          name="RadioButton"
                                          // onChange={e => this.handleCheckBox(e, item.docCount)}
                                          onClick={this.onClickNotebook}
                                          checked={this.state.selectedNotebookLists[0] === item.notebooklist_id}
                                        />
                                      </span>
                                      {item.notebooklist_name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </span>
                          ) : (
                            <span>
                              You currently do not have any notebooks created.
                              <br />
                            </span>
                          )}
                          <br />
                          <div>
                            Create A New Notebook By Entering A Title Below:
                          </div>
                          <input
                            type="text"
                            placeholder="Type here"
                            value={this.state.notebookListName}
                            className="reading-list-input-box"
                            onFocus={this.handleOnFocusInput}
                            onChange={this.handleInput}
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div className="errorInvalidName">
                            {this.state.error["pinNotebookList"]}
                          </div>
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.isManage && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper">
                                <tr>
                                  <th>Notebook Name</th>
                                  <th style={{ textAlign: "center" }}>Date</th>
                                  <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                                <tbody>
                                  {this.state.apiResponse.map((item) => {
                                    return (
                                      <tr key={item.notebooklist_id}>
                                        <td>{item.notebooklist_name}</td>
                                        <td style={{ textAlign: "center" }}>
                                          {moment(item.added_on).format("D-MMM-YYYY")}
                                        </td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "100%",
                                          }}
                                        >
                                          <span
                                            onClick={(e) =>
                                              this.handleOnClickView(
                                                item.notebooklist_id,
                                                item.notebooklist_name
                                              )
                                            }
                                            title="View"
                                            className="reading-list-action-text"
                                          >
                                            View
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            onClick={(e) =>
                                              this.handleRename(
                                                item.notebooklist_id,
                                                item.notebooklist_name
                                              )
                                            }
                                            title="Rename"
                                            className="reading-list-action-text"
                                          >
                                            Rename
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            title="Remove"
                                            className="reading-list-action-text"
                                            onClick={(e) =>
                                              this.handleRemove(
                                                item.notebooklist_id
                                              )
                                            }
                                          >
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span>
                              {" "}
                              You currently do not have any notebooks created.
                            </span>
                          )}
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Modal.Body>
                {this.state.isPin ? (
                  <div className="gsf-popup-button-wrapper" style={{ width: "32%" }}>
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />

                    <Button
                      text="Select Notebook"
                      customColor="#2EB000"
                      action={this.onclickProceed}
                    />
                  </div>
                ) : (
                  <div className="reading-list-button-wrapper">
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {this.state.showSuccess && (
              <>
                <SuccessReadingList
                  display={this.state.showSuccess}
                  handleClose={(status) => this.handleSuccessClose(status)}
                  data={this.state.successMessage}
                  width={this.props.width}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
            {this.state.showRenamePop && (
              <>
                <RenameNotebookPopup
                  showModal={this.state.showRenamePop}
                  handleClose={(status) => this.handleRenameClose(status)}
                  nName={this.state.itemtoBeRenamedName}
                  nID={this.state.itemtoBeRenamedID}
                  width={this.props.width}
                  apiResponse={this.state.apiResponse}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
          </Modal>
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
        </>
      );
    }
  }
}
export default NotebookListPopUpGlobal;