/* eslint-disable no-useless-escape */
import { Modal, ModalBody } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component } from "react";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'
import './BookmarkPopUp.css'
import ApiServices from "../../utils/apiServices";
import { snippetLongString } from "../../utils/utilityServices"
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";

class ShareTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: [],
      shared_to_email: '',
      error: '',
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageIndex: -1,
      login_id: 0,
      prompt: '',
      template_name: '',
      alertMessageShowModal: false,
      alertMessageContent: '',
      alertMessageModelColor: "",
      formIsValid: true,
    };
  }
  async componentDidMount() {
    this.setState({
      login_id: this.props.selectedPrompt[0].login_id,
      prompt: this.props.selectedPrompt[0].prompt,
      template_name: this.props.selectedPrompt[0].template_name
    })
  }

  showConfirmMessage = (message, index) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageIndex: index
    })
  }

  handleInput = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      shared_to_email: e.target.value
    })
  }
  handleValidation = async () => {
    let errors = {};
    let form_Is_Valid = true;
    let service = new ApiServices();
    let sharedEmail = this.state.shared_to_email?.toLowerCase();
    let username = JSON.parse(localStorage.getItem('username'));
    if (username) {
      username = username.toLowerCase();
    }
    let apiRes = await service.getUserDetailsByUserName(sharedEmail);
    if (sharedEmail?.length === 0) {
      form_Is_Valid = false;
      errors["proptError"] = 'Please enter a valid email to proceed.';
    }
    else if (username?.toLowerCase() === sharedEmail?.toLowerCase()) {
      form_Is_Valid = false;
      errors["proptError"] = "Please use an email address other than yours's to share."
    }
    else if (apiRes?.length === 0) {
      form_Is_Valid = false;
      errors["proptError"] = 'Email is not a registered mail.Please enter a registerd email.'
    }
    else if (!sharedEmail.includes('@its.jnj.com')) {
      form_Is_Valid = false;
      errors["proptError"] = `Email id must contain domain name ex: user@its.jnj.com`;
    }
    this.setState({ error: errors, formIsValid: form_Is_Valid });
    return form_Is_Valid;
  }
  handleSubmit = async () => {
    let { login_id, prompt, template_name, shared_to_email } = this.state;
    let service = new ApiServices();
    let id = JSON.parse(localStorage.getItem('wwid'));
    let shared_from_email = JSON.parse(localStorage.getItem('username'));
    let shared_from_name = JSON.parse(localStorage.getItem('userdisplayName'));
    if (await this.handleValidation()) {
      let apiRes = await service.insertSharePromptTemplateList(template_name, prompt, shared_to_email, login_id, shared_from_email, shared_from_name);
      if (apiRes) {
        this.setState({
          alertMessageModelColor: "#2eb000",
          alertMessageShowModal: true,
          alertMessageContent: "The prompt template shared successfully."
        })
      }
    }
  }
  closeAlertMessage = () => {
    this.setState({ showPop: false, alertMessageShowModal: false });
    this.props.handleClose(false);
  }


  render() {
    const { confirmMessageContent, confirmMessageShowModal, alertMessageContent, alertMessageModelColor, alertMessageShowModal } = this.state;

    return (
      <Modal
        show={this.props.showModal}
        size="lg md sm xs"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="popup-transition"
        className="z-index-Share"
        backdropClassName="z-index-Share-backdrop"
        scrollable={true}
      >
        <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
          <div className="popup-cancel-btn-wrapper">
            <img
              src={popCancelBttn}
              alt=""
              onClick={e => {
                this.setState({ showPop: false });
                this.props.handleClose(false);
              }}
            />
          </div>
          <div>
            <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
            </Modal.Header>
            <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
              <div>
                <span className="create-bookmark-field">Enter the email address of the user you would like to share this prompt:  ​</span>
                <br />
                <input type="text" placeholder="ex: user@its.jnj.com​" value={this.state.bookmarkName} className="create-bookmark-input-box" onChange={this.handleInput}  /*onBlur={this.handleValidation}*/
                />
                <br />
                {/*<span className="length-info">Name with a maximum of 100 characters.</span>*/}
                <div className="errorInvalidName">{this.state.error["proptError"]}</div>
              </div>
            </Modal.Body>
            <div className="bookmark-popup-button-wrapper" style={{ width: '22%' }}>
              <Button text="Cancel" customColor="#0074B0" action={() => {
                this.setState({ showPop: false });
                this.props.handleClose(false);
              }} />
              <Button text="Share" customColor="#2EB000" action={this.handleSubmit} />
            </div>
          </div>
        </div>
        {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
      </Modal>
    );
  }
}

export default ShareTemplateModal;
