import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import trackingService from "../../services/trackingService";

class GenAIAuthoringDisplayStringSup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: false
        }
    }
    indexDocumentPreview = (docTitle, searchID, url, docNumber) => {

        let { sessionID, Question, questionType, threadID } = this.props;
        let assistantinfo = {
            "session_id": sessionID,
            "thread_id": threadID,
            "question_type": questionType,
            "question": Question,
            "ismultifiles": false
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `Preview Answering2.0 Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', docNumber, searchID, url, '', '', `Preview Answering2.0 Document`, '', '', '', '', '', '', '', '', {}, {}, assistantinfo);
    };
    indexMultiFilesDocumentPreview = (docTitle) => {
        // let { sessionID, Question, questionType, threadID } = this.props;
        // let assistantinfo = {
        //     "session_id": sessionID,
        //     "thread_id": threadID,
        //     "question_type": questionType,
        //     "question": Question,
        //     "ismultifiles": true
        // }
        // const ts = new trackingService();
        // ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
        //     localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
        //     localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
        //     localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
        //     localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
        //     `Preview Answering2.0 Document`, '',
        //     window.location.href,
        //     '',
        //     '',
        //     docTitle, '', '', '', '', '', '', `Preview Answering2.0 Document`, '', '', '', '', '', '', '', '', {}, {}, assistantinfo);
    };
    render() {
        if (this.props.isMultiFiles) {
            let { document_title, doc_number, docType } = this.props.singleSource;
            return (<>
                <Tippy content={<>
                    <div className="gen-ai-answering-result-source-margin">
                        {document_title}
                    </div>
                    <div className="gen-ai-answering-result-source-margin">
                        Pages: {this.props.pageNumber.join(', ')}
                    </div></>}>
                    <sup custom-document-title={document_title} custom-pages={this.props.pageNumber.join(', ')} onClick={() => { this.indexMultiFilesDocumentPreview(document_title); this.props.handleClickOnCitationNumber(this.props.singleSource.document_guid, this.props.pageNumber); }} className="gen-ai-answering-result-main-answer-sup">
                        {this.props.sitem}</sup>
                </Tippy>
            </>
            )
        } else {
            let { document_title, doc_number, docType, download_url, searchID, enclosureDocID } = this.props.singleSource;
            if (enclosureDocID && enclosureDocID.trim()) {
                download_url += `&enclosureID=${enclosureDocID}`
            }
            return (<>
                <Tippy content={<>
                    <div className="gen-ai-answering-result-source-margin">
                        {document_title}
                    </div>
                    <div className="gen-ai-answering-result-source-margin">
                        {doc_number}
                    </div>
                    {docType &&
                        <div className="gen-ai-answering-result-source-margin">
                            {docType}
                        </div>}
                    <div className="gen-ai-answering-result-source-margin">
                        Pages: {this.props.pageNumber.join(', ')}
                    </div></>}>
                    <sup custom-document-title={document_title} custom-document-number={doc_number} custom-document-type={docType ? docType : ""} custom-pages={this.props.pageNumber.join(', ')} onClick={() => { this.indexDocumentPreview(document_title, searchID, download_url, doc_number); this.props.handleClickOnSourceNumber(this.props.singleSource, this.props.pageNumber); }} className="gen-ai-answering-result-main-answer-sup">
                        {this.props.sitem}</sup>
                </Tippy>
            </>
            )
        }
    }
}
export default withRouter(GenAIAuthoringDisplayStringSup);